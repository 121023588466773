import { Box, Paper, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ITeam } from 'models/ITeam';
import ModalEstimationTeam from '../ModalEstimationTeam';
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import { roleIdToPracticeId } from 'views/Templates/Template/helpers';
import { EEstimateAPIActionType, SalesEstimateAPIHook } from 'views/Estimates/EstimatesHooks/useSalesEstimate';
import ModalEditEstimationTeamOwner, { IPracticeUser } from './Modals/ModalEditEstimationTeamOwner';
import { HslSalesestimatepracticeassignmentStatuscode } from 'data/swagger/API';
import { MuiDataGrid } from "../../../../../components/DataGrid/MuiDataGrid";
import { ArrayToLookupByKey } from "../../../../../TSFunctions";
import { Config } from "../../../../../config";
import MuiButton from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import ActionButton from "../../../../../components/ActionButton";
interface IEstimationTeam {
	estimateReducer: SalesEstimateAPIHook;
}

const EstimationTeam: React.FunctionComponent<IEstimationTeam> = (props) => {
	const { cache, identity } = useApplication();

	const { stateEstimate, dispatchAPIEstimate } = props.estimateReducer;
	const [isOpenEstimationTeam, setIsOpenEstimationTeam] = useState(false);
	const [selectedPractice, setSelectedPractice] = useState<ITeam>();
	const [isEditOwnerModalOpen, setIsEditOwnerModalOpen] = useState<boolean>(false);
	// const [isEditOwnerModalOpen, setIsEditOwnerModalOpen] = useState<ITeam>();
	const canEdit = !stateEstimate.isReadOnly && !identity?.xrm.isReadOnly
	const handleOnEditClick = (team: ITeam) => {
		setSelectedPractice(team)
		setIsEditOwnerModalOpen(true)
	}
	const application = useApplication();
	const teams = useMemo(() => {
		return stateEstimate.estimate.hsl_hsl_salesestimate_hsl_salesestimatepracticeassignment_salesestimateid?.map(q => {
			const t: ITeam = {
				id: q?.hsl_practiceid?.id || '',
				hsl_practiceid: q.hsl_practiceid!,
				statuscode: q.statuscode,
				ownerId: q.ownerId,
				hasLineItemEffort: stateEstimate.estimate.hsl_hsl_salesestimate_hsl_salesestimatelineitem_salesestimateid?.find(w =>
					w.hsl_hsl_salesestimatelineitem_hsl_salesestimatelineitemeffort_salesestimatelineitemid?.find(effort =>
						roleIdToPracticeId(effort?.hsl_practiceroleid?.id, cache?.practiceRoles)?.id === q.hsl_practiceid?.id)) ? true : false
			}
			return t;
		}) ?? [];
	}, [stateEstimate.estimate.hsl_hsl_salesestimate_hsl_salesestimatepracticeassignment_salesestimateid, stateEstimate.estimate.hsl_hsl_salesestimate_hsl_salesestimatelineitem_salesestimateid])

	const isModalEnabled = (practiceId: string) => {
		return (application.identity?.xrm.practicesEstimatorPractices?.find(q => q.id === practiceId)
			|| application.identity?.xrm.practicesLeaderPractices?.find(q => q.id === practiceId)
			|| application.identity?.xrm.isAdmin)
			&& !stateEstimate.isReadOnly
	}
	const columns: GridColDef[] = [
		{ field: 'practice', headerName: 'Practice', minWidth: 200, type: "string", flex: 2, },
		{ field: 'status', headerName: 'Status', minWidth: 200, type: "string", flex: 2, },
		{ field: 'owner', headerName: 'Practice Owner', minWidth: 200, type: "string", flex: 2, },
		{
			field: 'edit', headerName: '', minWidth: 100, type: "string", flex: 2, align: "right", renderCell: (params: GridRenderCellParams<any>) => {
				return <>
					<MuiButton
						variant="text"
						disabled={!isModalEnabled(params.row.id)}
						sx={{ float: 'right' }}
						onClick={() => {
							handleOnEditClick(params.row.team)
						}}
					>
						<EditIcon />
					</MuiButton>
				</>
			}
		}

	];
	const practicesByPracticeId = useMemo(() => {
		return ArrayToLookupByKey(application.cache.practices, 'id');
	}, [application.cache.practices]);

	const getStatusCodeName = (status: HslSalesestimatepracticeassignmentStatuscode) => {
		return application.cache.options?.hsl_salesestimatepracticeassignment_statuscode?.find(code => code.value === status)?.name
	}

	const rows: GridValidRowModel[] = useMemo(() => {
		let retObj: GridValidRowModel[] = [];
		// TODO raise this to cache, and add cache baking
		teams.map((eachTeam) => (
			retObj.push({
				id: eachTeam.hsl_practiceid?.id,
				practice: practicesByPracticeId[eachTeam.hsl_practiceid?.id!]?.hsl_name ?? 'N/A',
				status: eachTeam?.statuscode ? getStatusCodeName(eachTeam?.statuscode) : 'Pending Save',
				owner: eachTeam.ownerId?.id === Config.webApp.appRegistrationSystemUserId ? "Unassigned" : eachTeam.ownerId?.name ?? 'N/A',
				team: eachTeam
			}
			)));
		return retObj;
	}, [teams])
	return <>
		<ModalEstimationTeam
			isOpen={isOpenEstimationTeam}
			estimateState={stateEstimate.estimate}
			onClose={() => { setIsOpenEstimationTeam(false); }}
			defaultValue={teams}
			onChange={(hsl_salesestimatepracticeassignments) => {
				dispatchAPIEstimate({
					type: EEstimateAPIActionType.estimate_projectassignment_change,
					projectAssignment: hsl_salesestimatepracticeassignments,
				});
				setIsOpenEstimationTeam(false);
			}}
		/>
		<ModalEditEstimationTeamOwner
			defaultValue={selectedPractice}
			onClose={() => { setIsEditOwnerModalOpen(false); }}
			isOpen={isEditOwnerModalOpen}
			onSubmit={((leader: IPracticeUser, status: HslSalesestimatepracticeassignmentStatuscode) => {
				const practiceAssignment = stateEstimate?.estimate?.hsl_hsl_salesestimate_hsl_salesestimatepracticeassignment_salesestimateid?.map((assignment) => {
					if (assignment.id === selectedPractice?.id) {
						return {
							...assignment,
							ownerId: status === HslSalesestimatepracticeassignmentStatuscode?.Option_864630003 ?
								{ id: identity?.xrm.systemuser.id, entityType: 'systemuser' } : { id: leader.userId, entityType: 'systemuser' },
							statuscode: status,
							statecode: status === HslSalesestimatepracticeassignmentStatuscode?.Option_864630003 ? 1 : 0
						}
					} else {
						return assignment;
					}
				}).filter(assignment =>
					assignment.id === selectedPractice?.id
				)[0]
				if (practiceAssignment) {
					dispatchAPIEstimate({
						type: EEstimateAPIActionType.estimate_update_practice_assignment,
						practiceAssignment: practiceAssignment
					});
					setIsEditOwnerModalOpen(false);
				}

			})}
			practiceId={selectedPractice?.hsl_practiceid?.id || ''}
		/>
		<Box component={Paper}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
				<Typography variant="h2">Estimation Team</Typography>
				<ActionButton
					primary
					sx={{ minWidth: '120px', marginRight: 1 }}
					label='Edit'
					disabled={!canEdit}
					onClick={() => setIsOpenEstimationTeam(true)}
				/></div>
			<MuiDataGrid rows={rows} columns={columns} />

		</Box>
	</>
};

export default EstimationTeam;
