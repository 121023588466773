// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

import { Theme } from "@mui/material";

export default function ListItemIcon(theme: Theme) {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    // minWidth: 24,
                }
            }
        }
    };
}
