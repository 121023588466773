import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";

import useSalesEstimate, { EEstimateAPIActionType } from "../EstimatesHooks/useSalesEstimate";
import { MuiDataGrid } from "components/DataGrid/MuiDataGrid";
import { GridColDef } from "@mui/x-data-grid-premium";
import dayjs from 'dayjs';
import { createAnchor } from "views/Templates/Template/helpers";
import { ExpandMore } from "@mui/icons-material";
import { MuiAccordion } from "./MuiAccordion";
import { LoadingBackdrop } from "views/Templates/Template/components/LoadingBackdrop";
import { useApplication } from "components/ApplicationContextProvider/ApplicationContextProvider";

export default function Output() {
    const application = useApplication();
    const { identity } = application;
    const salesEstimateReducer = useSalesEstimate()
    const { stateEstimate, isAPILoading, error, dispatchAPIEstimate } = salesEstimateReducer
    const outputs = stateEstimate.loadedOutputs || []
    const canEdit = !stateEstimate.isReadOnly && !identity?.xrm.isReadOnly



    const columns: GridColDef[] = [
        {
            field: 'fileName', headerName: 'File Name', minWidth: 175, type: "string", flex: 2,
            renderCell: (params) => {
                return (
                    <Link onClick={async () => createAnchor(params.row.documentBody, params.row.fileName, params.row.mimeType)} download={params.row.fileName}>{params.row.fileName}</Link>
                )
            }
        },
        { field: 'createdBy', headerName: 'Created By', minWidth: 175, type: "string", flex: 1 },
        {
            field: 'createdOn', headerName: 'Created On', minWidth: 175, type: "date", flex: 1,
            valueFormatter: params =>
                dayjs(params?.value).format("MM/DD/YYYY hh:mm:ss A"),
        },

    ];

    const rows = useMemo(() => {
        return outputs.map((output) => {
            return {
                id: output.id,
                documentBody: output.documentBody,
                fileName: output.fileName,
                createdOn: output.createdOn,
                createdBy: output.createdBy?.name,
                mimeType: output.mimeType,
                fileType: output.subject
            }
        }).sort(function (a, b) {
            return new Date(b.createdOn!).getTime() - new Date(a.createdOn!).getTime()
        }) || []
    }, [outputs])


    const SowGrid = () => {
        return (
            <MuiDataGrid
                rows={rows.filter(row => row.fileType == 'SOW')}
                columns={columns}
            />
        )
    }
    const WbsGrid = () => {
        return (
            <MuiDataGrid
                rows={rows.filter(row => row.fileType == 'WBS')}
                columns={columns}
            />
        )
    }

    const WbsTitle = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography sx={{}} variant="h2">Work Breakdown Structure</Typography>
                <Button disabled={!canEdit} size={"small"} variant={"contained"} color={"primary"} sx={{ minWidth: '120px', marginRight: 1 }} onClick={(event) => {
                    dispatchAPIEstimate({
                        type: EEstimateAPIActionType.estimate_generate_wbs,
                        salesEstimateId: stateEstimate.estimate.id!
                    })
                }}>
                    Generate WBS
                </Button>
            </div>
        )
    }

    const SowTitle = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Typography sx={{}} variant="h2">Statement Of Work</Typography>
                <Button disabled={!canEdit} size={"small"} variant={"contained"} color={"primary"} sx={{ minWidth: '120px', marginRight: 1 }} onClick={(event) => {
                    dispatchAPIEstimate({
                        type: EEstimateAPIActionType.estimate_generate_sow,
                        salesEstimateId: stateEstimate.estimate.id!
                    })
                }}>
                    Generate SOW
                </Button>
            </div>
        )
    }

    return (
        <div>
            <LoadingBackdrop isOpen={isAPILoading} />
            <Typography sx={{ paddingTop: '20px', paddingBottom: '20px' }} variant="h1">File Upload</Typography>
            <MuiAccordion titleComponent={SowTitle()} summaryComponent={SowGrid()} />
            <MuiAccordion titleComponent={WbsTitle()} summaryComponent={WbsGrid()} />
        </div >
    );
}