import { JsonWebTokenDataSource } from "./JsonWebTokenDataSource";
import { AzureDirectoryTokenProvider } from "./AzureDirectoryTokenProvider";

/**
 * Abstract class that handles the token acquisition.
 */
export abstract class AzureDirectoryDataSource extends JsonWebTokenDataSource {
    public readonly provider: AzureDirectoryTokenProvider;

    constructor(provider: AzureDirectoryTokenProvider) {
        super(provider);
        this.provider = provider;
    }
}