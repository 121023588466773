import { DataSource } from "data/DataSource";
import { BulkUpdateResourcePlanResourceDto, HslNotificationStatecode, HslNotificationStatuscode, HslProjectconfigurationpreferenceGc, HslProjectphase, InvestmentSummaryDto, LinkDto, ResourceAllocationDto, ResourcePlanDto, SalesEstimatePracticeAssignmentDto, SystemUserDto } from "data/swagger/API";
import { IEstimate } from "models/IEstimate";
import { ITeam } from "models/ITeam";
import { EEstimateActionType, IEstimateState, TEstimateAction } from "store/reducers/EstimateReducer";
import { ProductLineItem } from "../EstimateLineItems/ProductItems/ProductItemGrid";

export const fetchSalesEstimate = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, salesEstimateId: string) => {
    try {
        !currentState.isAPILoading && setIsLoading(true);
        const salesEstimate = await dataSource.webApi.swagger.salesEstimate.salesEstimateGetByIdWithRelatedEntities({ id: salesEstimateId });
        dispatchAction({
            type: EEstimateActionType.load,
            estimate: salesEstimate.data.data,
            hsl_resourceplans: salesEstimate.data.data?.hsl_hsl_resourceplan_salesestimateid_hsl_sale || []
        });
    }
    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimateProjectAssignment = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, assignments: ITeam[]) => {
    try {
        setIsLoading(true);
        const currentAssignments = currentState.estimate.hsl_hsl_salesestimate_hsl_salesestimatepracticeassignment_salesestimateid
        const deletedAssignments = currentAssignments?.filter((currAssignment) => !assignments.find((newAssignment) => newAssignment.id === currAssignment.hsl_practiceid?.id))
        const deletedAssignmentsPromises = deletedAssignments?.map((deletedAssignment) => {
            return dataSource.webApi.swagger.salesEstimatePracticeAssignment.salesEstimatePracticeAssignmentDelete({ id: deletedAssignment.id })
        }) || []
        const newAssignmentsPromises = assignments?.map((newAssignment) => {
            if (newAssignment.id === '' || newAssignment.id === undefined)
                return dataSource.webApi.swagger.salesEstimatePracticeAssignment.salesEstimatePracticeAssignmentAdd({
                    hsl_name: newAssignment.hsl_name,
                    hsl_practiceid: { id: newAssignment.hsl_practiceid?.id },
                    hsl_salesestimateid: { id: currentState.estimate.id },
                    ... ((newAssignment.ownerId?.id) && { ownerId: { id: newAssignment.ownerId?.id, logicalName: 'systemuser' } }),
                }) || []
        })

        const assignmentsToUpdate = assignments?.filter((updateAssignment) => {
            return currentAssignments?.find((currAssignment) => {
                return (currAssignment.id === updateAssignment.id &&
                    deletedAssignments?.find(deletedAssignments => deletedAssignments.id === currAssignment.id) === undefined
                    && currAssignment.ownerId?.id !== updateAssignment.ownerId?.id)
            })
        })
        const updateAssignmentsPromises = assignmentsToUpdate?.map((assignments) => {
            return dataSource.webApi.swagger.salesEstimatePracticeAssignment.salesEstimatePracticeAssignmentUpdate({
                id: assignments.id,
                hsl_name: assignments.hsl_name,
                hsl_practiceid: { id: assignments.hsl_practiceid?.id },
                hsl_salesestimateid: { id: currentState.estimate.id },
                ... ((assignments.ownerId?.id) && { ownerId: { id: assignments.ownerId?.id, logicalName: 'systemuser' } }),
                statecode: assignments.statecode,
                statuscode: assignments.statuscode
            })

        })
        await Promise.all([...newAssignmentsPromises, ...deletedAssignmentsPromises, ...updateAssignmentsPromises])
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)

    }
    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimateLinks = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, newLinks: LinkDto[]) => {
    try {
        setIsLoading(true);
        const currentLinks = currentState.intitialEstimate.hsl_hsl_links_SalesEstimate_hsl_salesestimate
        const newLinkPromises = newLinks?.map((newLink) => {
            // if new - add
            if (newLink.id === '' || newLink.id === undefined) {
                return dataSource.webApi.swagger.link.linkAdd({
                    hsl_name: newLink.hsl_name,
                    hsl_link: newLink.hsl_link,
                    hsl_salesestimate: { id: currentState.estimate.id }
                })
            } else {
                // if exists - update
                if (currentLinks?.find((currLink) => {
                    if (currLink.id === newLink.id) {
                        if (currLink.hsl_link !== newLink.hsl_link ||
                            currLink.hsl_name !== newLink.hsl_name
                        ) {
                            return true
                        }
                    }
                    return false
                }
                )) {
                    return dataSource.webApi.swagger.link.linkUpdate({
                        id: newLink.id,
                        hsl_name: newLink.hsl_name,
                        hsl_link: newLink.hsl_link,
                        hsl_salesestimate: { id: currentState.estimate.id }
                    })
                }
            }
        }) || []
        const deletedLinks = currentLinks?.filter((currLink) => !newLinks.find((newLink) => newLink.id === currLink.id))
        const deletedLinksPromises = deletedLinks?.map((deletedLink) => {
            return dataSource.webApi.swagger.link.linkDelete({ id: deletedLink.id })
        }) || []
        const allPromises = [...newLinkPromises, ...deletedLinksPromises]
        allPromises.length > 0 && await Promise.all(allPromises)
        allPromises.length > 0 && await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }
    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimateProjectConfiguration = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, partialEstimate: Partial<IEstimate>) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimate.salesEstimateUpdate({
            ...currentState.estimate,
            id: currentState.estimate.id,
            hsl_initiatepreference: partialEstimate.hsl_initiatepreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_initiateweeks: partialEstimate.hsl_initiateweeks || 0,
            hsl_blueprintpreference: partialEstimate.hsl_blueprintpreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_blueprintweeks: partialEstimate.hsl_blueprintweeks || 0,
            hsl_buildpreference: partialEstimate.hsl_buildpreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_sprintduration: partialEstimate.hsl_sprintduration || 0,
            hsl_validatepreference: partialEstimate.hsl_validatepreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_validateweeks: partialEstimate.hsl_validateweeks || 0,
            hsl_deploypreference: partialEstimate.hsl_deploypreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_deployweeks: partialEstimate.hsl_deployweeks || 0,
            hsl_operatepreference: partialEstimate.hsl_operatepreference || HslProjectconfigurationpreferenceGc.Option_864630000,
            hsl_operateweeks: partialEstimate.hsl_operateweeks || 0
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }
    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimateResourceAllocation = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, resourceAllocation: ResourceAllocationDto[]) => {
    try {
        setIsLoading(true);
        const currentAllocations = currentState.intitialEstimate.hsl_hsl_salesestimate_hsl_resourceallocation_SalesEstimate

        const resourceAllocationToUpdate = resourceAllocation?.filter((allocation) => {
            return currentAllocations?.find((currentAllocation) => {
                if (currentAllocation.id === allocation.id) {
                    return currentAllocation.hsl_DelayedWeeks !== allocation.hsl_DelayedWeeks
                        || currentAllocation.hsl_Rate?.value !== allocation.hsl_Rate?.value

                }
            })
        })
        const updateAssignmentsPromises = resourceAllocationToUpdate?.map((allocation) => {
            return dataSource.webApi.swagger.resourceAllocation.resourceAllocationUpdate({
                ...allocation
            })

        })
        await Promise.all([...updateAssignmentsPromises])
        await dataSource.webApi.swagger.investmentSummary.investmentSummaryGenerateInvestmentSummaryBySalesEstimateId({ salesEstimateId: currentState?.estimate?.id });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)

    }
    finally {
        setIsLoading(false);
    }
}

export const generateSalesEstimateResourceAllocation = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.resourceAllocation.resourceAllocationGenerateResourceAllocations({ salesEstimateId: currentState?.estimate?.id });
        await dataSource.webApi.swagger.investmentSummary.investmentSummaryGenerateInvestmentSummaryBySalesEstimateId({ salesEstimateId: currentState?.estimate?.id });
    }

    finally {
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
        setIsLoading(false);
    }
}

export const updateSalesEstimateInvestmentSummary = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, investmentSummary: InvestmentSummaryDto | undefined) => {
    try {
        setIsLoading(true);
        if (investmentSummary) {
            const productPricingUpdatePromises = investmentSummary.hsl_investsummaryprodpricing_InvestSummaryId?.forEach((product) => {
                return (dataSource.webApi.swagger.investmentSummaryProductPricing.investmentSummaryProductPricingUpdate({ ...product }))
            }) || []
            await Promise.all(productPricingUpdatePromises)
            await dataSource.webApi.swagger.investmentSummary.investmentSummaryUpdate({ ...investmentSummary });
            await dataSource.webApi.swagger.investmentSummary.investmentSummaryGenerateInvestmentSummaryBySalesEstimateId({ salesEstimateId: investmentSummary.hsl_SalesEstimate?.id });
            await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
        }
    }

    finally {
        setIsLoading(false);
    }
}

export const addSalesEstimateResourcePlan = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, resourcePlanName: string) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.resourcePlan.resourcePlanAdd({
            hsl_name: resourcePlanName,
            hsl_salesestimateid: {
                id: currentState.estimate.id,
                logicalName: "hsl_salesestimate"
            }
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)

    }

    finally {
        setIsLoading(false);
    }
}


export const applySalesEstimateTemplates = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, templateIds: string[]) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimate.salesEstimateApplyTemplatesToSalesEstimate(templateIds, { salesEstimateId: currentState?.estimate?.id });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }

    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimatePracticeAssignment = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, practiceAssignment: SalesEstimatePracticeAssignmentDto) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimatePracticeAssignment.salesEstimatePracticeAssignmentUpdate({
            id: practiceAssignment.id,
            hsl_name: practiceAssignment.hsl_name,
            hsl_practiceid: { id: practiceAssignment.hsl_practiceid?.id },
            hsl_salesestimateid: { id: practiceAssignment.hsl_salesestimateid?.id },
            ownerId: { id: practiceAssignment.ownerId?.id },
            statecode: practiceAssignment.statecode,
            statuscode: practiceAssignment.statuscode,
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }
    finally {
        setIsLoading(false);
    }
}

export const addSalesEstimateProductLineItem = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, productLineItem: ProductLineItem) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimateProductLineItem.salesEstimateProductLineItemAdd({
            hsl_Description: productLineItem.description,
            hsl_BillingMethod: Number(productLineItem.billingMethod),
            hsl_ProductId: { id: productLineItem.productId },
            hsl_CustomerUserCount: productLineItem.customerUserCount,
            hsl_SalesEstimateId: { id: currentState.estimate.id },
            hsl_Name: currentState.loadedProducts?.find(product => product.id === productLineItem.productId)?.name
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }
    finally {
        setIsLoading(false);
    }
}

export const updateSalesEstimateProductLineItem = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, productLineItem: ProductLineItem) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimateProductLineItem.salesEstimateProductLineItemUpdate({
            hsl_BillingMethod: Number(productLineItem.billingMethod),
            hsl_Description: productLineItem.description,
            hsl_Name: productLineItem.name,
            hsl_PricingMethodCode: Number(productLineItem.pricingMethodCode),
            hsl_SalesEstimateTemplateId: productLineItem.hsl_SalesEstimateTemplateId,
            hsl_TemplateLineItemId: { id: productLineItem.hsl_TemplateLineItemId?.id },
            hsl_TotalPrice: { value: productLineItem.totalPrice },
            hsl_UnitPrice: { value: productLineItem.unitPrice },
            id: productLineItem.id,
            hsl_ProductId: { id: productLineItem.productId },
            hsl_CustomerUserCount: productLineItem.customerUserCount,
            hsl_SalesEstimateId: { id: currentState.estimate.id }
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }

    finally {
        setIsLoading(false);
    }
}

export const deleteSalesEstimateProductLineItem = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, productLineItemId: string) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.salesEstimateProductLineItem.salesEstimateProductLineItemDelete({
            id: productLineItemId,
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }
    finally {
        setIsLoading(false);
    }
}

export const saveSalesEstimateResourcePlan = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, resourcePlan: ResourcePlanDto) => {
    try {
        setIsLoading(true);
        const estimateId = currentState.estimate.hsl_resourceplanid?.id === resourcePlan.id ? { id: currentState.estimate.id } : undefined
        // verify resourceplan allocations
        // do some data verifications
        //hsl_resrouceplan on sales estimate is this, then set hsl_salesestimate on the bulk update resource allocations, otherwise null
        const resources: BulkUpdateResourcePlanResourceDto[] = resourcePlan.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
            const entries = resource.hsl_hsl_resourceplanentry_resourceplanresourc
            const initateEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630000)
            const blueprintEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630001)
            const buildEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630002)
            const validateEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630003)
            const deployEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630004)
            const operateEntries = entries?.filter((entry) => entry.hsl_projectphase === HslProjectphase.Option_864630005)
            return {
                ...resource,
                hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation,
                    hsl_Hours: initateEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: initateEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
                hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation,
                    hsl_Hours: blueprintEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: blueprintEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
                hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation,
                    hsl_Hours: buildEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: buildEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
                hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation,
                    hsl_Hours: validateEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: validateEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
                hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation,
                    hsl_Hours: deployEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: deployEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
                hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation: {
                    ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation,
                    hsl_Hours: operateEntries?.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0),
                    hsl_Weeks: operateEntries?.length,
                    hsl_SalesEstimate: estimateId
                },
            }
        }) || []
        await dataSource.webApi.swagger.resourcePlan.resourcePlanBulkUpdateResourcePlan({
            data: {
                resourcePlan: {
                    id: resourcePlan.id,
                    hsl_name: resourcePlan.hsl_name,
                    hsl_salesestimateid: { id: currentState.estimate.id },
                    hsl_InvestmentSummary: { id: currentState.estimate.hsl_InvestmentSummary?.id },
                    hsl_hsl_resourceplanresource_resourceplanid_h: resources
                },
                resourcePlanResourceIds: currentState.deletedResourceRoles,
                resourcePlanEntryIds: currentState.deletedResourceEntries

            }
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
        dispatchAction({
            type: EEstimateActionType.estimate_clear_resource_plan_state
        })
    }

    finally {
        setIsLoading(false);
    }
}
export const saveSalesEstimateSelectedResourcePlan = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, resourcePlanId: string) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.resourcePlan.resourcePlanApplyResourcePlanAsDefaultForSalesEstimate({
            data: {
                salesEstimateId: currentState.estimate.id,
                resourcePlanId: resourcePlanId
            }
        });
        await fetchSalesEstimate(currentState, dataSource, setIsLoading, dispatchAction, currentState?.estimate?.id!)
    }

    finally {
        setIsLoading(false);
    }
}

export const generateSalesEstimateSOW = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, salesEstimateId: string) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.documentGeneration.documentGenerationGenerateStatementOfWork({
            salesEstimateId: salesEstimateId,
        });
        const estimateOutputs = await dataSource.webApi.swagger.annotation.annotationGetAllDocumentsBySalesEstimateId({ salesEstimateId: salesEstimateId });
        dispatchAction({
            type: EEstimateActionType.estimate_load_outputs,
            estimateOutputs: estimateOutputs.data.data || [],
        });
    }
    catch (error) {
        alert('Something went wrong with sales estimate, updating Practice Assignment');
    }
    finally {
        setIsLoading(false);
    }
}

export const generateSalesEstimateWBS = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, salesEstimateId: string) => {
    try {
        setIsLoading(true);
        await dataSource.webApi.swagger.documentGeneration.documentGenerationGenerateWorkBreakdownStructure({
            salesEstimateId: salesEstimateId,
        });
        const estimateOutputs = await dataSource.webApi.swagger.annotation.annotationGetAllDocumentsBySalesEstimateId({ salesEstimateId: salesEstimateId });
        dispatchAction({
            type: EEstimateActionType.estimate_load_outputs,
            estimateOutputs: estimateOutputs.data.data || [],
        });
    }
    catch (error) {
        alert('Something went wrong with sales estimate, updating Practice Assignment');
    }
    finally {
        setIsLoading(false);
    }
}

export const notifyUser = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, users: SystemUserDto[], commenterName: string) => {
    try {
        setIsLoading(true);
        const promises = users.map((user) => {
            return dataSource.webApi.swagger.notification.notificationAdd({
                subject: "New Mention",
                regardingObjectId: { id: currentState.estimate.id, logicalName: "hsl_salesestimate" },
                hsl_Message: `You have been mentioned in a comment by ${commenterName} on Sales Estimate ` + currentState.estimate.hsl_name,
                statusCode: HslNotificationStatuscode.Option_1,
                stateCode: HslNotificationStatecode.Option_0,
                ownerId: { id: user.id, logicalName: 'systemuser' }
            });
        })
        await Promise.all(promises)
    }
    catch (error) {
        alert('Something went wrong notifying the user');
    }
    finally {
        setIsLoading(false);
    }
}

export const getSalesEstimateComments = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, salesEstimateId: string) => {
    try {
        setIsLoading(true);
        const estimateComments = await dataSource.webApi.swagger.annotation.annotationGetAllNotesBySalesEstimateId({ salesEstimateId: currentState.estimate.id });
        dispatchAction({
            type: EEstimateActionType.estimate_load_comments,
            estimateComments: estimateComments.data.data || [],
        });
    }
    catch (error) {
        alert('Something went wrong with sales estimate, updating Practice Assignment');
    }
    finally {
        setIsLoading(false);
    }
}

export const addSalesEstimateComment = async (currentState: IEstimateState, dataSource: DataSource, setIsLoading: (isLoading: boolean) => void, dispatchAction: React.Dispatch<TEstimateAction>, comment: string, userId: string, users: SystemUserDto[], commenterName: string) => {
    try {
        setIsLoading(true);
        const annotation = {
            "objectId": {
                "id": currentState.estimate.id,
                "logicalName": "hsl_salesestimate",
                "name": currentState.estimate.hsl_name
            },
            "noteText": comment,
            "subject": "Estimate Comment",
            "isDocument": false,
            "createdBy": {
                "id": userId
            },
        }
        await dataSource.webApi.swagger.annotation.annotationAdd(annotation);
        getSalesEstimateComments(currentState, dataSource, setIsLoading, dispatchAction, currentState.estimate.id!)
        const promises = users.map((user) => {
            return dataSource.webApi.swagger.notification.notificationAdd({
                subject: "New Mention",
                regardingObjectId: { id: currentState.estimate.id, logicalName: "hsl_salesestimate" },
                hsl_Message: `You have been mentioned in a comment by ${commenterName} on Sales Estimate ` + currentState.estimate.hsl_name,
                statusCode: HslNotificationStatuscode.Option_1,
                stateCode: HslNotificationStatecode.Option_0,
                ownerId: { id: user.id, logicalName: 'systemuser' }
            });
        })
        await Promise.all(promises)

    }
    catch (error) {
        alert('Something went wrong with sales estimate, updating Practice Assignment');
    }
    finally {
        setIsLoading(false);
    }
}