
export const ErrorPage = (props: { error: any }) => {
    const { error } = props
    return (
        <div id="error-page" style={{ paddingLeft: '20px' }}>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>Error: {error?.error?.title // backend api error format
                    || error?.message // ADD error format
                }</i>
            </p>
        </div>
    );
}