import { IPractice } from "../../models/API/IPractice.type";
import { IDataGridProps } from "./IDataGridProps.type";

const DataGrid = (props: IDataGridProps<IPractice[]>) => {

  return <ul>
    {props?.data?.map(eachPractice => {
      return <li key={eachPractice.id}>
        {eachPractice.practiceName}
      </li>;
    })}
  </ul>
}
export default DataGrid;