import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo-estimator.svg';
import { useApplication } from '../ApplicationContextProvider/ApplicationContextProvider';
import MenuIcon from "@mui/icons-material/Menu";

import Notification from '../Notification/Notification';
import Profile from '../Profile';
import { EStatusActionType } from 'store/reducers/StatusReducer';
import { SignOutButton } from 'components/SignOut/SignOutButton';
import { LogoutOutlined } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';

const Navbar: React.FC = () => {

	const application = useApplication();
	const { instance } = useMsal();

	const [status, dispatchStatus] = application.statusReducer;

	return (
		<AppBar id={"master-navbar"} position="relative" sx={{zIndex: 10000}}>
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="Go to dashboard page" style={{ padding: '0', marginLeft: '0' }}
					onClick={() => dispatchStatus({ type: EStatusActionType.Status_sidebar_open_toggle })}
				>
					<MenuIcon />
					{/* <Logo id="Estimator Logo" style={{ width: '32px', height: '32px' }} width="32" height="32" viewBox="0 0 32 32" fill="none" /> */}
				</IconButton>
				<Box sx={{ width: '100%', ml: 1 }} />
				<Notification />
				<Profile />
				<IconButton size="large" color="secondary" onClick={() => { instance.logout() }}>
					<LogoutOutlined />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
