import { Alert, AlertTitle, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { ReactComponent as HitachiLogo } from 'assets/icons/hitachi-solutions-logo.svg';
import './Button.css';
import { ILoginProps } from "./ILoginProps";
import { ReactComponent as Logo } from '../../assets/logo-estimator.svg';
import { error } from "console";

const Login: React.FunctionComponent<ILoginProps> = (props) => {
    return <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto auto auto' }}>

        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ borderTop: '2px solid red' }}
        >
            <DialogTitle id="alert-dialog-title">
                <div style={{ display: 'flex' }}>
                    <HitachiLogo style={{ width: '200px' }} width={'180px'} />
                    {/* <div style={{
                        borderLeft: "1px solid #38546d",
                        borderRight: "1px solid #16222c",
                        height: "40px",
                        marginLeft: '25px',
                        marginRight: '25px'
                    }}></div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
                        <Logo id="Estimator Logo" style={{ width: '24x', height: '24px' }} width="32" height="32" viewBox="0 0 32 32" fill="none" />
                        <Typography variant="caption">Estimator</Typography>
                    </div> */}
                </div>
            </DialogTitle>
            <DialogContent>

                {props.error &&
                    <Alert severity="error" sx={{ marginBottom: '8px' }}>
                        <AlertTitle>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <span>
                                    Authentication Failed...
                                </span>
                            </div>
                        </AlertTitle>
                        {props.error}
                    </Alert>
                }
                {props.processing &&
                    <div>
                        Processing
                    </div>
                }
                <Typography>
                    Some components of this app require you to sign in. if you don't sign in, parts of the app may not work.
                </Typography>
                <Typography>
                    To continue working, please sign in again.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button disabled>Cancel</Button>
                <Button size="small" color="primary" variant="contained" onClick={props.onSignIn} autoFocus>
                    Authenticate
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}
export default Login;