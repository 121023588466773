import { Autocomplete, AutocompleteRenderInputParams, Box, Button, FormControl, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, Stack, TextField, Toolbar, Typography } from "@mui/material"
import { FormatDate, ShortenCurrency } from "TSFunctions";
import { useApplication } from "components/ApplicationContextProvider/ApplicationContextProvider";
import { IEstimate } from "models/IEstimate";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useSalesEstimate from "views/Estimates/EstimatesHooks/useSalesEstimate";
import { EEstimateActionType } from "store/reducers/EstimateReducer";
import dayjs from 'dayjs';
import { ReactNode, useRef, useState } from "react";
import { QualifiedBudgetInput } from "./QualifiedBudgetInput";
import { UserCountInput } from "./UserCountInput";
import { UserOptions, UserSearch } from "./UserSearch";
import MuiButton from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import CheckIcon from '@mui/icons-material/Check';
import { useIsTruncated } from "./useIsTruncated";
import { Config } from "config";
import { SaveChangesModal } from "components/Modal/SaveChangesModal";
import { unstable_usePrompt } from "react-router-dom";

interface ISummaryProps {
    setIsDirty: (isDirty: boolean) => void
}

const Summary: React.FunctionComponent<ISummaryProps> = (props) => {

    const application = useApplication();
    const salesEstimateReducer = useSalesEstimate()
    const { stateEstimate, dispatchEstimate, isAPILoading, error } = salesEstimateReducer
    const divSynopsis = useRef(null)
    const [synopsis, setSynopsis] = useState<string>(stateEstimate?.estimate?.hsl_Synopsis || "None");
    const [isSynopsisDisabled, setIsSynopsisDisabled] = useState<boolean>(false);
    const [isViewMoreVisible, setisViewMoreVisible] = useState<boolean>(true);
    const isEllipsisActive = useIsTruncated(divSynopsis, isSynopsisDisabled)

    const canEditOverview = (application.identity?.xrm.isSales || application.identity?.xrm.isSolutionPrincipal || application.identity?.xrm.isAdmin) && !stateEstimate.isReadOnly
    return <Box sx={{ marginBottom: '50px', marginTop: '10px' }}>{/* Overview Section */}
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Account: </Typography>
                <Link target="_blank" underline="always" href={`${Config.dynamics.baseUrl}/main.aspx?etn=account&id={${stateEstimate.estimate.hsl_opportunityid?.id}}&newWindow=true&pagetype=entityrecord`} variant="body1">
                    {stateEstimate.estimate.hsl_Account?.name}
                </Link>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Opportunity: </Typography>
                <Link target="_blank" underline="always" href={`${Config.dynamics.baseUrl}/main.aspx?etn=opportunity&id={${stateEstimate.estimate.hsl_opportunityid?.id}}&newWindow=true&pagetype=entityrecord`} variant="body1">
                    {stateEstimate.estimate.hsl_opportunityid?.name}
                </Link>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Sales Region: </Typography>
                <Typography variant="body1">
                    {stateEstimate?.estimate?.hsl_salesregionid?.name || "-"}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Type: </Typography>
                {/* <Typography variant="body1">
                    {application.cache.options?.hsl_salesestimate_hsl_type?.find(code => code.value === stateEstimate.estimate.statuscode)?.name}
                </Typography> */}
                <FormControl variant="standard" fullWidth>
                    <Select
                        style={{ height: 37, maxWidth: '80%' }}
                        disabled={!canEditOverview}
                        size='small'
                        value={stateEstimate?.estimate?.hsl_type}
                        onChange={(event, child) => {
                            if (event.target.value) {
                                dispatchEstimate({
                                    type: EEstimateActionType.estimate_update,
                                    estimate: { hsl_type: Number(event.target.value) }
                                })
                            }
                        }}
                    >
                        {
                            application?.cache?.options?.hsl_salesestimate_hsl_type.map((type) => (
                                <MenuItem key={type.value} value={type.value?.toString()}>
                                    {type.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Estimate Due: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_estimatedue ? FormatDate(new Date(props.estimate.hsl_estimatedue), 'en-US-ET') : 'N/A'}
                </Typography> */}
                <DatePicker
                    slotProps={{ textField: { variant: 'standard', } }}
                    // label="Estimate Due"
                    sx={{ width: '80%' }}
                    disabled={!canEditOverview}
                    value={dayjs(stateEstimate.estimate.hsl_estimatedue)}
                    onChange={(newValue) => {
                        props.setIsDirty(true)
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: { hsl_estimatedue: newValue?.toISOString() }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Contract Due: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_contractdue ? FormatDate(new Date(props.estimate.hsl_contractdue), 'en-US-ET') : 'N/A'}
                </Typography> */}
                <DatePicker
                    slotProps={{ textField: { variant: 'standard', } }}
                    // label="Estimate Due"
                    disabled={!canEditOverview}
                    sx={{ width: '80%' }}
                    value={dayjs(stateEstimate.estimate.hsl_contractdue)}
                    onChange={(newValue) => {
                        props.setIsDirty(true)
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: { hsl_contractdue: newValue?.toISOString() }
                        })
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Qualified Budget: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_qualifiedbudget ? ShortenCurrency(props.estimate.hsl_qualifiedbudget) : '?'}
                </Typography> */}
                <QualifiedBudgetInput disabled={!canEditOverview} onChange={() => { props.setIsDirty(true) }} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">User Count: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_CustomerUserCount}
                </Typography> */}
                <UserCountInput disabled={!canEditOverview} onChange={() => { props.setIsDirty(true) }} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Solution Principal: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_clientservicesprincipleid?.name}
                </Typography> */}
                <UserSearch title={""}
                            style={{minWidth: '200px'}}
                    value={{ ...stateEstimate.estimate.hsl_clientservicesprincipleid }}
                    disabled={!canEditOverview}
                    onSelect={(user: UserOptions) => {
                        props.setIsDirty(true)
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: { hsl_clientservicesprincipleid: { id: user.id, name: user.name } }
                        });
                    }} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Director of Business Development: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.hsl_directorofbusinessdevelopmentid?.name}
                </Typography> */}
                <UserSearch title={""}
                    style={{ maxHeight: '30px', minWidth: '200px'}}
                    disabled={!canEditOverview}
                    onSelect={(user: UserOptions) => {
                        props.setIsDirty(true)
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: { hsl_directorofbusinessdevelopmentid: { id: user.id, name: user.name } }
                        });
                    }} value={stateEstimate.estimate.hsl_directorofbusinessdevelopmentid} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">Owner: </Typography>
                {/* <Typography variant="body1">
                    {props.estimate.ownerId?.name}
                </Typography> */}
                <UserSearch title={""}
                    style={{ maxHeight: '30px', minWidth: '200px' }}
                    disabled={!canEditOverview}
                    onSelect={(user: UserOptions) => {
                        props.setIsDirty(true)
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: { ownerId: { id: user.id, name: user.name } }
                        });
                    }} value={stateEstimate.estimate.ownerId} />
            </Grid>

        </Grid>
        <Box mt={4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {
                        <>
                            <Typography variant="h6">Synopsis: </Typography>
                            <Stack justifyContent="space-between" direction="row">
                                {/* {isSynopsisDisabled ? */}
                                <div style={{ width: '100%' }}>
                                    <div style={
                                        !isSynopsisDisabled ? {
                                            display: "none"
                                        } : {}
                                    }>
                                        <TextField fullWidth
                                            disabled={!canEditOverview}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                props.setIsDirty(true)
                                                setSynopsis(event.target.value);
                                            }}
                                            // variant="outlined"
                                            InputProps={{ disableUnderline: true }}
                                            inputProps={{ maxLength: 5000 }}
                                            multiline
                                            // sx={{
                                            //     'div': {
                                            //         paddingTop: '0px',
                                            //         marginTop: '10px'
                                            //     },
                                            // }}
                                            value={synopsis}
                                            sx={{}}
                                        />
                                    </div>
                                    <div style={
                                        isSynopsisDisabled ? {
                                            display: "none"
                                        } : {}
                                    }
                                    >
                                        <Box
                                            ref={divSynopsis}
                                            component="div" sx={{
                                                marginTop: '10px',
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                "-webkit-box-orient": "vertical",
                                                "-webkit-line-clamp": isViewMoreVisible ? "3" : undefined, /* start showing ellipsis when 3rd line is reached */
                                                whiteSpace: "pre-wrap", /* let the text wrap preserving spaces */
                                            }}>
                                            {synopsis}
                                        </Box>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {divSynopsis?.current && isEllipsisActive &&
                                                < Link sx={{ marginTop: '5px' }} onClick={() => setisViewMoreVisible((prev) => !prev)}>
                                                    {isViewMoreVisible ? 'View More ' : "View Less"}
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* } */}
                                <MuiButton
                                    variant="text"
                                    sx={{ float: 'right', maxHeight: '100px' }}
                                    disabled={!canEditOverview}
                                    onClick={() => {
                                        setIsSynopsisDisabled(prev => !prev)
                                        // forceUpdate()
                                        dispatchEstimate({
                                            type: EEstimateActionType.estimate_update,
                                            estimate: { hsl_Synopsis: synopsis }
                                        });
                                    }}
                                >
                                    {!isSynopsisDisabled ? <EditIcon /> : <CheckIcon />}
                                </MuiButton>
                            </Stack>
                        </>
                    }
                </Grid>
            </Grid>
        </Box >
    </Box >

}

export default Summary;
