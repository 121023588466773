import { Avatar, AvatarProps } from "@mui/material";
import { IUserAvatarProps } from "./IUserAvatarProps";
import { useApplication } from "components/ApplicationContextProvider/ApplicationContextProvider";
import { useEffect, useState } from "react";


const UserAvatar: React.FC<IUserAvatarProps> = (props) => {

    const { azureActiveDirectoryObjectId, ...baseProps } = props;
    const application = useApplication();
    const [status, dispatchStatus] = application.statusReducer;

    const [src, setSrc] = useState<string | undefined | null>(azureActiveDirectoryObjectId
        ? application.dataSource.getUserPhotoObjectURLCache(azureActiveDirectoryObjectId)
        : undefined);

    useEffect(() => {
        if (!src && application.ready)
            (async () => {
                if (azureActiveDirectoryObjectId) {
                    const url = await application.dataSource.getUserPhotoObjectURL(azureActiveDirectoryObjectId);
                    setSrc(url);
                }
            })();
    }, [application.dataSource, application.ready]);

    return <Avatar
        {...baseProps}
        src={src ?? undefined}
    />
}

export default UserAvatar;