// ==============================|| OVERRIDES - BUTTON ||============================== //

import { ComponentsOverrides, Theme } from "@mui/material";

export default function MuiAppBar(theme: Theme) {
    const styleOverrides: ComponentsOverrides<Theme>['MuiAppBar'] = {
        root: {
            // fontWeight: 100,
        },
    };

    return {
        MuiAppBar: {
            defaultProps: {
                // disableElevation: true
            },
            styleOverrides: styleOverrides
        }
    };
}
