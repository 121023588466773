import { Button, ButtonTypeMap, ExtendButtonBase, SxProps, Theme } from "@mui/material"

interface IActionButtonProps {//extends ExtendButtonBase<ButtonTypeMap<{}, "button">> {
    label: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    primary?: boolean;
    sx?: SxProps<Theme>;
    disabled?: boolean;
    size?: "small" | "medium" | "large";
};

// TODO replace this with styled override of button/material ui
const ActionButton: React.FunctionComponent<IActionButtonProps> = (props) => {
    return <Button
        size={props.size ?? "small"}
        variant={props.primary ? "contained" : "outlined"}
        color={props.primary ? "primary" : "secondary"}
        onClick={props.onClick}
        sx={{ minWidth: '120px', ...props.sx }}
        disabled={props.disabled}
    >
        {props.label}
    </Button>
}

export default ActionButton;