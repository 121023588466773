import { Alert, AlertColor, Snackbar, SnackbarOrigin, SxProps, Theme } from '@mui/material'

const ModalNotification = (props: { isOpen: boolean, message: string, alertSeverity: AlertColor, anchorOrigin?: SnackbarOrigin, onClose?: () => void, sx?: SxProps<Theme> }) => {

    return (
        <Snackbar
            anchorOrigin={props.anchorOrigin ? { ...props.anchorOrigin } : { vertical: 'bottom', horizontal: 'center' }}
            open={props.isOpen}
            autoHideDuration={4000}
            onClose={props?.onClose}
            sx={props.sx} >
            <Alert severity={props.alertSeverity}>
                {props.message}
            </Alert>
        </Snackbar >
    )
}

export default ModalNotification;