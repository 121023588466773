import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Modal } from '@mui/material';
import MuiButton from '@mui/material/Button';
//To do: verify that the ModalNotification files are in the right folder
import ModalNotification from '../../../../components/Notification/ModalNotification';
import Mock from '../../../../store/mock';
import { IEstimateTemplate } from '../../../../models/API/IEstimateTemplate.type';

import { IModalChooseTemplateProps } from './IModalChooseTemplateProps';

const ModalChooseTemplate: React.FunctionComponent<IModalChooseTemplateProps> = (props) => {
  const successAlertColor: any = 'success';
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedTemplates, setSelectedTemplates] =
    useState<{ label: string; id: string }[]>();
  const [data, setData] = useState<{ label: string; id: string }[]>([]);

  const styleModalPopup = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1115,


    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    (async () => {
      const data: IEstimateTemplate[] = await Mock.TemplateGetAll();


      const templateLookupData: { label: string; id: string }[] = data.map(
        (template) => {
          return { label: template.description, id: template.id };
        }
      );
      setData(templateLookupData);
    })();
  }, []);


  const HandleAdd = () => {
    if (selectedTemplates) {
      let tempatesString = selectedTemplates.map((t) => `${t.id}:${t.label}\n`);
      alert(`******SELECTED TEMPLATES******\n\n${tempatesString}`);
      setDisableButton(true);
      setNotificationOpen(true);
    }
  };

  const onRenderMain = () => {
    return (
      <Box>
        <h1>Add a Template</h1>
        <Autocomplete
          multiple
          id="combo-box-template"
          onChange={(event, value) => {
            const selectedTemplates = value.map((option, index: number) => {
              return option
            })
            setSelectedTemplates(selectedTemplates)
          }}
          options={data}
          sx={{ width: 647 }}
          renderInput={(params) => <TextField {...params} />}
        />
        <ModalNotification
          isOpen={notificationOpen}
          message="Added"
          alertSeverity={successAlertColor}
        />
        <footer>
          <br />
          <MuiButton
            variant="contained"
            sx={{ float: 'right' }}
            onClick={HandleAdd}
            disabled={disableButton}
          >
            Add
          </MuiButton>
        </footer>
      </Box>
    );
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box sx={styleModalPopup}>{onRenderMain()}</Box>
    </Modal>
  );
};

export default ModalChooseTemplate;
