import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Link
} from '@mui/material';
import { Typography } from '@mui/material';
import MuiButton from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

import { IProjectLinksProps } from './IProjectLinksProps';
import * as ISwaggerAPI from 'data/swagger/API';
import ModalAddLink from './ModalAddLink';
import ActionButton from 'components/ActionButton';
import useSalesEstimate, { EEstimateAPIActionType } from 'views/Estimates/EstimatesHooks/useSalesEstimate';
import { MuiDataGrid } from "../../../../../components/DataGrid/MuiDataGrid";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';

const ProjectLinks: React.FunctionComponent<IProjectLinksProps> = (props) => {
  const [links, setLinks] = useState<ISwaggerAPI.LinkDto[]>(
    props.projectLinks
  );

  useEffect(() => {
    setLinks(props.projectLinks)
  }, [props.projectLinks])
  const { identity } = useApplication();
  const salesEstimateReducer = useSalesEstimate()
  const { stateEstimate, isAPILoading, error, dispatchAPIEstimate } = salesEstimateReducer
  const [selectedLink, setSelectedLink] = useState<ISwaggerAPI.LinkDto | null>(null);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number>(-1); //Using this because there is no Link id in the ILinkResonse,
  //so need a way to identify the selected link after it is updated in the ModalAddLink
  const [linkAction, setLinkAction] = useState('Add');
  const [isOpenModalAddLink, setIsOpenModalAddLink] = useState(false);
  const handleOpenModalAddLink = (): void => setIsOpenModalAddLink(true);
  const canEdit = !stateEstimate.isReadOnly && !identity?.xrm.isReadOnly

  const LinkGridHeader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <Typography variant="h2">Links</Typography>
        <ActionButton
          label='Add'
          primary
          sx={{ minWidth: '120px', marginRight: 1 }}
          disabled={!canEdit}
          onClick={() => {
            setLinkAction('Add');
            setSelectedLink(null);
            handleOpenModalAddLink();
          }}
        />
        <ModalAddLink
          defaultValue={selectedLink}
          action={linkAction}
          isOpen={isOpenModalAddLink}
          onChange={(projectLink: ISwaggerAPI.LinkDto) => {
            const projectLinkHttps = { ...projectLink, hsl_link: `${projectLink.hsl_link}` }
            const newLinks = () => {
              if (linkAction === 'Add') {
                const nProjectLink = projectLinkHttps;
                nProjectLink.hsl_salesestimate = { id: props.hsl_salesestimateid };
                const nProjectLinks = [...links, nProjectLink];
                return nProjectLinks;
              } else {
                const uProjectLinks = [...links];
                uProjectLinks[selectedLinkIndex] = projectLinkHttps;
                return uProjectLinks;
              }
            }
            dispatchAPIEstimate({
              type: EEstimateAPIActionType.estimate_links_change,
              links: newLinks()
            })
          }}
          onClose={() => {
            setIsOpenModalAddLink(false);
          }}
        />
      </div>
    )
  }


  const rows: GridValidRowModel[] = useMemo(() => {
    const retObj: GridValidRowModel[] = [];
    links.map((link, index) => {
      retObj.push({
        id: index,
        link: link.hsl_link ?? '#',
        name: link.hsl_name
      });
    });
    return retObj;


  }, [links]);
  const columns: GridColDef[] = [
    {
      field: 'name', headerName: 'Name', minWidth: 400, type: "string", flex: 2, renderCell: (params: GridRenderCellParams<any>) => {
        return <>
          <Link target="_blank" href={params.row.link}>{params.row.name}</Link>
        </>
      }
    },
    {
      field: 'modify', headerName: '', minWidth: 100, type: "string", flex: 2, align: "right", renderCell: (params: GridRenderCellParams<any>) => {
        return <>
          <MuiButton
            variant="text"
            sx={{ float: 'right' }}
            disabled={!canEdit}
            onClick={() => {
              const newLinksDeleted = () => {
                const t = links.filter((_, ix) => ix !== params.row.id);
                const nLinkRows = [...t];
                return nLinkRows;
              }
              dispatchAPIEstimate({
                type: EEstimateAPIActionType.estimate_links_change,
                links: newLinksDeleted()
              })
            }}

          >
            <DeleteIcon />
          </MuiButton>
          <MuiButton
            disabled={!canEdit}
            variant="text"
            sx={{ float: 'right' }}
            onClick={() => {
              setSelectedLink(links[params.row.id]);
              setSelectedLinkIndex(params.row.id);
              setLinkAction('Update');
              handleOpenModalAddLink();
            }}
          >
            <EditIcon />
          </MuiButton>
        </>
      }
    }
  ];


  return (
    <Box component={Paper}>
      <LinkGridHeader />
      <MuiDataGrid rows={rows} columns={columns} />
    </Box>
  );
};

export default ProjectLinks;
