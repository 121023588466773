import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import componentsOverride from './overrides';
import Palette from './palette';
import Typography from './typography';

const defaultTheme = createTheme({
    typography: {
        fontFamily: '"Segoe UI", sans-serif',
        h1: {
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '24px',
        },
        h2: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
        },
        h3: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '16px',
        },
        h4: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
        },
        h5: {
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '16px',
        },
        h6: {
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '16px',
        },
        body1: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        body2: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
        },
        button: {},
        subtitle1: {},
        subtitle2: {},
        caption: {},
        overline: {},

	},

});

export default function ThemeCustomization({ children }: { children: JSX.Element[] | JSX.Element }) {
    const themePalette = Palette('light');
    const themeTypography = Typography();

    const themeOptions = useMemo(
        () => {
            const themeOpt: ThemeOptions = {
                ...defaultTheme,

                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 768,
                        md: 1024,
                        lg: 1266,
                        xl: 1536
                    }
                },
                direction: 'ltr',
                mixins: {
                    toolbar: {
                        minHeight: 60,
                        paddingTop: 8,
                        paddingBottom: 8
                    }
				},

                palette: themePalette.palette,
                typography: { ...defaultTheme.typography, ...themeTypography },

            }

            return themeOpt
        },
        [themePalette, themeTypography]
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
