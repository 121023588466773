import { SystemUserDto } from "data/swagger/API";
import { AzureDirectoryTokenProvider } from "./core/AzureDirectoryTokenProvider";
import { MSGraphDataSource } from "./core/MSGraphDataSource";
import { NetDataSource } from "./core/NetDataSource";
import { XrmDataSource } from "./core/XrmDataSource";
import { ArrayToChunk } from "TSFunctions";
import { b64toBlob } from "views/Templates/Template/helpers";

export class DataSource {

    // https://github.com/AzureAD/microsoft-authentication-library-for-objc/issues/774
    readonly webApi: NetDataSource;
    readonly msGraphApi: MSGraphDataSource;
    readonly xrmApi: XrmDataSource;

    readonly objectURLCacheByID = new Map<string, string | null>();

    constructor(
        providers: {
            webApiTokenProvider: AzureDirectoryTokenProvider,
            msGraphApiTokenProvider: AzureDirectoryTokenProvider,
            xrmApiTokenProvider: AzureDirectoryTokenProvider,
        }
    ) {

        this.webApi = new NetDataSource(providers.webApiTokenProvider);
        this.msGraphApi = new MSGraphDataSource(providers.msGraphApiTokenProvider);
        this.xrmApi = new XrmDataSource(providers.xrmApiTokenProvider);
    }

    /**
     * WhoAmI
     */
    public async WhoAmI() {
        return await this.webApi.swagger.systemUser.systemUserGetCurrentUserInformation();
    }

    // https://learn.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
    public async myProfileImage() {
        // TODO cache/indexdb
        // 48x48, 64x64, 96x96, 120x120, 240x240, 360x360, 432x432, 504x504, and 648x648
        const response = await this.msGraphApi.raw("GET", "me/photos/120x120/$value");
        const blob = await response.blob();
        const image = URL.createObjectURL(blob);
        return image;
    }

    public getUserPhotoObjectURLCache(azureObjectId: string) {
        return this.objectURLCacheByID.get(azureObjectId) ?? undefined;
    }

    public async getUserPhotoObjectURL(azureActiveDirectoryObjectId: string) {

        let imageURL: string | null = null;
        try {
            if (azureActiveDirectoryObjectId !== '00000000-0000-0000-0000-000000000000')
                if (this.objectURLCacheByID.has(azureActiveDirectoryObjectId)) {
                    imageURL = this.objectURLCacheByID.get(azureActiveDirectoryObjectId)!;
                }
                else {
                    // 48x48, 64x64, 96x96, 120x120, 240x240, 360x360, 432x432, 504x504, and 648x648
                    const response = await this.msGraphApi.raw("GET", `users/${azureActiveDirectoryObjectId}/photo/$value`);
                    const blob = await response.blob();
                    imageURL = URL.createObjectURL(blob);
                    this.objectURLCacheByID.set(azureActiveDirectoryObjectId, imageURL);
                    return imageURL;
                }

        } catch (error) {
            this.objectURLCacheByID.set(azureActiveDirectoryObjectId, imageURL);
        } finally {
            return imageURL;
        }
    }

    // https://learn.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
    public async directoryUsers() {
        // TODO cache/indexdb
        // 48x48, 64x64, 96x96, 120x120, 240x240, 360x360, 432x432, 504x504, and 648x648
        const response = await this.msGraphApi.execute("GET", "users");
        return response;
    }


    public async getDynamicsUsers() {
        const systemUsers: SystemUserDto[] = [];

        const req = await this.webApi.swagger.systemUser.systemUserGetAllActiveUsersCount();
        const count = req.data.data!
        const pageSize = 999;
        const totalPages = Math.ceil(count / pageSize);

        const systemUserGetAllRequests = Array.from({ length: totalPages }, (_, i) => i + 1).map(currentPage =>
            this.webApi.swagger.systemUser.systemUserGetAllActiveUsers(
                {
                    data: {
                        pageSize: pageSize,
                        currentPage: currentPage
                    }
                }
            )
        )

        const systemUserGetAllResponse = await Promise.all(systemUserGetAllRequests);
        systemUserGetAllResponse.forEach(eachSystemUserGetAllResponse => {
            systemUsers.push(...eachSystemUserGetAllResponse.data.data!);
        });

        return systemUsers;
    }

    public async cacheUserAvatars(allUsers: SystemUserDto[]) {


        function createBatchPayload(userIds: SystemUserDto[]) {
            return {
                requests: userIds.map((userId, index) => ({
                    id: userId.azureActiveDirectoryObjectId,
                    url: `/users/${userId.azureActiveDirectoryObjectId}/photo/$value`,
                    method: 'GET',
                    headers: { 'Content-Type': 'image/jpeg' },
                })),
            };
        }

        try {
            const chunks = ArrayToChunk(allUsers.filter(q => q.azureActiveDirectoryObjectId !== '00000000-0000-0000-0000-000000000000'), 20);

            const chunkRequests = chunks.map(q => createBatchPayload(q));

            chunkRequests.forEach(async (eachChunkRequest) => {

                const response = await this.msGraphApi.raw(
                    "POST",
                    `$batch`,
                    eachChunkRequest
                );

                const responseJson = await response.json() as {
                    responses: {
                        id: string,
                        headers: {
                            "Cache-Control": "max-age=21600, private",
                            "Content-Type": "image/jpeg",
                            "ETag": string,
                        }
                        body: string,
                        status: 200 | 404
                    }[]
                }

                const responseJsonBlobs = responseJson.responses.forEach(eachResponses => {
                    if (eachResponses.status === 200)
                        this.objectURLCacheByID.set(eachResponses.id, `data:image/jpeg;base64,${eachResponses.body}`);
                    else
                        this.objectURLCacheByID.set(eachResponses.id, "");
                });

                // })
                // const responseJsonBlobs = responseJson.responses.map(eachResponses => {
                //     return {
                //         ...eachResponses,
                //         blobPromise: b64toBlob(eachResponses.body),
                //         imageURL: "",
                //     }
                // })

                // for (const element of responseJsonBlobs) {
                //     const blob = await element.blobPromise;
                //     const imageURL = URL.createObjectURL(blob);
                //     this.objectURLCacheByID.set(element.id, imageURL);
                // }

            })
        }
        catch {

        }
    }
}