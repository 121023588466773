import {
    DataGridPremiumProps,
    GridColDef,
    GridPaginationModel,
    GridRowsProp,
    GridValidRowModel,
    useGridApiRef,
    useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";
import { StripedDataGrid } from "views/Estimates/EstimateInvestmentSummary/components/StripedDataGrid"

interface IProductItemModalGridProps {
    rows: GridValidRowModel[],
    columns: GridColDef[]
    dataGridProps?: any
    sx?: any
    apiRef?: any
    rowCount?: number
    paginationModel?: GridPaginationModel
    paginationMode?: string
    pageSizeOptions?: number[]
    onPaginationModelChange?:  React.Dispatch<React.SetStateAction<{pageSize: number, page: number}>>
}

export const MuiDataGrid = (props: IProductItemModalGridProps) => {
    const apiRef = props.apiRef? props.apiRef : useGridApiRef();

    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {
        },
    });


    return (
        <StripedDataGrid apiRef={apiRef}
            // treeData
            {...props.dataGridProps}
            sx={{
                minHeight: '100px',
                '.MuiDataGrid-aggregationColumnHeaderLabel': {
                    visibility: 'hidden'
                },
                '& .MuiDataGrid-cell': {
                    fontWeight: "400",
                    lineHeight: "16px",
                    fontSize: "14px",
                    color: '#262626'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: "600",
                    lineHeight: "26px",
                    fontSize: "14px",
                    color: '#262626'
                },
                '& .MuiDataGrid-cellContent': {
                    height: "20px"
                },
                '& .MuiDataGrid-footerCell': {
                    color: '#262626 !important'
                },
                '& .MuiDataGrid-columnHeadersInner': {
                    background: '#F8F8F8'
                },
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: props.dataGridProps?.getDetailPanelContent === undefined ? 'inherit' : 'rgba(0, 0, 0, 0.04) !important'
                },
                ...props.sx
            }}
            // groupingColDef={groupingColDef}
            // getTreeDataPath={getTreeDataPath}
            initialState={initialState}
            rows={props.rows} columns={props.columns}
            getRowClassName={(params) =>
               params.indexRelativeToCurrentPage % 2 === 1 && props.dataGridProps?.getDetailPanelContent === undefined ? 'even' : 'odd'
            }
            hideFooter={!props.paginationModel}
            rowCount={props.rowCount}
            paginationModel={props.paginationModel}
            paginationMode={props.paginationMode}
            onPaginationModelChange={props.onPaginationModelChange}
            pageSizeOptions={props.pageSizeOptions}
                         pagination={!!props.paginationModel}
        />
    )
}
