import { InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { Backdrop, LinearProgress } from "@mui/material";
import { ArrayToLookupByKey, filterArrayUnique } from "TSFunctions";
import { EApplicationInsightsEvents } from "components/AppInsights";
import { ESignOutScope } from "components/Authentication/ESignOut";
import { IAuthentication } from "components/Authentication/IAuthentication";
import { IIdentity } from "components/Authentication/IIdentity";
import { ErrorPage } from "components/Error/ErrorPage";
import ErrorDialog, { ITrace, ITraceSeverity } from "components/ErrorDialog";
import { addDocumentExceptionEventListener, dispatchDocumentExceptionEvent } from "components/EventSystem";
import * as ISwaggerAPI from "data/swagger/API";
import { PaginationOptionsModel } from "data/swagger/API";
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useSessionStorage } from "react-use";
import { StatusInitialState, StatusReducer } from "store/reducers/StatusReducer";
import { v4 as uuidv4 } from 'uuid';
import Login from "views/Login";
import { Config, applicationInsights } from "../../config";
import { DataSource } from "../../data/DataSource";
import { AzureDirectoryTokenProvider } from "../../data/core/AzureDirectoryTokenProvider";
import { ApplicationContextInitialState } from "./ApplicationContextInitialState";
import { IApplicationContext, IOptionsRecord } from "./IApplicationContext.type";
import { IApplicationContextProps } from "./IApplicationContextProps.type";


const isAzureActiveDirectoryError = (error: any) => {
    return error?.message?.includes("invalid_grant") ||
        error?.message?.includes("access_denied") ||
        error?.message?.includes("interaction_in_progress") ||
        error?.message?.includes("no_account_error");
}

export const ApplicationContext = createContext(ApplicationContextInitialState());

export interface ISessionState {
    cacheReady: boolean;
    dataSourceReady: boolean;
    identityReady: boolean;
    canLoad: boolean;
    userListReady: boolean;
}

export function useApplication() {
    return useContext(ApplicationContext);
}

interface IPracticeCache {
    practices: ISwaggerAPI.PracticeDto[],
    practiceRoles: ISwaggerAPI.PracticeRoleDto[],
    practiceLeaders: ISwaggerAPI.PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModel[],
    practiceEstimators: ISwaggerAPI.PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModel[],
}


const ApplicationContextProvider = (props: IApplicationContextProps) => {

    const [options, setOptions] = useSessionStorage<IOptionsRecord | null>(
        Config.webApp.storageKeys.options, null
    );

    const [practices, setPractices] = useSessionStorage<IPracticeCache | null>(
        Config.webApp.storageKeys.practices, null
    );

    const [systemUsers, setSystemUsers] = useSessionStorage<ISwaggerAPI.SystemUserDto[] | null>(
        Config.webApp.storageKeys.crmUsers, null
    );

    const [systemUsersBySystemUserId, setSystemUsersBySystemUserId] = useState<Record<string, ISwaggerAPI.SystemUserDto>>({});

    const [identity, setIdentity] = useSessionStorage<IIdentity | null>(
        Config.webApp.storageKeys.identity, null
    );


    const [sessionState, setSessionState] = useState<ISessionState>({
        identityReady: false,
        cacheReady: false,
        canLoad: false,
        dataSourceReady: false,
        userListReady: false,
    });

    const [interactionType, setInteractionType] = useState(InteractionType.None);

    const cacheResetCallback = useCallback(() => {
        setOptions(null);
        setPractices(null);
        setIdentity(null);
        setSessionState({
            cacheReady: false,
            identityReady: false,
            canLoad: false,
            dataSourceReady: false,
            userListReady: false,
        });
    }, []);

    const statusReducer = useReducer(StatusReducer, StatusInitialState());

    const { instance, accounts } = useMsal();

    const [error, setError] = useState<string | undefined>(undefined);

    const trackException = useCallback((error: Error) => {

        // appInsights not setup?
        if (!applicationInsights)
            return;

        const telemetry: IExceptionTelemetry = {
            id: uuidv4(),
        };

        if (error instanceof Error) {
            telemetry.exception = error;

            telemetry.properties = {
                ...telemetry.properties,
                id: telemetry.id,
            };
        }
        else {
            const errorEvent = error as ErrorEvent;

            telemetry.exception = errorEvent?.error;

            telemetry.properties = {
                ...telemetry.properties,
                message: errorEvent?.message,
                id: telemetry.id,
            };
        }

        applicationInsights.trackException(telemetry);
        applicationInsights.flush();

        return telemetry;
    }, [applicationInsights]);

    const [errorQueue, setErrorQueue] = useState([] as ITrace[]);

    //globalException handler
    useEffect(() => {
        const unsubscribeTelemetry = addDocumentExceptionEventListener((event) => {
            event.detail.telemetry = trackException(event.detail.error);;
            event.detail.timeStamp = (new Date()).toISOString();
            event.detail.location = location.toString();
            event.detail.description = JSON.parse(
                JSON.stringify(event.detail.error, Object.getOwnPropertyNames(event.detail))
            )
        });

        const unsubscribeUserNotification = addDocumentExceptionEventListener((event) => {
            setErrorQueue(t => {
                const nt = [...t];
                nt.push(event.detail);
                return nt;
            });
        });

        const unsubscribeWindowNotification = (() => {
            const windowErrorHandler = (event: ErrorEvent) => {
                // Before the code manages to finish the service cycle, another one is queued or ignored.
                // ResizeObserver loop completed with undelivered notifications.
                // ResizeObserver loop limit exceeded
                if (event.message.includes('ResizeObserver loop')) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    return;
                }

                dispatchDocumentExceptionEvent({
                    error: event.error
                });
            }

            window.addEventListener('error', windowErrorHandler);

            return () => {
                window.removeEventListener('error', windowErrorHandler);
            }
        })();

        return () => {
            unsubscribeTelemetry();
            unsubscribeUserNotification();
            unsubscribeWindowNotification();
        }
    }, []);

    const dataSource = useMemo(() => {

        const webApiTokenProvider = new AzureDirectoryTokenProvider(
            instance,
            Config.webApi.scopes,
            Config.webApi.baseUrl,
            (error: any) => {
                // if (error.toString().indexOf('no_account_error:') > -1)
                //     setAuthError("Login failed.");
                // else
                //     setAuthError(error.toString());
            }
        );

        const xrmApiTokenProvider = new AzureDirectoryTokenProvider(
            instance,
            Config.dynamics.scopes,
            Config.dynamics.baseUrl,
            (error: any) => {
                // debugger
                // if (error.toString().indexOf('no_account_error:') > -1)
                //     setAuthError("Login failed.");
                // else
                //     setAuthError(error.toString());
            }
        );

        const msGraphApiTokenProvider = new AzureDirectoryTokenProvider(
            instance,
            Config.msGraph.scopes,
            Config.msGraph.baseUrl,
            (error: any) => {
                // setAuthError(error.toString());
            }
        );

        const dataSource = new DataSource({
            webApiTokenProvider,
            msGraphApiTokenProvider,
            xrmApiTokenProvider
        });

        return dataSource;

    }, [instance, accounts]);

    const loadSessionAsync = useCallback(async () => {
        // trying a silent login first to prevent data layer recovery for allowing redirect option
        try {
            setError(undefined);

            applicationInsights.startTrackEvent(EApplicationInsightsEvents.SessionLoading);

            await instance.handleRedirectPromise();

            if (!instance.getActiveAccount()) {
                const accounts = instance.getAllAccounts();
                if (accounts.length > 0)
                    instance.setActiveAccount(accounts[0]);
            }

            const account = instance.getActiveAccount();

            if (!account)
                throw new Error("no_account_error:");

            const request = {
                account: account,
                scopes: Config.webApi.scopes
            };

            const authenticationResult = await instance.acquireTokenSilent(request);
            const WhoAmI = await dataSource.webApi.swagger.systemUser.systemUserGetCurrentUserInformation();

            setSessionState(xReady => { return { ...xReady, dataSourceReady: true } });

            applicationInsights.setAuthenticatedUserContext(WhoAmI.data.data?.azureActiveDirectoryObjectId!, undefined, true);
            applicationInsights.trackEvent({
                name: EApplicationInsightsEvents.WhoAmI,
                properties: {
                    ...WhoAmI.data.data
                }
            });
            console.log("whoami", WhoAmI.data.data);

            applicationInsights.flush();

            // TODO move these out
            const roles = WhoAmI.data?.data?.systemuserroles_association?.map((t: any) => { return { roleId: t.systemUserRoleId!, roleName: t.roleName! } }) ?? [];
            const teams = WhoAmI.data?.data?.teammembership_association?.map((t: any) => { return { teamId: t.teamId!, teamName: t.teamName! } }) ?? [];

            const userPracticesLeaderPractices = WhoAmI.data?.data?.hsl_practiceleaders || []
            const userPracticesEstimatorPractices = WhoAmI.data?.data?.hsl_practiceestimators || []


            setIdentity({
                ...identity,
                activeDirectory: {
                    account: authenticationResult.account,
                    azureActiveDirectoryObjectId: WhoAmI.data!.data!.azureActiveDirectoryObjectId!,
                },
                xrm: {
                    systemuser: WhoAmI.data?.data!,
                    roles: roles,
                    teams: teams,
                    practicesLeaderPractices: userPracticesLeaderPractices || [],
                    practicesEstimatorPractices: userPracticesEstimatorPractices || [],
                    usersPractices: (userPracticesEstimatorPractices && userPracticesLeaderPractices) ? filterArrayUnique([...userPracticesLeaderPractices, ...userPracticesEstimatorPractices], "id") : [],
                    isAdmin: WhoAmI.data?.data?.isAdmin,
                    isSales: WhoAmI.data?.data?.isSales,
                    isSolutionPrincipal: WhoAmI.data?.data?.isSolutionPrincipal,
                    isPracticeLeader: WhoAmI.data?.data?.isPracticeLeader,
                    isPracticeEstimator: WhoAmI.data?.data?.isPracticeEstimator,
                    isReadOnly: !(WhoAmI.data?.data?.isAdmin || WhoAmI.data?.data?.isSales || WhoAmI.data?.data?.isSolutionPrincipal || WhoAmI.data?.data?.isPracticeLeader || WhoAmI.data?.data?.isPracticeEstimator)
                }
            });

            setSessionState(xReady => { return { ...xReady, identityReady: true } });
            // TODO rest mechanics on sign-out

            const optionsAsync = dataSource.webApi.swagger.optionSet.getAll({})
            //todo: figure out how we want to handle this limited page size
            const paginationCriteria: PaginationOptionsModel = {
                "currentPage": 1,
                "pageSize": 1000,
                "isSortAscending": true,
            }

            const practicesFetchAsync = practices === null
                ? {
                    practices: dataSource.webApi.swagger.practice.practiceGetAll({ data: { ...paginationCriteria, pageSize: 100 } }),
                    practiceRoles: dataSource.webApi.swagger.practiceRole.practiceRoleGetAllWithoutPagination(),
                    practiceLeaders: dataSource.webApi.swagger.practiceLeader.practiceLeaderGetAll({ data: paginationCriteria }),
                    practiceEstimators: dataSource.webApi.swagger.practiceEstimator.practiceEstimatorGetAll({ data: paginationCriteria }),
                }
                : null;

            if (optionsAsync) {
                const options = await optionsAsync;
                const optionLookup: IOptionsRecord = {}
                options.data?.data?.map((option) => {
                    if (option?.name)
                        optionLookup[option.name] = option?.options || []
                })

                setOptions(optionLookup);
            }

            if (practicesFetchAsync) {
                setPractices({
                    practices: (await practicesFetchAsync.practices).data?.data || [],
                    practiceRoles: (await practicesFetchAsync.practiceRoles).data?.data || [],
                    practiceLeaders: (await practicesFetchAsync.practiceLeaders).data?.data ?? [],
                    practiceEstimators: (await practicesFetchAsync.practiceEstimators).data?.data ?? [],
                });

            }
            setSessionState(xReady => { return { ...xReady, cacheReady: true } });


            setSessionState(xReady => { return { ...xReady, canLoad: true } });

            applicationInsights.stopTrackEvent(EApplicationInsightsEvents.SessionLoading);

            // CommentsChatBox requires full list of users, ask Christian to refactor/cleanup code, we are dropping full list of system users loading
            const _systemUsers = await dataSource.getDynamicsUsers();
            const _systemUsersBySystemUserId = ArrayToLookupByKey(_systemUsers, 'id');

            setSystemUsers(_systemUsers);
            setSystemUsersBySystemUserId(_systemUsersBySystemUserId);
            setSessionState(xReady => { return { ...xReady, userListReady: true } });

            console.log("Context is ready.", context)
        } catch (error) {
            console.error(error);

            setError("Unable to confirm authentication" + error);

            if (!isAzureActiveDirectoryError(error))
                dispatchDocumentExceptionEvent({
                    error: error,
                })

        } finally {


        }
    }, []);

    useEffect(() => {
        loadSessionAsync();
    }, []);

    // WhoAmI/Permissions/Teams/Roles
    // Backend pinging
    // loading optionset cache
    // loading webapi config
    const signOut = (scope?: ESignOutScope) => {
        setIdentity(null);

        if (scope === ESignOutScope.EApplicationWithMSAL)
            instance.logout();
    };

    const context: IApplicationContext = {
        // TODO drop ready
        ready: sessionState.cacheReady && sessionState.identityReady,
        dataSource: dataSource,
        statusReducer: statusReducer,
        identity: identity,
        sessionState: sessionState,
        cache: {
            options: options!,
            practices: practices?.practices ?? [],
            practiceRoles: practices?.practiceRoles ?? [],
            practiceLeaders: practices?.practiceLeaders ?? [],
            practiceEstimators: practices?.practiceEstimators ?? [],
            crmUsers: systemUsers ?? [],
            systemUsersBySystemUserId: systemUsersBySystemUserId ?? {},
            reset: cacheResetCallback,
        }
    };

    const onRenderUnauthenticated = () => {
        return <Login
            processing={false}
            error={error}
            onSignIn={async () => {
                setInteractionType(InteractionType.Redirect);
                const request = {
                    scopes: Config.webApi.scopes
                };
                instance.loginRedirect(request);
            }}
        />
    }

    const onRenderContextLoading = () => {
        if (!sessionState.canLoad)
            return <>
                <LinearProgress />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                </Backdrop>
            </>
    }

    // TODO redo this
    const fatalError = errorQueue.length > 0 && errorQueue.find(t => t.severity === ITraceSeverity.Fatal);
    if (fatalError)
        return <ErrorPage error={fatalError} />

    return (
        <ApplicationContext.Provider value={context}>
            {/* <Alert severity="error">
                This is an error alert — <strong>check it out!</strong>
            </Alert> */}
            <ErrorDialog
                isOpen={errorQueue.length > 0}
                onClose={(_) => {
                    setErrorQueue(t => t.slice(1));
                }}
                trace={errorQueue?.[0]}
            />
            <UnauthenticatedTemplate>
                {onRenderUnauthenticated()}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate >
                {onRenderContextLoading()}
                {props.children}
            </AuthenticatedTemplate>
        </ApplicationContext.Provider >
    );
}
export default ApplicationContextProvider;
