import { Autocomplete, Box, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ActionButton from 'components/ActionButton';
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import { EntityReferenceDto, HslSalesestimatepracticeassignmentStatuscode } from 'data/swagger/API';
import { filterArrayUnique } from 'TSFunctions';
import { ITeam } from 'models/ITeam';
import useSalesEstimate from 'views/Estimates/EstimatesHooks/useSalesEstimate';


const styleModalPopup = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    minWidth: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface IModalEditEstimationTeamOwnerProps {
    defaultValue?: ITeam,
    onClose: () => void,
    isOpen: boolean,
    onSubmit: (leader: IPracticeUser, status: HslSalesestimatepracticeassignmentStatuscode) => void,
    practiceId: string
}

export interface UserOptions {
    name: string,
    id: string,
    email: string
}
export interface IPracticeUser {
    name: string,
    practiceId: string
    userId: string
}


const ModalEditEstimationTeamOwner: React.FunctionComponent<IModalEditEstimationTeamOwnerProps> = (props) => {

    const application = useApplication();
    const salesEstimateReducer = useSalesEstimate()
    const { stateEstimate, isAPILoading, error } = salesEstimateReducer

    const blankUser = useCallback(() => {
        const user: UserOptions = {
            name: "",
            id: "",
            email: "",
        };
        return user;
    }, []);

    const userOptions = useMemo(() => {
        const leaders = application.cache.practiceLeaders.filter((leader) => leader.entity2?.id === props.practiceId)
        const estimators = application.cache.practiceEstimators.filter((estimator) => estimator.entity2?.id === props.practiceId)
        const users = filterArrayUnique([...leaders, ...estimators], 'entity1', ((v: any) => v.entity1?.id))

        return users.map((user) => {
            return {
                name: user.entity1?.fullName || "",
                id: user.entity1?.id || "",
                email: user.entity1?.internalEMailAddress || "",
            }
        })
    }, [props.practiceId, application.cache.practiceEstimators, application.cache.practiceLeaders])

    const [selectedStatus, setSelectedStatus] = useState<HslSalesestimatepracticeassignmentStatuscode>(props.defaultValue?.statuscode || HslSalesestimatepracticeassignmentStatuscode?.Option_864630000);
    const [selectedUser, setSelectedUser] = useState<UserOptions>(blankUser());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props?.defaultValue?.ownerId && setSelectedUser({
            name: props.defaultValue?.ownerId.name || '',
            id: props.defaultValue?.ownerId.id || '',
            email: ''
        })
        props?.defaultValue?.statuscode && setSelectedStatus(props?.defaultValue?.statuscode)

    }, [props.defaultValue, props.isOpen])

    const handleClose = () => {
        setSelectedUser(blankUser())
        setSelectedStatus(HslSalesestimatepracticeassignmentStatuscode?.Option_864630000)
        props.onClose?.();
    }

    const onRenderMain = () => {
        return (
            <Box>
                <Typography style={{ paddingBottom: '10px' }} variant="h2">{`Update Owner`}</Typography>
                <Divider />
                <div
                    style={{

                        marginTop: '25px',
                        marginBottom: '40px',

                    }}
                >
                    <Autocomplete
                        value={selectedUser}
                        onChange={(event, value) => {
                            if (value) {
                                setSelectedUser(value)
                            }
                        }}
                        sx={{ width: "100%" }}
                        size='small'
                        options={userOptions}
                        getOptionLabel={option => option.name ?? ""}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                label={`Select Owner`}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {/* {loading ? <CircularProgress color="inherit" size={20} /> : null} */}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                    style: { height: 41 }
                                }}
                            />
                        }
                        isOptionEqualToValue={(option, value) => !value.id
                            ? false
                            : option.id === value.id
                        }
                    />
                    <FormControl sx={{ marginTop: '30px' }} fullWidth>
                        <InputLabel id="statusSelect">Status</InputLabel>
                        <Select
                            style={{ height: 37 }}
                            labelId="statusSelect"
                            fullWidth
                            size='small'
                            value={selectedStatus}
                            onChange={(event, child) => {
                                if (event.target.value) {
                                    setSelectedStatus(Number(event.target.value))
                                }
                            }}
                        >
                            {
                                application?.cache?.options?.hsl_salesestimatepracticeassignment_statuscode.map((type) => (
                                    <MenuItem key={type.value} value={type.value?.toString()}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div>
                {onRenderFooter()}
            </Box>
        );
    };

    const disabled = selectedUser?.id === ''

    const onRenderFooter = () => {
        return (
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: '30px', gap: '10px'
            }
            }>
                <ActionButton onClick={handleClose} label='Cancel' />
                {/* <ActionButton
                    primary
                    label='Save and Create New'
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => {
                        props.onSubmit?.(practiceLeader);
                    }}
                    disabled={disabled}
                /> */}
                <ActionButton
                    primary
                    label='Save and Close'
                    sx={{ float: 'right' }}
                    onClick={() => {
                        props.onSubmit?.({
                            name: selectedUser.name,
                            practiceId: props.practiceId,
                            userId: selectedUser.id
                        },
                            selectedStatus);
                        setSelectedUser(blankUser())
                        props.onClose?.();
                    }}
                    disabled={disabled}
                />
            </div >
        );
    };

    return (
        <Modal
            open={props.isOpen}
            onClose={handleClose}
        >
            <Box sx={styleModalPopup}>{onRenderMain()}</Box>
        </Modal>
    );
};

export default ModalEditEstimationTeamOwner;