import { Box, Button, Tab, Tabs, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stepper from 'components/Stepper';
import { IStep } from 'components/Stepper/IStep';
import React, { useEffect, useMemo, useState } from 'react';
import Output from '../EstimateOutputs';
import Overview from './Overview';
import { EStepStatus } from 'components/Stepper/EStepStatus';
import { unstable_usePrompt, useOutletContext } from 'react-router-dom';
import { EEstimateActionType, TEstimateStore } from 'store/reducers/EstimateReducer';
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import useSalesEstimate from '../EstimatesHooks/useSalesEstimate';
import { LoadingBackdrop } from 'views/Templates/Template/components/LoadingBackdrop';
import { HslSalesestimateStatecode, HslSalesestimateStatuscode } from 'data/swagger/API';


interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface IEstimateDetailProps {
}

const EstimateDetail: React.FC<IEstimateDetailProps> = (props) => {
	const [tabIX, setTabIX] = useState(0);
	const application = useApplication();
	const salesEstimateReducer = useSalesEstimate()
	const { stateEstimate, dispatchEstimate, isAPILoading, error } = salesEstimateReducer
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const canMarkDealSigned = (application.identity?.xrm.isAdmin || application.identity?.xrm.isSolutionPrincipal || application.identity?.xrm.isSales)
		&& stateEstimate.estimate.statuscode === HslSalesestimateStatuscode.Option_864630004
	const canEditOverview = (application.identity?.xrm.isSales || application.identity?.xrm.isSolutionPrincipal || application.identity?.xrm.isAdmin) && !stateEstimate.isReadOnly

	//https://github.com/remix-run/react-router/issues/8139
	unstable_usePrompt({
		when: isDirty,
		message: "Are you sure you want to leave without saving?"
	})
	const onRenderToolbar = () => {
		return <Toolbar sx={{ justifyContent: 'end', gap: '10px' }}>
			{/* <Typography variant="caption" sx={{ marginRight: '20px' }}>
				All changes saved
			</Typography> */}


			<Button
				variant="contained"
				color="primary"
				sx={{ minWidth: '120px' }}
				disabled={!canEditOverview}
				onClick={async (_) => {
					setIsLoading(true)
					try {
						const updateRequest = {
							...stateEstimate.estimate,
							id: stateEstimate.estimate.id,
							hsl_directorofbusinessdevelopmentid: stateEstimate.estimate.hsl_directorofbusinessdevelopmentid,
							hsl_clientservicesprincipleid: stateEstimate.estimate.hsl_clientservicesprincipleid,
							hsl_estimatedue: stateEstimate.estimate.hsl_estimatedue,
							hsl_contractdue: stateEstimate.estimate.hsl_contractdue,
							hsl_CustomerUserCount: stateEstimate.estimate.hsl_CustomerUserCount,
							hsl_qualifiedbudget: { value: Math.round((stateEstimate.estimate.hsl_qualifiedbudget?.value || 0) * 100) / 100 },
							ownerId: stateEstimate.estimate.ownerId,
							hsl_synopsis: stateEstimate.estimate.hsl_Synopsis,
						}
						await application.dataSource.webApi.swagger.salesEstimate.salesEstimateUpdate(updateRequest);
						const estimateLoad = await application.dataSource.webApi.swagger.salesEstimate.salesEstimateGetByIdWithRelatedEntities({ id: stateEstimate.estimate.id! });
						dispatchEstimate({
							type: EEstimateActionType.load,
							estimate: { ...estimateLoad.data.data },
						});
						setIsDirty(false)
					} finally {
						setIsLoading(false)
					}
				}}
			>
				Save
			</Button>
			{canMarkDealSigned && <Button
				variant="contained"
				color="primary"
				sx={{
					minWidth: '120px',

				}}
				onClick={async (_) => {
					setIsLoading(true)
					try {
						const updateRequest = {
							...stateEstimate.estimate,
							statecode: HslSalesestimateStatecode.Option_1,
							statuscode: HslSalesestimateStatuscode.Option_864630002
						}
						await application.dataSource.webApi.swagger.salesEstimate.salesEstimateUpdate(updateRequest);
						const estimateLoad = await application.dataSource.webApi.swagger.salesEstimate.salesEstimateGetByIdWithRelatedEntities({ id: stateEstimate.estimate.id! });
						dispatchEstimate({
							type: EEstimateActionType.load,
							estimate: { ...estimateLoad.data.data },
						});
						setIsDirty(false)
					} finally {
						setIsLoading(false)
					}
				}}
			>
				Mark Deal Signed
			</Button>
			}
		</Toolbar>;
	}

	const steps = useMemo(() => {
		const steps: IStep[] = [
			{ label: 'In Progress', id: '864630000', status: EStepStatus.active },
			{ label: 'Approved By Practices', id: '864630004', status: EStepStatus.disabled },
			{ label: "Deal Signed", id: '864630002', status: EStepStatus.disabled },
		];
		steps.map((step, ix) => {
			step.id === stateEstimate.estimate.statuscode?.toString() ?
				steps[ix].status = EStepStatus.active
				: steps[ix].status = EStepStatus.disabled
		})
		return steps;
	}, [stateEstimate.estimate.statuscode]);



	const onRenderMain = () => {

		return <div key="0" style={{ margin: '10px' }}>
			<LoadingBackdrop isOpen={isLoading} />

			<div key="5" style={{ width: '100%', display: 'flex', padding: '15px' }}>
				<div style={{ marginLeft: 'auto', marginRight: 'auto' }} >
					<Stepper steps={steps} />
				</div>
			</div>

			{onRenderToolbar()}
			{
				tabIX === 0 && <Overview setIsDirty={setIsDirty} {...props} key="overview" />
			}
			{
				tabIX === 1 && <Output key="output"{...props} />
			}

		</div>;

	}
	return <>
		{onRenderMain()}
	</>;
}

export default EstimateDetail;
