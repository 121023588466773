import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails, Theme, SxProps } from "@mui/material";
import { useState } from "react";

export const MuiAccordion = (props: { sx?: SxProps<Theme>, titleComponent: any, summaryComponent: any }) => {
    const [expand, setExpand] = useState(true);
    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    return (
        <Accordion sx={{ borderRadius: '4px', ...props.sx }} expanded={expand}>
            <AccordionSummary expandIcon={<ExpandMore onClick={toggleAccordion} />}>
                {props.titleComponent}
            </AccordionSummary>
            <AccordionDetails>
                {props.summaryComponent}
            </AccordionDetails>
        </Accordion >
    )
}