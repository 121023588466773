import { Box } from '@mui/material';
import React from 'react';
import * as ISwaggerAPI from "data/swagger/API";
import EstimationTeam from './EstimationTeam';
import ProjectLinks from './ModalAddLink/ProjectLinks';
import Summary from './Summary';
import useSalesEstimate, { EEstimateAPIActionType } from 'views/Estimates/EstimatesHooks/useSalesEstimate';
import { LoadingBackdrop } from 'views/Templates/Template/components/LoadingBackdrop';


interface IOverviewProps {
	setIsDirty: (isDirty: boolean) => void
}

const Overview: React.FC<IOverviewProps> = (props) => {

	const salesEstimateReducer = useSalesEstimate()
	const { stateEstimate, isAPILoading, error, dispatchAPIEstimate } = salesEstimateReducer
	return (
		<>
			<LoadingBackdrop isOpen={isAPILoading} />
			<Summary setIsDirty={props.setIsDirty} />
			<EstimationTeam estimateReducer={salesEstimateReducer} />
			{/* inject data to addlink and get data from addlink (Similar to PhaseSettings, but with add/delete) */}
			<Box mt={4}>{/* Project Links*/}
				<ProjectLinks
					hsl_salesestimateid={stateEstimate?.estimate?.id!}
					projectLinks={stateEstimate?.estimate?.hsl_hsl_links_SalesEstimate_hsl_salesestimate ?? []}
					onChange={(projectLinks: ISwaggerAPI.LinkDto[]) => {
						dispatchAPIEstimate({
							type: EEstimateAPIActionType.estimate_links_change,
							links: projectLinks
						})
					}}
				/>
			</Box>
		</>
	);
};

export default Overview;