
// Event System
// ---------------------------------------------------------
// This is a react anti-pattern, but really good fit for our model due to efficiency
// very simple event system,  and there are really good libraries out there that support wrapping events around command pattern

import { ITrace } from "components/ErrorDialog";

// but for now this is works, and its pretty simple
interface IDocumentEventDelegate<T> {
    eventType: string;
    detail: T,
    handler: (event: CustomEvent<T>) => void;
}

export interface IDocumentExceptionEvent extends IDocumentEventDelegate<ITrace> {
    eventType: 'DocumentException',
}

export interface ITestEvent extends IDocumentEventDelegate<unknown | null> {
    eventType: 'TestEvent',
}

export type TDocumentEventTypes = ITestEvent | IDocumentExceptionEvent;

function dispatchDocumentEvent(customEvent: Pick<TDocumentEventTypes, 'eventType' | 'detail'>) {
    const event = new CustomEvent<any>(
        customEvent.eventType,
        {
            detail: customEvent.detail,
            // cancelable: true,
        }
    );

    // lets create a new one is payload is not providing one
    document.dispatchEvent(event);
}

/**
 * 
 * @param arg 
 * @returns unsubscribing method, that upon invocation will unregister the handler
 */
function addDocumentEventListener(arg: Pick<TDocumentEventTypes, 'handler' | 'eventType'>) {
    const handler = arg.handler as (event: Event) => void;

    document.addEventListener(arg.eventType, handler);
    return () => {
        document.removeEventListener(arg.eventType, handler);
    }
}


export function addDocumentExceptionEventListener(callback: (detail: CustomEvent<ITrace>) => void) {
    return addDocumentEventListener({
        eventType: "DocumentException",
        handler: callback
    })
}

export function dispatchDocumentExceptionEvent(detail: ITrace) {
    dispatchDocumentEvent({ eventType: 'DocumentException', detail: detail })
}



