//@ts-nocheck
import { delay } from 'q';
import { IEstimateTemplate } from '../models/API/IEstimateTemplate.type';
import { IPractice } from '../models/API/IPractice.type';
import { IResourcePlan } from 'models/IResourcePlan';
import { IEstimate } from 'models/IEstimate';
import { v4 as uuidv4 } from 'uuid';
import { ResourcePlanDto } from 'data/swagger/API';

const practiceData: IPractice[] =
  [
    {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      practiceName: 'Dev Tech',
      practiceEstimators: [
        {
          practiceName: 'Example Data',
          userName: 'Juanita Banana',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
      ],
      practiceLeaders: [
        {
          practiceName: 'Example Data',
          userName: 'Juanita Banana',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          userId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        },
      ],
      practiceRoles: [
        {
          offshoreEquivalentPracticeRoleName: 'CE Developer (Dev Tech - CE)',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          listPrice: 0,
          isAssociate: true,
          isArchitect: false,
          isDeveloper: true,
          isOffshore: true,
          name: 'CE Developer (Dev Tech - CE)',
          includeInBlueprint: true,
          preferredClientPrice: 0,
          cost: 0,
          offshoreEquivalentPracticeRoleId:
            '3fa85f64-5717-4562-b3fc-2c963f66afa6'
        },
        {
          offshoreEquivalentPracticeRoleName: 'CE Solutions Architect (Dev Tech - CE)',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          listPrice: 0,
          isAssociate: true,
          isArchitect: true,
          isDeveloper: true,
          isOffshore: false,
          name: 'CE Solutions Architect (Dev Tech - CE)',
          includeInBlueprint: true,
          preferredClientPrice: 0,
          cost: 0,
          offshoreEquivalentPracticeRoleId: null
        },
        {
          offshoreEquivalentPracticeRoleName: 'CE Consultant (Dev Tech - CE)',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          listPrice: 0,
          isAssociate: false,
          isArchitect: false,
          isDeveloper: true,
          isOffshore: true,
          name: 'CE Consultant (Dev Tech - CE)',
          includeInBlueprint: true,
          preferredClientPrice: 0,
          cost: 0,
          offshoreEquivalentPracticeRoleId: null
        },
        {
          offshoreEquivalentPracticeRoleName: 'CE Technical Architect (Dev Tech - CE)',
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          listPrice: 0,
          isAssociate: false,
          isArchitect: true,
          isDeveloper: false,
          isOffshore: false,
          name: 'CE Technical Architect (Dev Tech - CE)',
          includeInBlueprint: true,
          preferredClientPrice: 0,
          cost: 0,
          offshoreEquivalentPracticeRoleId: null
        },
      ],
      standardItems: [
        {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          practiceName: 'string',
          standardItemDescription: 'description',
          standardItemId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          standardItemName: 'string',
          standardItemType: 'string',
        },
      ],
    },
    {
      id: '5322f593-b4e3-ed11-a7c7-0022482a466b',
      practiceName: 'DevTech#TheCoolestPracticeEver',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: 'e9b85ad4-87d8-ed11-a7c7-0022482a466b',
      practiceName: 'TestPractice1',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: '36c443ee-87d8-ed11-a7c7-0022482a4dc2',
      practiceName: 'TestPractice2',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: 'bb772e06-88d8-ed11-a7c7-0022482a4dc2',
      practiceName: 'TestPractice3',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: '45b5551a-88d8-ed11-a7c7-00224827b4bb',
      practiceName: 'TestPractice4',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: '35b5551a-98d8-ed11-a7c7-00224827b4bz',
      practiceName: 'TestPractice5',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: '35b5551a-88d8-ed11-a7c7-10224827b4bc',
      practiceName: 'TestPractice6',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
    {
      id: '35b5551a-78d8-ed11-a7c7-00224827b4bd',
      practiceName: 'TestPractice7',
      practiceEstimators: null,
      practiceLeaders: null,
      practiceRoles: null,
      standardItems: null,
    },
  ];

const templateData: IEstimateTemplate[] = [
  {
    description: 'Basic ALM Set-Up',
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    lineItem: [
      {
        complexity: 864630000,
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        lineItem: [
          {
            id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateTemplateId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            standardItemId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            name: 'string',
            type: 864630000,
            complexity: 864630000,
            complexityModifier: 0,
            description: 'string',
            totalHours: 0,
            isExcluded: true,
            assumptions: [
              {
                id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                developmentLineItemId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                description: 'string',
              },
            ],
            effort: [
              {
                devLineItemId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                hours: 0,
                id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                name: 'string',
                practiceRoleId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
              },
            ],
          },
        ],
        name: 'string',
        salesEstimateTemplateId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        totalHours: 0,
        type: 864630000,
      },
    ],
    name: 'string',
    practice: [
      {
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceName: 'string',
        salesEstimateTemplateId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    ],
    modifiedOn: '2023-05-22T16:23:55.585Z',
  },
  {
    description: 'Consumer Service Configuration',
    id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
    lineItem: [
      {
        complexity: 864630000,
        id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
        lineItem: [
          {
            id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateTemplateId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
            standardItemId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
            name: 'string',
            type: 864630000,
            complexity: 864630000,
            complexityModifier: 0,
            description: 'string',
            totalHours: 0,
            isExcluded: true,
            assumptions: [
              {
                id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
                developmentLineItemId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
                description: 'string',
              },
            ],
            effort: [
              {
                devLineItemId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
                hours: 0,
                id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
                name: 'string',
                practiceRoleId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
              },
            ],
          },
        ],
        name: 'string',
        salesEstimateTemplateId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
        totalHours: 0,
        type: 864630000,
      },
    ],
    name: 'string',
    practice: [
      {
        id: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceName: 'string',
        salesEstimateTemplateId: '4fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    ],
    modifiedOn: '2023-05-22T16:23:55.585Z',
  },
  {
    description: 'Dual-Write Configuration',
    id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
    lineItem: [
      {
        complexity: 864630000,
        id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
        lineItem: [
          {
            id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateTemplateId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
            standardItemId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
            name: 'string',
            type: 864630000,
            complexity: 864630000,
            complexityModifier: 0,
            description: 'string',
            totalHours: 0,
            isExcluded: true,
            assumptions: [
              {
                id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
                developmentLineItemId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
                description: 'string',
              },
            ],
            effort: [
              {
                devLineItemId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
                hours: 0,
                id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
                name: 'string',
                practiceRoleId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
              },
            ],
          },
        ],
        name: 'string',
        salesEstimateTemplateId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
        totalHours: 0,
        type: 864630000,
      },
    ],
    name: 'string',
    practice: [
      {
        id: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceName: 'string',
        salesEstimateTemplateId: '5fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    ],
    modifiedOn: '2023-05-22T16:23:55.585Z',
  },
  {
    description: 'Portal Configuration',
    id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
    lineItem: [
      {
        complexity: 864630000,
        id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
        lineItem: [
          {
            id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
            salesEstimateTemplateId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
            standardItemId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
            name: 'string',
            type: 864630000,
            complexity: 864630000,
            complexityModifier: 0,
            description: 'string',
            totalHours: 0,
            isExcluded: true,
            assumptions: [
              {
                id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
                developmentLineItemId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
                description: 'string',
              },
            ],
            effort: [
              {
                devLineItemId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
                hours: 0,
                id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
                name: 'string',
                practiceRoleId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
              },
            ],
          },
        ],
        name: 'string',
        salesEstimateTemplateId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        totalHours: 0,
        type: 864630000,
      },
    ],
    name: 'string',
    practice: [
      {
        id: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
        practiceName: 'string',
        salesEstimateTemplateId: '6fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    ],
    modifiedOn: '2023-05-22T16:23:55.585Z',
  },
];


const PSAssumptions = {
  "Stage Activities": [
    {
      activity: "Weekly Internal Meeting",
      duration: "1.00",
      frequency: "2/week",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "Weekly Client Meeting",
      duration: "1.00",
      frequency: "2/week",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "Daily Scrum/Standup",
      duration: "1.00",
      frequency: "2/week",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "End of Sprint Review",
      duration: "1.00",
      frequency: "2/sprint",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "Sprint Retrospective",
      duration: "1.00",
      frequency: "2/sprint",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "Sprint Planning/Deep Dive",
      duration: "1.00",
      frequency: "2/sprint",
      numberOfResources: 6,
      hours: 347
    },
    {
      activity: "Supporting Document Update",
      duration: "1.00",
      frequency: "2/sprint",
      numberOfResources: 6,
      hours: 2429
    }
  ]
}

const AddResourceMock = [
  {
    practiceRoleId: '0000-0000-00000-0',
    initiatePhase: 1,
    blueprintPhase: 1,
    buildPhase: 1,
    validatePhase: 1,
    deployPhase: 1,
    operatePhase: 1,
  },
  {
    practiceRoleId: '0000-0000-00000-0',
    initiatePhase: 1,
    blueprintPhase: 1,
    buildPhase: 1,
    validatePhase: 1,
    deployPhase: 1,
    operatePhase: 1,
  }, {
    practiceRoleId: '0000-0000-00000-0',
    initiatePhase: 1,
    blueprintPhase: 1,
    buildPhase: 1,
    validatePhase: 1,
    deployPhase: 1,
    operatePhase: 1,
  }, {
    practiceRoleId: '0000-0000-00000-0',
    initiatePhase: 1,
    blueprintPhase: 1,
    buildPhase: 1,
    validatePhase: 1,
    deployPhase: 1,
    operatePhase: 1,
  }, {
    practiceRoleId: '0000-0000-00000-0',
    initiatePhase: 1,
    blueprintPhase: 1,
    buildPhase: 1,
    validatePhase: 1,
    deployPhase: 1,
    operatePhase: 1,
  },
];


const resourcePlans: ResourcePlanDto[] = [
  {
    id: '7cb12fc5-b212-4baf-9bdb-fb10ca6e9ae9',
    hsl_name: 'Resource_2023-06-17',
    hsl_hsl_resourceplanresource_resourceplanid_h: [
      {
        id: '7cb12fc5-b212-4baf-9bdb-fb10ca6e9ae9',
        hsl_name: 'Architect',
        hsl_rate: 10,
        hsl_hsl_resourceplanentry_resourceplanresourc: [
          {
            id: "672ff1cb-790c-ee11-8f6e-000d3a1a9e6e",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 2,
            "hsl_week": 3
          },
          {
            "id": "c179f0d7-790c-ee11-8f6e-000d3a1a9e6e",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 1,
            "hsl_week": 2
          },
          {
            "id": "4d52d2d3-790c-ee11-8f6e-00224827b509",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 2,
            "hsl_week": 1
          },
          {
            "id": "4295786-790c-ee11-8f6e-000d3a1a9e6e",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 2,
            "hsl_week": 4
          },

        ]
      },
      {
        id: 'fe4cb3ac-05f9-4eac-bed8-a0d2b0f71e6a',
        hsl_name: 'Developer',
        hsl_rate: 20,
        hsl_hsl_resourceplanentry_resourceplanresourc: [
          {
            "id": "672ff1cb-790c-ee11-8f6e-000d3a1a9e6e",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 2,
            "hsl_week": 2
          },
          {
            "id": "c179f0d7-790c-ee11-8f6e-000d3a1a9e6e",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 1,
            "hsl_week": 3
          },
          {
            "id": "4d52d2d3-790c-ee11-8f6e-00224827b509",
            hsl_hours: 40,
            "hsl_projectphase": 864630000,
            "hsl_sprint": 2,
            "hsl_week": 1
          },

        ]
      }
    ],

  }
];


function getRandomDate(from: Date, to: Date) {
  const fromTime = from.getTime();
  const toTime = to.getTime();
  return new Date(fromTime + Math.random() * (toTime - fromTime));
}
const generateRandomDOB = (): string => {
  const random = getRandomDate(new Date('1950-02-12T01:57:45.271Z'), new Date('2001-02-12T01:57:45.271Z'))
  return random.toISOString();
}



const generateData = (numRows: number): IEstimate[] => {
  const data: IEstimate[] = [];

  for (let i = 1; i <= numRows; i++) {
    let status: number;


    // Name	Value
    // Deal Signed	864630002
    // Abandoned	864630003
    // In Progress	864630000
    // Deal Signed	864630001


    if (i % 4 === 0) {
      status = 864630002;
    } else if (i % 4 === 1) {
      status = 864630003;
    } else if (i % 4 === 2) {
      status = 864630000;
    } else {
      status = 864630001;
    }


    data.push({
      id: uuidv4(),
      hsl_opportunityidname: `RXR Development ${i}`,
      hsl_estimatedue: generateRandomDOB(),
      hsl_contractdue: generateRandomDOB(),
      hsl_qualifiedbudget: i * 1000000,
      statecode: status,
      hsl_initiatepreference: 864630002,
      hsl_blueprintpreference: 864630003,
      hsl_buildpreference: 864630000,
      hsl_validatepreference: 864630000,
      hsl_deploypreference: 864630002,
      hsl_operatepreference: 864630001,
      hsl_initiateweeks: 1,
      hsl_validateweeks: 1,
      hsl_blueprintweeks: 2,
      hsl_deployweeks: 4,
      hsl_sprintduration: 3,
      hsl_operateweeks: 2,
      hsl_links: []
    });
  }
  return data;
};


const esimtates: IEstimate[] = [

]

const Mock = {
  //Todo Armen to get the data from the WebAPI: /Practice/Get
  PracticeGet: async (id: string): Promise<IPractice | undefined> => {
    await delay(Math.random() * 800);
    return practiceData.find((t) => t.id === id);
  },
  //Todo Armen to get the data from the WebAPI: /Practice/GetAll
  PracticeGetAll: async () => {
    await delay(Math.random() * 800);
    return practiceData;
  },
  //Todo Armen to get the data from the WebAPI: /Practice/GetAll
  PSAssumptionsGetAll: async () => {
    await delay(Math.random() * 800);
    return PSAssumptions;
  },
  //Todo Armen to get the data from the WebAPI: /SalesEstimate/GetSalesEstimateTemplate
  TemplateGet: async (id: string) => {
    await delay(Math.random() * 800);
    return templateData.filter((t) => t.id === id);
  },
  //Todo Armen to get the data from the WebAPI: /SalesEstimate/GetAllSalesEstimateTemplates
  TemplateGetAll: async () => {
    await delay(Math.random() * 800);
    return templateData;
  },
  ResourceWizardSample: () => {
    return AddResourceMock;
  },
  GenerateEstimate: async (size?: number) => {
    await delay(Math.random() * 800);
    return esimtates;
  },
  //Todo Armen to get the data from the WebAPI: /Practice/GetAll
  ResourcePlansGetAll: async () => {
    await delay(Math.random() * 800);
    return resourcePlans;
  },
};
export default Mock;



