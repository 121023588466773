
// material-ui
import { Box, Fade, Grow } from '@mui/material';

// ==============================|| TRANSITIONS ||============================== //

interface ITransitionsProps {
    children: any,
    type: 'grow' | 'fade' | 'collapse' | 'slide' | 'zoom',
    position?: 'top-left' | 'top-right' | 'top' | 'bottom-left' | 'bottom-right' | 'bottom'
}

const Transitions = (props: ITransitionsProps) => {
    const { children, position, type, ...others } = props;
    let positionSX = {
        transformOrigin: '0 0 0'
    };

    switch (position) {
        case 'top-right':
        case 'top':
        case 'bottom-left':
        case 'bottom-right':
        case 'bottom':
        case 'top-left':
        default:
            positionSX = {
                transformOrigin: '0 0 0'
            };
            break;
    }

    return (
        <Box>
            {type === 'grow' && (
                <Grow {...others}>
                    <Box sx={positionSX}>{children}</Box>
                </Grow>
            )}
            {type === 'fade' && (
                <Fade
                    {...others}
                    timeout={{
                        appear: 0,
                        enter: 300,
                        exit: 150
                    }}
                >
                    <Box sx={positionSX}>{children}</Box>
                </Fade>
            )}
        </Box>
    );
}

export default Transitions;
