/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AnnotationDto {
  /** @format uuid */
  id?: string;
  objectId?: EntityReferenceDto;
  noteText?: string | null;
  subject?: string | null;
  fileName?: string | null;
  /** @format int32 */
  fileSize?: number | null;
  isDocument?: boolean | null;
  documentBody?: string | null;
  /** @format date-time */
  createdOn?: string | null;
  createdBy?: EntityReferenceDto;
  mimeType?: string | null;
}

export interface AnnotationDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: AnnotationDto;
}

export interface AnnotationDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: AnnotationDto[] | null;
}

export interface ApplyResourcePlanDto {
  /** @format uuid */
  salesEstimateId?: string;
  /** @format uuid */
  resourcePlanId?: string;
}

export interface ApplyResourcePlanDtoApiRequestDto {
  /** @format uuid */
  id?: string;
  data?: ApplyResourcePlanDto;
}

export interface AssumptionDto {
  /** @format uuid */
  id?: string;
  hsl_descriptiontext?: string | null;
  hsl_salesestimatelineitemidid?: EntityReferenceDto;
}

export interface AssumptionDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: AssumptionDto;
}

export interface BugDto {
  /** @format uuid */
  id?: string;
  hsl_title?: string | null;
  hsl_stepstoreproduce?: string | null;
  hsl_type?: HslBugHslType;
}

export interface BugDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: BugDto;
}

export interface BulkUpdateResourceAllocationDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  /** @format int32 */
  hsl_EstimatedHours?: number | null;
  /** @format int32 */
  hsl_Hours?: number | null;
  hsl_Rate?: MoneyDto;
  /** @format int32 */
  hsl_Weeks?: number | null;
  /** @format int32 */
  hsl_DelayedWeeks?: number | null;
  hsl_HoursPreference?: HslProjectconfigurationpreferenceGc;
  hsl_ProjectPhase?: HslProjectphase;
  hsl_PracticeRole?: EntityReferenceDto;
  hsl_SalesEstimate?: EntityReferenceDto;
  hsl_ResourcePlan?: EntityReferenceDto;
  hsl_LinkedSalesEstimate?: EntityReferenceDto;
}

export interface BulkUpdateResourcePlanDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_salesestimateid?: EntityReferenceDto;
  hsl_InvestmentSummary?: EntityReferenceDto;
  /** @format date-time */
  createdOn?: string | null;
  hsl_hsl_resourceplanresource_resourceplanid_h?: BulkUpdateResourcePlanResourceDto[] | null;
}

export interface BulkUpdateResourcePlanEntryDto {
  /** @format uuid */
  id?: string;
  isNew?: boolean;
  hsl_resourceplanresourceid?: EntityReferenceDto;
  /** @format int32 */
  hsl_hours?: number | null;
  /** @format int32 */
  hsl_projectphase?: number | null;
  /** @format int32 */
  hsl_sprint?: number | null;
  /** @format int32 */
  hsl_week?: number | null;
}

export interface BulkUpdateResourcePlanIncludingDeleteDto {
  resourcePlan?: BulkUpdateResourcePlanDto;
  resourcePlanResourceIds?: string[] | null;
  resourcePlanEntryIds?: string[] | null;
}

export interface BulkUpdateResourcePlanIncludingDeleteDtoApiRequestDto {
  /** @format uuid */
  id?: string;
  data?: BulkUpdateResourcePlanIncludingDeleteDto;
}

export interface BulkUpdateResourcePlanResourceDto {
  /** @format uuid */
  id?: string;
  isNew?: boolean;
  hsl_resourceplanid?: EntityReferenceDto;
  hsl_practiceroleid?: EntityReferenceDto;
  hsl_name?: string | null;
  /** @format double */
  hsl_rate?: number | null;
  hsl_InitiateResourceAllocation?: EntityReferenceDto;
  hsl_BlueprintResourceAllocation?: EntityReferenceDto;
  hsl_BuildResourceAllocation?: EntityReferenceDto;
  hsl_ValidateResourceAllocation?: EntityReferenceDto;
  hsl_DeployResourceAllocation?: EntityReferenceDto;
  hsl_OperateResourceAllocation?: EntityReferenceDto;
  hsl_hsl_resourceplanentry_resourceplanresourc?: BulkUpdateResourcePlanEntryDto[] | null;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation?: BulkUpdateResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation?: BulkUpdateResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation?: BulkUpdateResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation?: BulkUpdateResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation?: BulkUpdateResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation?: BulkUpdateResourceAllocationDto;
}

export interface CurrentUserDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  azureActiveDirectoryObjectId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  internalEMailAddress?: string | null;
  fullName?: string | null;
  domainName?: string | null;
  hsl_practiceteam?: HslPractice1;
  systemuserroles_association?: RoleDto[] | null;
  teammembership_association?: TeamDto[] | null;
  hsl_practiceleaders?: PracticeDto[] | null;
  hsl_practiceestimators?: PracticeDto[] | null;
  isAdmin?: boolean;
  isSales?: boolean;
  isSolutionPrincipal?: boolean;
  isPracticeLeader?: boolean;
  isPracticeEstimator?: boolean;
}

export interface CurrentUserDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: CurrentUserDto;
}

export interface EntityReferenceDto {
  /** @format uuid */
  id?: string;
  logicalName?: string | null;
  name?: string | null;
}

export interface FeedbackDto {
  /** @format uuid */
  id?: string;
  hsl_title?: string | null;
  hsl_description?: string | null;
}

export interface FeedbackDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: FeedbackDto;
}

export interface Int32ApiResponseDto {
  /** @format uuid */
  id?: string;
  /** @format int32 */
  data?: number;
}

export interface IntersectEntityModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  entity1Id?: string;
  /** @format uuid */
  entity2Id?: string;
}

export interface InvestmentSummaryDistributionDto {
  /** @format uuid */
  id?: string;
  hsl_Name?: string | null;
  /** @format double */
  hsl_OffshorePercent?: number | null;
  /** @format double */
  hsl_GrossMarginPercent?: number | null;
  /** @format int32 */
  hsl_OffshoreHours?: number | null;
  /** @format int32 */
  hsl_OnshoreHours?: number | null;
  hsl_InvestmentSummary?: EntityReferenceDto;
  hsl_Practice?: EntityReferenceDto;
}

export interface InvestmentSummaryDistributionDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: InvestmentSummaryDistributionDto;
}

export interface InvestmentSummaryDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_Cost?: MoneyDto;
  hsl_Discount?: MoneyDto;
  hsl_Contingency?: MoneyDto;
  hsl_ListPrice?: MoneyDto;
  hsl_ListPriceAverageBillRate?: MoneyDto;
  hsl_OffshoreListPriceAverageBillRate?: MoneyDto;
  hsl_OffshorePriceAverageBillRate?: MoneyDto;
  hsl_OnshoreListPriceAverageBillRate?: MoneyDto;
  hsl_OnshorePriceAverageBillRate?: MoneyDto;
  hsl_Price?: MoneyDto;
  hsl_PriceAverageBillRate?: MoneyDto;
  hsl_PriceVariance?: MoneyDto;
  hsl_Profit?: MoneyDto;
  /** @format double */
  hsl_DiscountPercent?: number | null;
  /** @format double */
  hsl_ContingencyPercent?: number | null;
  /** @format double */
  hsl_GrossMarginPercent?: number | null;
  /** @format double */
  hsl_OffshorePercent?: number | null;
  /** @format double */
  hsl_OnshorePercent?: number | null;
  /** @format double */
  hsl_PriceVariancePercent?: number | null;
  /** @format int32 */
  hsl_OffshoreTotalHours?: number | null;
  /** @format int32 */
  hsl_OnshoreTotalHours?: number | null;
  /** @format int32 */
  hsl_TotalHours?: number | null;
  hsl_SalesEstimate?: EntityReferenceDto;
  hsl_ResourcePlan?: EntityReferenceDto;
  hsl_hsl_investmentsummary_hsl_investmentsummarydistribution_InvestmentSummary?:
    | InvestmentSummaryDistributionDto[]
    | null;
  hsl_hsl_investmentsummary_hsl_investmentsummarypricingbyresource_InvestmentSummary?:
    | InvestmentSummaryPricingByResourceDto[]
    | null;
  hsl_hsl_investmentsummary_hsl_investmentsummarypricingbylineitem_InvestmentSummary?:
    | InvestmentSummaryPricingByLineItemDto[]
    | null;
  hsl_investsummaryprodpricing_InvestSummaryId?: InvestmentSummaryProductPricingDto[] | null;
}

export interface InvestmentSummaryDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: InvestmentSummaryDto;
}

export interface InvestmentSummaryPricingByLineItemDto {
  /** @format uuid */
  id?: string;
  hsl_Name?: string | null;
  hsl_Cost?: MoneyDto;
  hsl_ListPrice?: MoneyDto;
  hsl_Price?: MoneyDto;
  hsl_Profit?: MoneyDto;
  hsl_InvestmentSummary?: EntityReferenceDto;
  hsl_LineItemEffort?: EntityReferenceDto;
  hsl_hsl_salesestimatelineitemeffort_hsl_investmentsummarypricingbylineitem_LineItemEffort?: SalesEstimateLineItemEffortDto;
}

export interface InvestmentSummaryPricingByLineItemDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: InvestmentSummaryPricingByLineItemDto;
}

export interface InvestmentSummaryPricingByResourceDto {
  /** @format uuid */
  id?: string;
  hsl_Name?: string | null;
  /** @format int32 */
  hsl_EstimatedHours?: number | null;
  /** @format int32 */
  hsl_Hours?: number | null;
  /** @format double */
  hsl_HoursVariancePercent?: number | null;
  hsl_Rate?: MoneyDto;
  hsl_PracticeRole?: EntityReferenceDto;
  hsl_ListPrice?: MoneyDto;
  hsl_Cost?: MoneyDto;
  hsl_TotalCost?: MoneyDto;
  hsl_Price?: MoneyDto;
  hsl_Profit?: MoneyDto;
  /** @format double */
  hsl_GrossMarginPercent?: number | null;
  hsl_InvestmentSummary?: EntityReferenceDto;
  hsl_hsl_practicerole_hsl_investmentsummarypricingbyresource_PracticeRole?: PracticeRoleDto;
}

export interface InvestmentSummaryPricingByResourceDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: InvestmentSummaryPricingByResourceDto;
}

export interface InvestmentSummaryProductPricingDto {
  /** @format uuid */
  id?: string;
  investmentSummary?: EntityReferenceDto;
  hsl_ListPrice?: MoneyDto;
  hsl_Price?: MoneyDto;
  /** @format int32 */
  hsl_CustomerUserCount?: number | null;
  hsl_BillingMethodCode?: HslProductbillingmethod;
  hsl_ProductLineItem?: EntityReferenceDto;
  hsl_hsl_salesestimateproductlineitem_hsl_investmentsummaryproductpricing_ProductLineItem?: SalesEstimateProductLineItemDto;
}

export interface InvestmentSummaryProductPricingDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: InvestmentSummaryProductPricingDto;
}

export interface LinkDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_link?: string | null;
  hsl_salesestimate?: EntityReferenceDto;
}

export interface LinkDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: LinkDto;
}

export interface MoneyDto {
  /** @format double */
  value?: number | null;
}

export interface NotificationDto {
  /** @format uuid */
  id?: string;
  subject?: string | null;
  regardingObjectId?: EntityReferenceDto;
  hsl_Message?: string | null;
  statusCode?: HslNotificationStatuscode;
  stateCode?: HslNotificationStatecode;
  /** @format date-time */
  createdOn?: string | null;
  /** @format uuid */
  activityId?: string | null;
  ownerId?: EntityReferenceDto;
}

export interface NotificationDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: NotificationDto;
}

export interface NotificationDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: NotificationDto[] | null;
}

export interface OptionDto {
  name?: string | null;
  /** @format int32 */
  value?: number | null;
}

export interface OptionSetDto {
  name?: string | null;
  entityLogicalName?: string | null;
  displayName?: string | null;
  options?: OptionDto[] | null;
}

export interface OptionSetDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: OptionSetDto[] | null;
}

export interface OrganizationInformationDto {
  /** @format uuid */
  id?: string | null;
  friendlyName?: string | null;
  version?: string | null;
  uniqueName?: string | null;
  url?: string | null;
}

export interface OrganizationInformationDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: OrganizationInformationDto;
}

export interface OwnerIdPaginationDto {
  /** @format uuid */
  ownerId?: string | null;
  paginationOptions?: PaginationOptionsModel;
}

export interface PaginationOptionsModel {
  /**
   * @format int32
   * @default 1
   */
  currentPage?: number;
  /**
   * @format int32
   * @default 1
   */
  pageSize?: number;
  sortBy?: string | null;
  isSortAscending?: boolean;
  filters?: Record<string, string>;
  /** @default "AND" */
  filterOperator?: string | null;
}

export interface PaginationOptionsModelApiRequestDto {
  /** @format uuid */
  id?: string;
  data?: PaginationOptionsModel;
}

export interface PracticeDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_practiceoption?: HslPractice1;
  ownerId?: EntityReferenceDto;
}

export interface PracticeDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeDto;
}

export interface PracticeDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeDto[] | null;
}

export interface PracticeEstimatorDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  systemuserid?: string | null;
  /** @format uuid */
  hsl_practiceid?: string | null;
}

export interface PracticeEstimatorDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeEstimatorDto;
}

export interface PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModel {
  relationship?: PracticeEstimatorDto;
  entity1?: SystemUserDto;
  entity2?: PracticeDto;
}

export interface PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModel[] | null;
}

export interface PracticeIdPaginationDto {
  /** @format uuid */
  practiceId?: string | null;
  paginationOptions?: PaginationOptionsModel;
}

export interface PracticeIdPaginationDtoApiRequestDto {
  /** @format uuid */
  id?: string;
  data?: PracticeIdPaginationDto;
}

export interface PracticeIdsPaginationDto {
  practiceIds?: string[] | null;
  paginationOptions?: PaginationOptionsModel;
}

export interface PracticeLeaderDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  systemuserid?: string | null;
  /** @format uuid */
  hsl_practiceid?: string | null;
}

export interface PracticeLeaderDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeLeaderDto;
}

export interface PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModel {
  relationship?: PracticeLeaderDto;
  entity1?: SystemUserDto;
  entity2?: PracticeDto;
}

export interface PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModel[] | null;
}

export interface PracticeRoleDelayedWeeksDto {
  /** @format uuid */
  id?: string;
  hsl_PracticeRole?: EntityReferenceDto;
  /** @format int32 */
  hsl_DelayedWeeks?: number | null;
  hsl_ProjectPhase?: HslProjectphase;
}

export interface PracticeRoleDelayedWeeksDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeRoleDelayedWeeksDto;
}

export interface PracticeRoleDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_cost?: MoneyDto;
  hsl_isarchitect?: boolean | null;
  hsl_isoffshore?: boolean | null;
  hsl_listprice?: MoneyDto;
  hsl_preferredclientprice?: MoneyDto;
  hsl_practiceid?: EntityReferenceDto;
  hsl_offshoreequivalentpracticeroleid?: EntityReferenceDto;
  hsl_hsl_practicerole_hsl_practiceroledelayedweeks_PracticeRole?: PracticeRoleDelayedWeeksDto[] | null;
}

export interface PracticeRoleDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeRoleDto;
}

export interface PracticeRoleDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: PracticeRoleDto[] | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProductDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
  description?: string | null;
  hsl_IsVisibleinEstimator?: boolean | null;
  hsl_ParentProductId?: EntityReferenceDto;
  hsl_InternalOwnerId?: EntityReferenceDto;
  hsl_PreSalesOwnerId?: EntityReferenceDto;
}

export interface ProductDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ProductDto;
}

export interface ProductDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ProductDto[] | null;
}

export interface ProjectSupportEffortDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_ProjectPhase?: HslProjectphasewithall;
  /** @format double */
  hsl_Hours?: number | null;
  /** @format int32 */
  hsl_FrequencyValue?: number | null;
  hsl_FrequencyUnit?: HslProjectsupporteffortHslFrequencyunit;
  /** @format double */
  hsl_TotalHours?: number | null;
  hsl_SalesEstimate?: EntityReferenceDto;
  hsl_hsl_projectsupporteffort_hsl_resourcerole?: ResourceRoleDto[] | null;
}

export interface ProjectSupportEffortDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ProjectSupportEffortDto;
}

export interface ResourceAllocationDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  /** @format int32 */
  hsl_EstimatedHours?: number | null;
  /** @format int32 */
  hsl_Hours?: number | null;
  hsl_Rate?: MoneyDto;
  /** @format int32 */
  hsl_Weeks?: number | null;
  /** @format int32 */
  hsl_DelayedWeeks?: number | null;
  hsl_HoursPreference?: HslProjectconfigurationpreferenceGc;
  hsl_ProjectPhase?: HslProjectphase;
  hsl_PracticeRole?: EntityReferenceDto;
  hsl_SalesEstimate?: EntityReferenceDto;
  hsl_ResourcePlan?: EntityReferenceDto;
  hsl_LinkedSalesEstimate?: EntityReferenceDto;
}

export interface ResourceAllocationDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ResourceAllocationDto;
}

export interface ResourcePlanDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_salesestimateid?: EntityReferenceDto;
  hsl_InvestmentSummary?: EntityReferenceDto;
  /** @format date-time */
  createdOn?: string | null;
  hsl_hsl_resourceplanresource_resourceplanid_h?: ResourcePlanResourceDto[] | null;
  hsl_hsl_investmentsummary_hsl_resourceplan_InvestmentSummary?: InvestmentSummaryDto;
}

export interface ResourcePlanDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ResourcePlanDto;
}

export interface ResourcePlanDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ResourcePlanDto[] | null;
}

export interface ResourcePlanEntryDto {
  /** @format uuid */
  id?: string;
  hsl_resourceplanresourceid?: EntityReferenceDto;
  /** @format int32 */
  hsl_hours?: number | null;
  /** @format int32 */
  hsl_projectphase?: number | null;
  /** @format int32 */
  hsl_sprint?: number | null;
  /** @format int32 */
  hsl_week?: number | null;
}

export interface ResourcePlanEntryDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ResourcePlanEntryDto;
}

export interface ResourcePlanResourceDto {
  /** @format uuid */
  id?: string;
  hsl_resourceplanid?: EntityReferenceDto;
  hsl_practiceroleid?: EntityReferenceDto;
  hsl_name?: string | null;
  /** @format double */
  hsl_rate?: number | null;
  hsl_InitiateResourceAllocation?: EntityReferenceDto;
  hsl_BlueprintResourceAllocation?: EntityReferenceDto;
  hsl_BuildResourceAllocation?: EntityReferenceDto;
  hsl_ValidateResourceAllocation?: EntityReferenceDto;
  hsl_DeployResourceAllocation?: EntityReferenceDto;
  hsl_OperateResourceAllocation?: EntityReferenceDto;
  hsl_hsl_resourceplanentry_resourceplanresourc?: ResourcePlanEntryDto[] | null;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation?: ResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation?: ResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation?: ResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation?: ResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation?: ResourceAllocationDto;
  hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation?: ResourceAllocationDto;
}

export interface ResourceRoleDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_PracticeRole?: EntityReferenceDto;
  hsl_ProjectSupportEffortId?: EntityReferenceDto;
  hsl_hsl_practicerole_hsl_resourcerole_PracticeRole?: PracticeRoleDto;
}

export interface ResourceRoleDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: ResourceRoleDto;
}

export interface RoleDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
}

export interface SalesEstimateDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_Synopsis?: string | null;
  hsl_type?: HslSalesestimateHslType;
  statecode?: HslSalesestimateStatecode;
  statuscode?: HslSalesestimateStatuscode;
  hsl_opportunityid?: EntityReferenceDto;
  hsl_Account?: EntityReferenceDto;
  hsl_salesregionid?: EntityReferenceDto;
  hsl_directorofbusinessdevelopmentid?: EntityReferenceDto;
  hsl_clientservicesprincipleid?: EntityReferenceDto;
  hsl_resourceplanid?: EntityReferenceDto;
  hsl_InvestmentSummary?: EntityReferenceDto;
  ownerId?: EntityReferenceDto;
  owningBusinessUnit?: EntityReferenceDto;
  /** @format date-time */
  hsl_estimatedue?: string | null;
  /** @format date-time */
  hsl_contractdue?: string | null;
  /** @format date-time */
  hsl_projectstartdate?: string | null;
  /** @format int32 */
  hsl_CustomerUserCount?: number | null;
  hsl_qualifiedbudget?: MoneyDto;
  hsl_initiatepreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_initiateweeks?: number | null;
  /** @format int32 */
  hsl_CalculatedInitiateWeeks?: number | null;
  hsl_blueprintpreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_blueprintweeks?: number | null;
  /** @format int32 */
  hsl_CalculatedBlueprintWeeks?: number | null;
  hsl_buildpreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_sprintduration?: number | null;
  /** @format int32 */
  hsl_CalculatedBuildWeeks?: number | null;
  hsl_validatepreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_validateweeks?: number | null;
  /** @format int32 */
  hsl_CalculatedValidateWeeks?: number | null;
  hsl_deploypreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_deployweeks?: number | null;
  /** @format int32 */
  hsl_CalculatedDeployWeeks?: number | null;
  hsl_operatepreference?: HslProjectconfigurationpreferenceGc;
  /** @format int32 */
  hsl_operateweeks?: number | null;
  /** @format int32 */
  hsl_CalculatedOperateWeeks?: number | null;
  hsl_hsl_investmentsummary_hsl_salesestimate_InvestmentSummary?: InvestmentSummaryDto;
  hsl_hsl_resourceplan_salesestimateid_hsl_sale?: ResourcePlanDto[] | null;
  hsl_hsl_salesestimate_hsl_salesestimatelineitem_salesestimateid?: SalesEstimateLineItemDto[] | null;
  hsl_hsl_links_SalesEstimate_hsl_salesestimate?: LinkDto[] | null;
  hsl_hsl_salesestimate_hsl_salesestimatepracticeassignment_salesestimateid?:
    | SalesEstimatePracticeAssignmentDto[]
    | null;
  hsl_hsl_salesestimate_hsl_resourceallocation_SalesEstimate?: ResourceAllocationDto[] | null;
  hsl_hsl_salesestimate_hsl_projectsupporteffort_SalesEstimate?: ProjectSupportEffortDto[] | null;
  hsl_salesestimateproductlineitem_SalesEst?: SalesEstimateProductLineItemDto[] | null;
}

export interface SalesEstimateDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateDto;
}

export interface SalesEstimateDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateDto[] | null;
}

export interface SalesEstimateLineItemDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_salesestimateid?: EntityReferenceDto;
  hsl_salesestimatetemplateid?: EntityReferenceDto;
  hsl_TemplateItemId?: EntityReferenceDto;
  hsl_descriptiontext?: string | null;
  hsl_isexcluded?: boolean | null;
  /** @format int32 */
  hsl_totalhours?: number | null;
  hsl_projectphase?: HslProjectphase;
  hsl_type?: HslType;
  hsl_isstandarditem?: boolean | null;
  hsl_salesestimatestandarditemid?: EntityReferenceDto;
  hsl_hsl_salesestimatelineitem_hsl_salesestimatelineitemeffort_salesestimatelineitemid?:
    | SalesEstimateLineItemEffortDto[]
    | null;
  hsl_hsl_salesestimatelineitem_hsl_assumption?: AssumptionDto[] | null;
}

export interface SalesEstimateLineItemDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateLineItemDto;
}

export interface SalesEstimateLineItemDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateLineItemDto[] | null;
}

export interface SalesEstimateLineItemEffortDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  /** @format int32 */
  hsl_hours?: number | null;
  hsl_practiceroleid?: EntityReferenceDto;
  hsl_salesestimatelineitemid?: EntityReferenceDto;
}

export interface SalesEstimateLineItemEffortDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateLineItemEffortDto;
}

export interface SalesEstimateLineItemTagDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  hsl_estimatortagsid?: string | null;
  /** @format uuid */
  hsl_salesestimatelineitemid?: string | null;
}

export interface SalesEstimateLineItemTagDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateLineItemTagDto;
}

export interface SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModel {
  relationship?: SalesEstimateLineItemTagDto;
  entity1?: SalesEstimateLineItemDto;
  entity2?: TagDto;
}

export interface SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModel[] | null;
}

export interface SalesEstimatePracticeAssignmentDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_practiceid?: EntityReferenceDto;
  hsl_salesestimateid?: EntityReferenceDto;
  ownerId?: EntityReferenceDto;
  hsl_hsl_practice_hsl_salesestimatepracticeassignment_practiceid?: PracticeDto;
  statecode?: HslSalesestimatepracticeassignmentStatecode;
  statuscode?: HslSalesestimatepracticeassignmentStatuscode;
}

export interface SalesEstimatePracticeAssignmentDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimatePracticeAssignmentDto;
}

export interface SalesEstimatePracticeAssignmentDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimatePracticeAssignmentDto[] | null;
}

export interface SalesEstimateProductLineItemDto {
  /** @format uuid */
  id?: string;
  hsl_BillingMethod?: HslProductbillingmethod;
  /** @format int32 */
  hsl_CustomerUserCount?: number | null;
  hsl_Description?: string | null;
  hsl_Name?: string | null;
  hsl_ProductId?: EntityReferenceDto;
  hsl_PricingMethodCode?: HslPricingmethodcode;
  hsl_SalesEstimateId?: EntityReferenceDto;
  hsl_SalesEstimateTemplateId?: EntityReferenceDto;
  hsl_TemplateLineItemId?: EntityReferenceDto;
  hsl_TotalPrice?: MoneyDto;
  hsl_UnitPrice?: MoneyDto;
}

export interface SalesEstimateProductLineItemDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateProductLineItemDto;
}

export interface SalesEstimateSalesEstimateTemplateDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_SalesEstimate?: EntityReferenceDto;
  hsl_SalesEstimateTemplate?: EntityReferenceDto;
}

export interface SalesEstimateSalesEstimateTemplateDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateSalesEstimateTemplateDto;
}

export interface SalesEstimateSalesEstimateTemplateDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateSalesEstimateTemplateDto[] | null;
}

export interface SalesEstimateTemplateDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_description?: string | null;
  /** @format date-time */
  hsl_LastAppliedtoEstimate?: string | null;
  hsl_hsl_salesestimatetemplate_hsl_salesestimatelineitem_salesestimatetemplateid?: SalesEstimateLineItemDto[] | null;
  hsl_salesestimateproductlineitem_SalesEstTemp?: SalesEstimateProductLineItemDto[] | null;
  hsl_salesestimatetemplatepractice?: PracticeDto[] | null;
}

export interface SalesEstimateTemplateDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplateDto;
}

export interface SalesEstimateTemplateDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplateDto[] | null;
}

export interface SalesEstimateTemplatePracticeDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  hsl_practiceid?: string | null;
  /** @format uuid */
  hsl_salesestimatetemplateid?: string | null;
}

export interface SalesEstimateTemplatePracticeDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplatePracticeDto;
}

export interface SalesEstimateTemplatePracticeDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplatePracticeDto[] | null;
}

export interface SalesEstimateTemplateTagDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  hsl_estimatortagsid?: string | null;
  /** @format uuid */
  hsl_salesestimatetemplateid?: string | null;
}

export interface SalesEstimateTemplateTagDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplateTagDto;
}

export interface SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModel {
  relationship?: SalesEstimateTemplateTagDto;
  entity1?: SalesEstimateTemplateDto;
  entity2?: TagDto;
}

export interface SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModel[] | null;
}

export interface SalesRegionDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
  hsl_territoryid?: EntityReferenceDto;
}

export interface SalesRegionDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SalesRegionDto[] | null;
}

export interface SystemUserDto {
  /** @format uuid */
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  internalEMailAddress?: string | null;
  fullName?: string | null;
  domainName?: string | null;
  status?: string | null;
  /** @format int32 */
  accessMode?: number | null;
  /** @format uuid */
  azureActiveDirectoryObjectId?: string;
  hsl_practiceteam?: HslPractice1;
  systemuserroles_association?: RoleDto[] | null;
  teammembership_association?: TeamDto[] | null;
  hsl_practiceleaders?: PracticeDto[] | null;
  hsl_Practiceestimators?: PracticeDto[] | null;
}

export interface SystemUserDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SystemUserDto;
}

export interface SystemUserDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: SystemUserDto[] | null;
}

export interface TagDto {
  /** @format uuid */
  id?: string;
  hsl_name?: string | null;
}

export interface TagDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: TagDto;
}

export interface TeamDto {
  /** @format uuid */
  id?: string;
  name?: string | null;
  description?: string | null;
}

export interface TransactionCurrencyDto {
  /** @format uuid */
  id?: string;
  currencyName?: string | null;
  isoCurrencyCode?: string | null;
  currencySymbol?: string | null;
  /** @format int32 */
  currencyPrecision?: number | null;
  /** @format double */
  exchangeRate?: number | null;
  stateCode?: TransactioncurrencyStatecode;
  statusCode?: TransactioncurrencyStatuscode;
}

export interface TransactionCurrencyDtoApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: TransactionCurrencyDto;
}

export interface TransactionCurrencyDtoIEnumerableApiResponseDto {
  /** @format uuid */
  id?: string;
  data?: TransactionCurrencyDto[] | null;
}

/** @format int32 */
export enum HslBugHslType {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
}

/** @format int32 */
export enum HslNotificationStatecode {
  Option_0 = 0,
  Option_1 = 1,
  Option_2 = 2,
  Option_3 = 3,
}

/** @format int32 */
export enum HslNotificationStatuscode {
  Option_1 = 1,
  Option_2 = 2,
  Option_3 = 3,
  Option_4 = 4,
}

/** @format int32 */
export enum HslPractice1 {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
  Option_864630004 = 864630004,
  Option_864630005 = 864630005,
  Option_864630006 = 864630006,
  Option_864630007 = 864630007,
  Option_864630008 = 864630008,
  Option_864630009 = 864630009,
  Option_864630010 = 864630010,
  Option_864630011 = 864630011,
  Option_864630012 = 864630012,
  Option_864630013 = 864630013,
  Option_864630014 = 864630014,
  Option_864630015 = 864630015,
  Option_864630016 = 864630016,
  Option_864630017 = 864630017,
  Option_864630018 = 864630018,
  Option_864630019 = 864630019,
  Option_864630020 = 864630020,
  Option_864630021 = 864630021,
  Option_864630022 = 864630022,
  Option_864630023 = 864630023,
  Option_864630024 = 864630024,
  Option_864630025 = 864630025,
  Option_864630026 = 864630026,
  Option_864630027 = 864630027,
  Option_864630028 = 864630028,
  Option_864630029 = 864630029,
  Option_864630030 = 864630030,
  Option_864630031 = 864630031,
  Option_864630032 = 864630032,
  Option_864630033 = 864630033,
  Option_864630034 = 864630034,
  Option_864630035 = 864630035,
  Option_864630036 = 864630036,
  Option_864630037 = 864630037,
  Option_864630038 = 864630038,
}

/** @format int32 */
export enum HslPricingmethodcode {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
}

/** @format int32 */
export enum HslProductbillingmethod {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
}

/** @format int32 */
export enum HslProjectconfigurationpreferenceGc {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
}

/** @format int32 */
export enum HslProjectphase {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
  Option_864630004 = 864630004,
  Option_864630005 = 864630005,
}

/** @format int32 */
export enum HslProjectphasewithall {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
  Option_864630004 = 864630004,
  Option_864630005 = 864630005,
  Option_864630006 = 864630006,
}

/** @format int32 */
export enum HslProjectsupporteffortHslFrequencyunit {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
}

/** @format int32 */
export enum HslSalesestimateHslType {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
}

/** @format int32 */
export enum HslSalesestimateStatecode {
  Option_0 = 0,
  Option_1 = 1,
}

/** @format int32 */
export enum HslSalesestimateStatuscode {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
  Option_864630004 = 864630004,
}

/** @format int32 */
export enum HslSalesestimatepracticeassignmentStatecode {
  Option_0 = 0,
  Option_1 = 1,
}

/** @format int32 */
export enum HslSalesestimatepracticeassignmentStatuscode {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630003 = 864630003,
}

/** @format int32 */
export enum HslType {
  Option_864630000 = 864630000,
  Option_864630001 = 864630001,
  Option_864630002 = 864630002,
  Option_864630003 = 864630003,
  Option_864630004 = 864630004,
  Option_864630006 = 864630006,
  Option_864630007 = 864630007,
}

/** @format int32 */
export enum TransactioncurrencyStatecode {
  Option_0 = 0,
  Option_1 = 1,
}

/** @format int32 */
export enum TransactioncurrencyStatuscode {
  Option_1 = 1,
  Option_2 = 2,
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Estimator.Api
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  admin = {
    /**
     * No description
     *
     * @tags Admin
     * @name AdminClearCache
     * @request POST:/Admin/ClearCache
     * @secure
     * @response `200` `AnnotationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    adminClearCache: (
      query?: {
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AnnotationDtoApiResponseDto, void>({
        path: `/Admin/ClearCache`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminResetConnectionPool
     * @request POST:/Admin/ResetConnectionPool
     * @secure
     * @response `200` `AnnotationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    adminResetConnectionPool: (
      query?: {
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AnnotationDtoApiResponseDto, void>({
        path: `/Admin/ResetConnectionPool`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AdminAddAdmin
     * @request POST:/Admin/AddAdmin
     * @secure
     * @response `201` `AnnotationDtoApiResponseDto` Created
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    adminAddAdmin: (data: SystemUserDto, params: RequestParams = {}) =>
      this.http.request<AnnotationDtoApiResponseDto, void>({
        path: `/Admin/AddAdmin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  annotation = {
    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationGetById
     * @request GET:/Annotation/GetById
     * @secure
     * @response `200` `AnnotationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    annotationGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AnnotationDtoApiResponseDto, void | ProblemDetails>({
        path: `/Annotation/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationUpdate
     * @request PATCH:/Annotation/Update
     * @secure
     * @response `200` `AnnotationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    annotationUpdate: (data: AnnotationDto, params: RequestParams = {}) =>
      this.http.request<AnnotationDtoApiResponseDto, void | ProblemDetails>({
        path: `/Annotation/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationAdd
     * @request POST:/Annotation/Add
     * @secure
     * @response `201` `AnnotationDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    annotationAdd: (data: AnnotationDto, params: RequestParams = {}) =>
      this.http.request<AnnotationDtoApiResponseDto, ProblemDetails | void>({
        path: `/Annotation/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationDelete
     * @request DELETE:/Annotation/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    annotationDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/Annotation/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationGetAllDocumentsBySalesEstimateId
     * @request GET:/Annotation/GetAllDocumentsBySalesEstimateId
     * @secure
     * @response `200` `AnnotationDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    annotationGetAllDocumentsBySalesEstimateId: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AnnotationDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/Annotation/GetAllDocumentsBySalesEstimateId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Annotation
     * @name AnnotationGetAllNotesBySalesEstimateId
     * @request GET:/Annotation/GetAllNotesBySalesEstimateId
     * @secure
     * @response `200` `AnnotationDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    annotationGetAllNotesBySalesEstimateId: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AnnotationDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/Annotation/GetAllNotesBySalesEstimateId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  assumption = {
    /**
     * No description
     *
     * @tags Assumption
     * @name AssumptionGetById
     * @request GET:/Assumption/GetById
     * @secure
     * @response `200` `AssumptionDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    assumptionGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AssumptionDtoApiResponseDto, void | ProblemDetails>({
        path: `/Assumption/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assumption
     * @name AssumptionGetAllCount
     * @request GET:/Assumption/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    assumptionGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Assumption/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assumption
     * @name AssumptionUpdate
     * @request PATCH:/Assumption/Update
     * @secure
     * @response `200` `AssumptionDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    assumptionUpdate: (data: AssumptionDto, params: RequestParams = {}) =>
      this.http.request<AssumptionDtoApiResponseDto, void | ProblemDetails>({
        path: `/Assumption/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assumption
     * @name AssumptionAdd
     * @request POST:/Assumption/Add
     * @secure
     * @response `201` `AssumptionDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    assumptionAdd: (data: AssumptionDto, params: RequestParams = {}) =>
      this.http.request<AssumptionDtoApiResponseDto, ProblemDetails | void>({
        path: `/Assumption/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assumption
     * @name AssumptionDelete
     * @request DELETE:/Assumption/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    assumptionDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/Assumption/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  bug = {
    /**
     * No description
     *
     * @tags Bug
     * @name BugGetById
     * @request GET:/Bug/GetById
     * @secure
     * @response `200` `BugDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    bugGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BugDtoApiResponseDto, void | ProblemDetails>({
        path: `/Bug/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bug
     * @name BugGetAllCount
     * @request GET:/Bug/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    bugGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Bug/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bug
     * @name BugUpdate
     * @request PATCH:/Bug/Update
     * @secure
     * @response `200` `BugDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    bugUpdate: (data: BugDto, params: RequestParams = {}) =>
      this.http.request<BugDtoApiResponseDto, void | ProblemDetails>({
        path: `/Bug/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bug
     * @name BugAdd
     * @request POST:/Bug/Add
     * @secure
     * @response `201` `BugDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    bugAdd: (data: BugDto, params: RequestParams = {}) =>
      this.http.request<BugDtoApiResponseDto, ProblemDetails | void>({
        path: `/Bug/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  documentGeneration = {
    /**
     * No description
     *
     * @tags DocumentGeneration
     * @name DocumentGenerationGenerateStatementOfWork
     * @request POST:/DocumentGeneration/GenerateStatementOfWork
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    documentGenerationGenerateStatementOfWork: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/DocumentGeneration/GenerateStatementOfWork`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DocumentGeneration
     * @name DocumentGenerationGenerateWorkBreakdownStructure
     * @request POST:/DocumentGeneration/GenerateWorkBreakdownStructure
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    documentGenerationGenerateWorkBreakdownStructure: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/DocumentGeneration/GenerateWorkBreakdownStructure`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  feedback = {
    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackGetById
     * @request GET:/Feedback/GetById
     * @secure
     * @response `200` `FeedbackDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    feedbackGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FeedbackDtoApiResponseDto, void | ProblemDetails>({
        path: `/Feedback/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackGetAllCount
     * @request GET:/Feedback/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    feedbackGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Feedback/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackUpdate
     * @request PATCH:/Feedback/Update
     * @secure
     * @response `200` `FeedbackDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    feedbackUpdate: (data: FeedbackDto, params: RequestParams = {}) =>
      this.http.request<FeedbackDtoApiResponseDto, void | ProblemDetails>({
        path: `/Feedback/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackAdd
     * @request POST:/Feedback/Add
     * @secure
     * @response `201` `FeedbackDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    feedbackAdd: (data: FeedbackDto, params: RequestParams = {}) =>
      this.http.request<FeedbackDtoApiResponseDto, ProblemDetails | void>({
        path: `/Feedback/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackDelete
     * @request DELETE:/Feedback/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    feedbackDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/Feedback/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  investmentSummary = {
    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryGetById
     * @request GET:/InvestmentSummary/GetById
     * @secure
     * @response `200` `InvestmentSummaryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummary/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryGetAllCount
     * @request GET:/InvestmentSummary/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/InvestmentSummary/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryUpdate
     * @request PATCH:/InvestmentSummary/Update
     * @secure
     * @response `200` `InvestmentSummaryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryUpdate: (data: InvestmentSummaryDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummary/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryAdd
     * @request POST:/InvestmentSummary/Add
     * @secure
     * @response `201` `InvestmentSummaryDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryAdd: (data: InvestmentSummaryDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryDtoApiResponseDto, ProblemDetails | void>({
        path: `/InvestmentSummary/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryDelete
     * @request DELETE:/InvestmentSummary/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/InvestmentSummary/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryGetBySalesEstimateIdWithRelatedEntities
     * @request GET:/InvestmentSummary/GetBySalesEstimateIdWithRelatedEntities
     * @secure
     * @response `200` `InvestmentSummaryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryGetBySalesEstimateIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummary/GetBySalesEstimateIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryGetByResourcePlanIdWithRelatedEntities
     * @request GET:/InvestmentSummary/GetByResourcePlanIdWithRelatedEntities
     * @secure
     * @response `200` `InvestmentSummaryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryGetByResourcePlanIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        resourcePlanId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummary/GetByResourcePlanIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummary
     * @name InvestmentSummaryGenerateInvestmentSummaryBySalesEstimateId
     * @request POST:/InvestmentSummary/GenerateInvestmentSummaryBySalesEstimateId
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryGenerateInvestmentSummaryBySalesEstimateId: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/InvestmentSummary/GenerateInvestmentSummaryBySalesEstimateId`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  investmentSummaryDistribution = {
    /**
     * No description
     *
     * @tags InvestmentSummaryDistribution
     * @name InvestmentSummaryDistributionGetById
     * @request GET:/InvestmentSummaryDistribution/GetById
     * @secure
     * @response `200` `InvestmentSummaryDistributionDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryDistributionGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryDistributionDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryDistribution/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryDistribution
     * @name InvestmentSummaryDistributionGetAllCount
     * @request GET:/InvestmentSummaryDistribution/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryDistributionGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/InvestmentSummaryDistribution/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryDistribution
     * @name InvestmentSummaryDistributionUpdate
     * @request PATCH:/InvestmentSummaryDistribution/Update
     * @secure
     * @response `200` `InvestmentSummaryDistributionDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryDistributionUpdate: (data: InvestmentSummaryDistributionDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryDistributionDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryDistribution/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryDistribution
     * @name InvestmentSummaryDistributionAdd
     * @request POST:/InvestmentSummaryDistribution/Add
     * @secure
     * @response `201` `InvestmentSummaryDistributionDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryDistributionAdd: (data: InvestmentSummaryDistributionDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryDistributionDtoApiResponseDto, ProblemDetails | void>({
        path: `/InvestmentSummaryDistribution/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryDistribution
     * @name InvestmentSummaryDistributionDelete
     * @request DELETE:/InvestmentSummaryDistribution/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryDistributionDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/InvestmentSummaryDistribution/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  investmentSummaryPricingByLineItem = {
    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByLineItem
     * @name InvestmentSummaryPricingByLineItemGetById
     * @request GET:/InvestmentSummaryPricingByLineItem/GetById
     * @secure
     * @response `200` `InvestmentSummaryPricingByLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByLineItemGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryPricingByLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByLineItem/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByLineItem
     * @name InvestmentSummaryPricingByLineItemGetAllCount
     * @request GET:/InvestmentSummaryPricingByLineItem/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryPricingByLineItemGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/InvestmentSummaryPricingByLineItem/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByLineItem
     * @name InvestmentSummaryPricingByLineItemUpdate
     * @request PATCH:/InvestmentSummaryPricingByLineItem/Update
     * @secure
     * @response `200` `InvestmentSummaryPricingByLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByLineItemUpdate: (
      data: InvestmentSummaryPricingByLineItemDto,
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryPricingByLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByLineItem/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByLineItem
     * @name InvestmentSummaryPricingByLineItemAdd
     * @request POST:/InvestmentSummaryPricingByLineItem/Add
     * @secure
     * @response `201` `InvestmentSummaryPricingByLineItemDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryPricingByLineItemAdd: (data: InvestmentSummaryPricingByLineItemDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryPricingByLineItemDtoApiResponseDto, ProblemDetails | void>({
        path: `/InvestmentSummaryPricingByLineItem/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByLineItem
     * @name InvestmentSummaryPricingByLineItemDelete
     * @request DELETE:/InvestmentSummaryPricingByLineItem/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByLineItemDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByLineItem/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  investmentSummaryPricingByResource = {
    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByResource
     * @name InvestmentSummaryPricingByResourceGetById
     * @request GET:/InvestmentSummaryPricingByResource/GetById
     * @secure
     * @response `200` `InvestmentSummaryPricingByResourceDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByResourceGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryPricingByResourceDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByResource/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByResource
     * @name InvestmentSummaryPricingByResourceGetAllCount
     * @request GET:/InvestmentSummaryPricingByResource/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryPricingByResourceGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/InvestmentSummaryPricingByResource/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByResource
     * @name InvestmentSummaryPricingByResourceUpdate
     * @request PATCH:/InvestmentSummaryPricingByResource/Update
     * @secure
     * @response `200` `InvestmentSummaryPricingByResourceDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByResourceUpdate: (
      data: InvestmentSummaryPricingByResourceDto,
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryPricingByResourceDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByResource/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByResource
     * @name InvestmentSummaryPricingByResourceAdd
     * @request POST:/InvestmentSummaryPricingByResource/Add
     * @secure
     * @response `201` `InvestmentSummaryPricingByResourceDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryPricingByResourceAdd: (data: InvestmentSummaryPricingByResourceDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryPricingByResourceDtoApiResponseDto, ProblemDetails | void>({
        path: `/InvestmentSummaryPricingByResource/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryPricingByResource
     * @name InvestmentSummaryPricingByResourceDelete
     * @request DELETE:/InvestmentSummaryPricingByResource/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryPricingByResourceDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/InvestmentSummaryPricingByResource/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  investmentSummaryProductPricing = {
    /**
     * No description
     *
     * @tags InvestmentSummaryProductPricing
     * @name InvestmentSummaryProductPricingGetById
     * @request GET:/InvestmentSummaryProductPricing/GetById
     * @secure
     * @response `200` `InvestmentSummaryProductPricingDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryProductPricingGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestmentSummaryProductPricingDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryProductPricing/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryProductPricing
     * @name InvestmentSummaryProductPricingGetAllCount
     * @request GET:/InvestmentSummaryProductPricing/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryProductPricingGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/InvestmentSummaryProductPricing/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryProductPricing
     * @name InvestmentSummaryProductPricingUpdate
     * @request PATCH:/InvestmentSummaryProductPricing/Update
     * @secure
     * @response `200` `InvestmentSummaryProductPricingDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryProductPricingUpdate: (data: InvestmentSummaryProductPricingDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryProductPricingDtoApiResponseDto, void | ProblemDetails>({
        path: `/InvestmentSummaryProductPricing/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryProductPricing
     * @name InvestmentSummaryProductPricingAdd
     * @request POST:/InvestmentSummaryProductPricing/Add
     * @secure
     * @response `201` `InvestmentSummaryProductPricingDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    investmentSummaryProductPricingAdd: (data: InvestmentSummaryProductPricingDto, params: RequestParams = {}) =>
      this.http.request<InvestmentSummaryProductPricingDtoApiResponseDto, ProblemDetails | void>({
        path: `/InvestmentSummaryProductPricing/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InvestmentSummaryProductPricing
     * @name InvestmentSummaryProductPricingDelete
     * @request DELETE:/InvestmentSummaryProductPricing/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    investmentSummaryProductPricingDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/InvestmentSummaryProductPricing/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  link = {
    /**
     * No description
     *
     * @tags Link
     * @name LinkGetById
     * @request GET:/Link/GetById
     * @secure
     * @response `200` `LinkDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    linkGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LinkDtoApiResponseDto, void | ProblemDetails>({
        path: `/Link/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Link
     * @name LinkGetAllCount
     * @request GET:/Link/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    linkGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Link/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Link
     * @name LinkUpdate
     * @request PATCH:/Link/Update
     * @secure
     * @response `200` `LinkDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    linkUpdate: (data: LinkDto, params: RequestParams = {}) =>
      this.http.request<LinkDtoApiResponseDto, void | ProblemDetails>({
        path: `/Link/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Link
     * @name LinkAdd
     * @request POST:/Link/Add
     * @secure
     * @response `201` `LinkDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    linkAdd: (data: LinkDto, params: RequestParams = {}) =>
      this.http.request<LinkDtoApiResponseDto, ProblemDetails | void>({
        path: `/Link/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Link
     * @name LinkDelete
     * @request DELETE:/Link/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    linkDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/Link/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  notification = {
    /**
     * No description
     *
     * @tags Notification
     * @name NotificationGetById
     * @request GET:/Notification/GetById
     * @secure
     * @response `200` `NotificationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    notificationGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<NotificationDtoApiResponseDto, void | ProblemDetails>({
        path: `/Notification/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationGetAllCount
     * @request GET:/Notification/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    notificationGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Notification/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationUpdate
     * @request PATCH:/Notification/Update
     * @secure
     * @response `200` `NotificationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    notificationUpdate: (data: NotificationDto, params: RequestParams = {}) =>
      this.http.request<NotificationDtoApiResponseDto, void | ProblemDetails>({
        path: `/Notification/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationAdd
     * @request POST:/Notification/Add
     * @secure
     * @response `201` `NotificationDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    notificationAdd: (data: NotificationDto, params: RequestParams = {}) =>
      this.http.request<NotificationDtoApiResponseDto, ProblemDetails | void>({
        path: `/Notification/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationDelete
     * @request DELETE:/Notification/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    notificationDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/Notification/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationGetAll
     * @request POST:/Notification/GetAll
     * @secure
     * @response `200` `NotificationDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    notificationGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<NotificationDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/Notification/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationGetUnreadBySystemUserId
     * @request GET:/Notification/GetUnreadBySystemUserId
     * @secure
     * @response `200` `NotificationDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    notificationGetUnreadBySystemUserId: (
      query?: {
        /** @format uuid */
        systemUserId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<NotificationDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/Notification/GetUnreadBySystemUserId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name NotificationMarkAllAsRead
     * @request POST:/Notification/MarkAllAsRead
     * @secure
     * @response `201` `void` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    notificationMarkAllAsRead: (data: string[], params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/Notification/MarkAllAsRead`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  optionSet = {
    /**
     * No description
     *
     * @tags OptionSet
     * @name GetAll
     * @request GET:/OptionSet/GetAll
     * @secure
     * @response `200` `OptionSetDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    getAll: (params: RequestParams = {}) =>
      this.http.request<OptionSetDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/OptionSet/GetAll`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  practice = {
    /**
     * No description
     *
     * @tags Practice
     * @name PracticeGetById
     * @request GET:/Practice/GetById
     * @secure
     * @response `200` `PracticeDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeDtoApiResponseDto, void | ProblemDetails>({
        path: `/Practice/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Practice
     * @name PracticeGetAllCount
     * @request GET:/Practice/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Practice/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Practice
     * @name PracticeUpdate
     * @request PATCH:/Practice/Update
     * @secure
     * @response `200` `PracticeDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceUpdate: (data: PracticeDto, params: RequestParams = {}) =>
      this.http.request<PracticeDtoApiResponseDto, void | ProblemDetails>({
        path: `/Practice/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Practice
     * @name PracticeGetAll
     * @request POST:/Practice/GetAll
     * @secure
     * @response `200` `PracticeDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<PracticeDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/Practice/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  practiceEstimator = {
    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorGetById
     * @request GET:/PracticeEstimator/GetById
     * @secure
     * @response `200` `PracticeEstimatorDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceEstimatorGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeEstimatorDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeEstimator/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorGetAllCount
     * @request GET:/PracticeEstimator/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceEstimatorGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/PracticeEstimator/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorGetAll
     * @request POST:/PracticeEstimator/GetAll
     * @secure
     * @response `200` `PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceEstimatorGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<
        PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeEstimator/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorGetAllByEntity1Id
     * @request GET:/PracticeEstimator/GetAllByEntity1Id
     * @secure
     * @response `200` `PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceEstimatorGetAllByEntity1Id: (
      query?: {
        /** @format uuid */
        entity1Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeEstimator/GetAllByEntity1Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorGetAllByEntity2Id
     * @request GET:/PracticeEstimator/GetAllByEntity2Id
     * @secure
     * @response `200` `PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceEstimatorGetAllByEntity2Id: (
      query?: {
        /** @format uuid */
        entity2Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PracticeEstimatorDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeEstimator/GetAllByEntity2Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorAdd
     * @request POST:/PracticeEstimator/Add
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceEstimatorAdd: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/PracticeEstimator/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeEstimator
     * @name PracticeEstimatorDelete
     * @request DELETE:/PracticeEstimator/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceEstimatorDelete: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/PracticeEstimator/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  practiceLeader = {
    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderGetById
     * @request GET:/PracticeLeader/GetById
     * @secure
     * @response `200` `PracticeLeaderDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceLeaderGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeLeaderDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeLeader/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderGetAllCount
     * @request GET:/PracticeLeader/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceLeaderGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/PracticeLeader/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderGetAll
     * @request POST:/PracticeLeader/GetAll
     * @secure
     * @response `200` `PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceLeaderGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<
        PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeLeader/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderGetAllByEntity1Id
     * @request GET:/PracticeLeader/GetAllByEntity1Id
     * @secure
     * @response `200` `PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceLeaderGetAllByEntity1Id: (
      query?: {
        /** @format uuid */
        entity1Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeLeader/GetAllByEntity1Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderGetAllByEntity2Id
     * @request GET:/PracticeLeader/GetAllByEntity2Id
     * @secure
     * @response `200` `PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceLeaderGetAllByEntity2Id: (
      query?: {
        /** @format uuid */
        entity2Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PracticeLeaderDtoSystemUserDtoPracticeDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/PracticeLeader/GetAllByEntity2Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderAdd
     * @request POST:/PracticeLeader/Add
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceLeaderAdd: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/PracticeLeader/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeLeader
     * @name PracticeLeaderDelete
     * @request DELETE:/PracticeLeader/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceLeaderDelete: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/PracticeLeader/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  practiceRole = {
    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetById
     * @request GET:/PracticeRole/GetById
     * @secure
     * @response `200` `PracticeRoleDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllCount
     * @request GET:/PracticeRole/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceRoleGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/PracticeRole/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAll
     * @request POST:/PracticeRole/GetAll
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/PracticeRole/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllWithoutPagination
     * @request GET:/PracticeRole/GetAllWithoutPagination
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAllWithoutPagination: (params: RequestParams = {}) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/PracticeRole/GetAllWithoutPagination`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllByResourcePlanId
     * @request GET:/PracticeRole/GetAllByResourcePlanId
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAllByResourcePlanId: (
      query?: {
        /** @format uuid */
        resourcePlanId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/GetAllByResourcePlanId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleUpdate
     * @request PATCH:/PracticeRole/Update
     * @secure
     * @response `200` `PracticeRoleDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleUpdate: (data: PracticeRoleDto, params: RequestParams = {}) =>
      this.http.request<PracticeRoleDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleAdd
     * @request POST:/PracticeRole/Add
     * @secure
     * @response `201` `PracticeRoleDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceRoleAdd: (data: PracticeRoleDto, params: RequestParams = {}) =>
      this.http.request<PracticeRoleDtoApiResponseDto, ProblemDetails | void>({
        path: `/PracticeRole/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleDelete
     * @request DELETE:/PracticeRole/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/PracticeRole/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllByPracticeId
     * @request GET:/PracticeRole/GetAllByPracticeId
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAllByPracticeId: (
      query?: {
        /** @format uuid */
        practiceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/GetAllByPracticeId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllBySalesEstimateId
     * @request GET:/PracticeRole/GetAllBySalesEstimateId
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAllBySalesEstimateId: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/GetAllBySalesEstimateId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRole
     * @name PracticeRoleGetAllByPracticeIdWithRelatedEntities
     * @request GET:/PracticeRole/GetAllByPracticeIdWithRelatedEntities
     * @secure
     * @response `200` `PracticeRoleDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleGetAllByPracticeIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        practiceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRole/GetAllByPracticeIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  practiceRoleDelayedWeeks = {
    /**
     * No description
     *
     * @tags PracticeRoleDelayedWeeks
     * @name PracticeRoleDelayedWeeksGetById
     * @request GET:/PracticeRoleDelayedWeeks/GetById
     * @secure
     * @response `200` `PracticeRoleDelayedWeeksDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleDelayedWeeksGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PracticeRoleDelayedWeeksDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRoleDelayedWeeks/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRoleDelayedWeeks
     * @name PracticeRoleDelayedWeeksGetAllCount
     * @request GET:/PracticeRoleDelayedWeeks/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceRoleDelayedWeeksGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/PracticeRoleDelayedWeeks/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRoleDelayedWeeks
     * @name PracticeRoleDelayedWeeksUpdate
     * @request PATCH:/PracticeRoleDelayedWeeks/Update
     * @secure
     * @response `200` `PracticeRoleDelayedWeeksDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleDelayedWeeksUpdate: (data: PracticeRoleDelayedWeeksDto, params: RequestParams = {}) =>
      this.http.request<PracticeRoleDelayedWeeksDtoApiResponseDto, void | ProblemDetails>({
        path: `/PracticeRoleDelayedWeeks/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRoleDelayedWeeks
     * @name PracticeRoleDelayedWeeksAdd
     * @request POST:/PracticeRoleDelayedWeeks/Add
     * @secure
     * @response `201` `PracticeRoleDelayedWeeksDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    practiceRoleDelayedWeeksAdd: (data: PracticeRoleDelayedWeeksDto, params: RequestParams = {}) =>
      this.http.request<PracticeRoleDelayedWeeksDtoApiResponseDto, ProblemDetails | void>({
        path: `/PracticeRoleDelayedWeeks/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PracticeRoleDelayedWeeks
     * @name PracticeRoleDelayedWeeksDelete
     * @request DELETE:/PracticeRoleDelayedWeeks/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    practiceRoleDelayedWeeksDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/PracticeRoleDelayedWeeks/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  product = {
    /**
     * No description
     *
     * @tags Product
     * @name ProductGetById
     * @request GET:/Product/GetById
     * @secure
     * @response `200` `ProductDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    productGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ProductDtoApiResponseDto, void | ProblemDetails>({
        path: `/Product/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetAllCount
     * @request GET:/Product/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    productGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Product/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Product
     * @name ProductGetAllVisibleInEstimator
     * @request GET:/Product/GetAllVisibleInEstimator
     * @secure
     * @response `200` `ProductDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    productGetAllVisibleInEstimator: (params: RequestParams = {}) =>
      this.http.request<ProductDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/Product/GetAllVisibleInEstimator`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  projectSupportEffort = {
    /**
     * No description
     *
     * @tags ProjectSupportEffort
     * @name ProjectSupportEffortGetById
     * @request GET:/ProjectSupportEffort/GetById
     * @secure
     * @response `200` `ProjectSupportEffortDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    projectSupportEffortGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ProjectSupportEffortDtoApiResponseDto, void | ProblemDetails>({
        path: `/ProjectSupportEffort/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectSupportEffort
     * @name ProjectSupportEffortGetAllCount
     * @request GET:/ProjectSupportEffort/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    projectSupportEffortGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/ProjectSupportEffort/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectSupportEffort
     * @name ProjectSupportEffortUpdate
     * @request PATCH:/ProjectSupportEffort/Update
     * @secure
     * @response `200` `ProjectSupportEffortDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    projectSupportEffortUpdate: (data: ProjectSupportEffortDto, params: RequestParams = {}) =>
      this.http.request<ProjectSupportEffortDtoApiResponseDto, void | ProblemDetails>({
        path: `/ProjectSupportEffort/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectSupportEffort
     * @name ProjectSupportEffortAdd
     * @request POST:/ProjectSupportEffort/Add
     * @secure
     * @response `201` `ProjectSupportEffortDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    projectSupportEffortAdd: (data: ProjectSupportEffortDto, params: RequestParams = {}) =>
      this.http.request<ProjectSupportEffortDtoApiResponseDto, ProblemDetails | void>({
        path: `/ProjectSupportEffort/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectSupportEffort
     * @name ProjectSupportEffortDelete
     * @request DELETE:/ProjectSupportEffort/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    projectSupportEffortDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/ProjectSupportEffort/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  resourceAllocation = {
    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationGetById
     * @request GET:/ResourceAllocation/GetById
     * @secure
     * @response `200` `ResourceAllocationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceAllocationGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourceAllocationDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourceAllocation/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationGetAllCount
     * @request GET:/ResourceAllocation/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourceAllocationGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/ResourceAllocation/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationUpdate
     * @request PATCH:/ResourceAllocation/Update
     * @secure
     * @response `200` `ResourceAllocationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceAllocationUpdate: (data: ResourceAllocationDto, params: RequestParams = {}) =>
      this.http.request<ResourceAllocationDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourceAllocation/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationAdd
     * @request POST:/ResourceAllocation/Add
     * @secure
     * @response `201` `ResourceAllocationDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourceAllocationAdd: (data: ResourceAllocationDto, params: RequestParams = {}) =>
      this.http.request<ResourceAllocationDtoApiResponseDto, ProblemDetails | void>({
        path: `/ResourceAllocation/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationDelete
     * @request DELETE:/ResourceAllocation/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceAllocationDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/ResourceAllocation/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceAllocation
     * @name ResourceAllocationGenerateResourceAllocations
     * @request POST:/ResourceAllocation/GenerateResourceAllocations
     * @secure
     * @response `200` `void` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourceAllocationGenerateResourceAllocations: (
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void>({
        path: `/ResourceAllocation/GenerateResourceAllocations`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  resourcePlan = {
    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanGetById
     * @request GET:/ResourcePlan/GetById
     * @secure
     * @response `200` `ResourcePlanDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourcePlanDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlan/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanGetAllBySalesEstimateId
     * @request GET:/ResourcePlan/GetAllBySalesEstimateId
     * @secure
     * @response `200` `ResourcePlanDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanGetAllBySalesEstimateId: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourcePlanDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlan/GetAllBySalesEstimateId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanGetByIdWithRelatedEntities
     * @request GET:/ResourcePlan/GetByIdWithRelatedEntities
     * @secure
     * @response `200` `ResourcePlanDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanGetByIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourcePlanDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlan/GetByIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanGetAllCount
     * @request GET:/ResourcePlan/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourcePlanGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/ResourcePlan/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanUpdate
     * @request PATCH:/ResourcePlan/Update
     * @secure
     * @response `200` `ResourcePlanDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanUpdate: (data: ResourcePlanDto, params: RequestParams = {}) =>
      this.http.request<ResourcePlanDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlan/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanAdd
     * @request POST:/ResourcePlan/Add
     * @secure
     * @response `201` `ResourcePlanDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourcePlanAdd: (data: ResourcePlanDto, params: RequestParams = {}) =>
      this.http.request<ResourcePlanDtoApiResponseDto, ProblemDetails | void>({
        path: `/ResourcePlan/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanDelete
     * @request DELETE:/ResourcePlan/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/ResourcePlan/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanGetAll
     * @request POST:/ResourcePlan/GetAll
     * @secure
     * @response `200` `ResourcePlanDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<ResourcePlanDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/ResourcePlan/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanBulkUpdateResourcePlan
     * @request POST:/ResourcePlan/BulkUpdateResourcePlan
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanBulkUpdateResourcePlan: (
      data: BulkUpdateResourcePlanIncludingDeleteDtoApiRequestDto,
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/ResourcePlan/BulkUpdateResourcePlan`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlan
     * @name ResourcePlanApplyResourcePlanAsDefaultForSalesEstimate
     * @request POST:/ResourcePlan/ApplyResourcePlanAsDefaultForSalesEstimate
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanApplyResourcePlanAsDefaultForSalesEstimate: (
      data: ApplyResourcePlanDtoApiRequestDto,
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/ResourcePlan/ApplyResourcePlanAsDefaultForSalesEstimate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  resourcePlanEntry = {
    /**
     * No description
     *
     * @tags ResourcePlanEntry
     * @name ResourcePlanEntryGetById
     * @request GET:/ResourcePlanEntry/GetById
     * @secure
     * @response `200` `ResourcePlanEntryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanEntryGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourcePlanEntryDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlanEntry/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlanEntry
     * @name ResourcePlanEntryGetAllCount
     * @request GET:/ResourcePlanEntry/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourcePlanEntryGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/ResourcePlanEntry/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlanEntry
     * @name ResourcePlanEntryUpdate
     * @request PATCH:/ResourcePlanEntry/Update
     * @secure
     * @response `200` `ResourcePlanEntryDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanEntryUpdate: (data: ResourcePlanEntryDto, params: RequestParams = {}) =>
      this.http.request<ResourcePlanEntryDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourcePlanEntry/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlanEntry
     * @name ResourcePlanEntryAdd
     * @request POST:/ResourcePlanEntry/Add
     * @secure
     * @response `201` `ResourcePlanEntryDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourcePlanEntryAdd: (data: ResourcePlanEntryDto, params: RequestParams = {}) =>
      this.http.request<ResourcePlanEntryDtoApiResponseDto, ProblemDetails | void>({
        path: `/ResourcePlanEntry/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourcePlanEntry
     * @name ResourcePlanEntryDelete
     * @request DELETE:/ResourcePlanEntry/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourcePlanEntryDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/ResourcePlanEntry/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  resourceRole = {
    /**
     * No description
     *
     * @tags ResourceRole
     * @name ResourceRoleGetById
     * @request GET:/ResourceRole/GetById
     * @secure
     * @response `200` `ResourceRoleDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceRoleGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ResourceRoleDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourceRole/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceRole
     * @name ResourceRoleGetAllCount
     * @request GET:/ResourceRole/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourceRoleGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/ResourceRole/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceRole
     * @name ResourceRoleUpdate
     * @request PATCH:/ResourceRole/Update
     * @secure
     * @response `200` `ResourceRoleDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceRoleUpdate: (data: ResourceRoleDto, params: RequestParams = {}) =>
      this.http.request<ResourceRoleDtoApiResponseDto, void | ProblemDetails>({
        path: `/ResourceRole/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceRole
     * @name ResourceRoleAdd
     * @request POST:/ResourceRole/Add
     * @secure
     * @response `201` `ResourceRoleDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    resourceRoleAdd: (data: ResourceRoleDto, params: RequestParams = {}) =>
      this.http.request<ResourceRoleDtoApiResponseDto, ProblemDetails | void>({
        path: `/ResourceRole/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ResourceRole
     * @name ResourceRoleDelete
     * @request DELETE:/ResourceRole/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    resourceRoleDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/ResourceRole/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  salesEstimate = {
    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetById
     * @request GET:/SalesEstimate/GetById
     * @secure
     * @response `200` `SalesEstimateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimate/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetByIdWithRelatedEntities
     * @request GET:/SalesEstimate/GetByIdWithRelatedEntities
     * @secure
     * @response `200` `SalesEstimateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateGetByIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimate/GetByIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAllCount
     * @request GET:/SalesEstimate/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimate/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateUpdate
     * @request PATCH:/SalesEstimate/Update
     * @secure
     * @response `200` `SalesEstimateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateUpdate: (data: SalesEstimateDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimate/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateAdd
     * @request POST:/SalesEstimate/Add
     * @secure
     * @response `201` `SalesEstimateDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateAdd: (data: SalesEstimateDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimate/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateDelete
     * @request DELETE:/SalesEstimate/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimate/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAll
     * @request POST:/SalesEstimate/GetAll
     * @secure
     * @response `200` `SalesEstimateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimate/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAllCountByOwnerId
     * @request POST:/SalesEstimate/GetAllCountByOwnerId
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateGetAllCountByOwnerId: (
      query?: {
        /** @format uuid */
        ownerId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimate/GetAllCountByOwnerId`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAllByOwnerId
     * @request POST:/SalesEstimate/GetAllByOwnerId
     * @secure
     * @response `200` `SalesEstimateDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateGetAllByOwnerId: (data: OwnerIdPaginationDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimate/GetAllByOwnerId`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAllCountByPracticeIds
     * @request POST:/SalesEstimate/GetAllCountByPracticeIds
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateGetAllCountByPracticeIds: (data: string[], params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimate/GetAllCountByPracticeIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateGetAllByPracticeIds
     * @request POST:/SalesEstimate/GetAllByPracticeIds
     * @secure
     * @response `200` `SalesEstimateDtoIEnumerableApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateGetAllByPracticeIds: (data: PracticeIdsPaginationDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateDtoIEnumerableApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimate/GetAllByPracticeIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimate
     * @name SalesEstimateApplyTemplatesToSalesEstimate
     * @request POST:/SalesEstimate/ApplyTemplatesToSalesEstimate
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateApplyTemplatesToSalesEstimate: (
      data: string[],
      query?: {
        /** @format uuid */
        salesEstimateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/SalesEstimate/ApplyTemplatesToSalesEstimate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  salesEstimateLineItem = {
    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetById
     * @request GET:/SalesEstimateLineItem/GetById
     * @secure
     * @response `200` `SalesEstimateLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItem/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetByIdWithRelatedEntities
     * @request GET:/SalesEstimateLineItem/GetByIdWithRelatedEntities
     * @secure
     * @response `200` `SalesEstimateLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemGetByIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItem/GetByIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetAllCount
     * @request GET:/SalesEstimateLineItem/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateLineItem/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemUpdate
     * @request PATCH:/SalesEstimateLineItem/Update
     * @secure
     * @response `200` `SalesEstimateLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemUpdate: (data: SalesEstimateLineItemDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItem/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemAdd
     * @request POST:/SalesEstimateLineItem/Add
     * @secure
     * @response `201` `SalesEstimateLineItemDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemAdd: (data: SalesEstimateLineItemDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateLineItem/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemDelete
     * @request DELETE:/SalesEstimateLineItem/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateLineItem/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetAll
     * @request POST:/SalesEstimateLineItem/GetAll
     * @secure
     * @response `200` `SalesEstimateLineItemDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateLineItem/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetAllStandardItemsByPracticeIdCount
     * @request GET:/SalesEstimateLineItem/GetAllStandardItemsByPracticeIdCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemGetAllStandardItemsByPracticeIdCount: (
      query?: {
        /** @format uuid */
        practiceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateLineItem/GetAllStandardItemsByPracticeIdCount`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetAllStandardItemsByPracticeId
     * @request POST:/SalesEstimateLineItem/GetAllStandardItemsByPracticeId
     * @secure
     * @response `200` `SalesEstimateLineItemDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemGetAllStandardItemsByPracticeId: (
      data: PracticeIdPaginationDtoApiRequestDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateLineItemDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateLineItem/GetAllStandardItemsByPracticeId`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItem
     * @name SalesEstimateLineItemGetAllStandardItems
     * @request POST:/SalesEstimateLineItem/GetAllStandardItems
     * @secure
     * @response `200` `SalesEstimateLineItemDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemGetAllStandardItems: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateLineItem/GetAllStandardItems`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  salesEstimateLineItemEffort = {
    /**
     * No description
     *
     * @tags SalesEstimateLineItemEffort
     * @name SalesEstimateLineItemEffortGetById
     * @request GET:/SalesEstimateLineItemEffort/GetById
     * @secure
     * @response `200` `SalesEstimateLineItemEffortDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemEffortGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateLineItemEffortDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItemEffort/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemEffort
     * @name SalesEstimateLineItemEffortGetAllCount
     * @request GET:/SalesEstimateLineItemEffort/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemEffortGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateLineItemEffort/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemEffort
     * @name SalesEstimateLineItemEffortUpdate
     * @request PATCH:/SalesEstimateLineItemEffort/Update
     * @secure
     * @response `200` `SalesEstimateLineItemEffortDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemEffortUpdate: (data: SalesEstimateLineItemEffortDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemEffortDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItemEffort/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemEffort
     * @name SalesEstimateLineItemEffortAdd
     * @request POST:/SalesEstimateLineItemEffort/Add
     * @secure
     * @response `201` `SalesEstimateLineItemEffortDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemEffortAdd: (data: SalesEstimateLineItemEffortDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateLineItemEffortDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateLineItemEffort/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemEffort
     * @name SalesEstimateLineItemEffortDelete
     * @request DELETE:/SalesEstimateLineItemEffort/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemEffortDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateLineItemEffort/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  salesEstimateLineItemTag = {
    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagGetById
     * @request GET:/SalesEstimateLineItemTag/GetById
     * @secure
     * @response `200` `SalesEstimateLineItemTagDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemTagGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateLineItemTagDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateLineItemTag/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagGetAllCount
     * @request GET:/SalesEstimateLineItemTag/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemTagGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateLineItemTag/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagGetAll
     * @request POST:/SalesEstimateLineItemTag/GetAll
     * @secure
     * @response `200` `SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemTagGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<
        SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateLineItemTag/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagGetAllByEntity1Id
     * @request GET:/SalesEstimateLineItemTag/GetAllByEntity1Id
     * @secure
     * @response `200` `SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemTagGetAllByEntity1Id: (
      query?: {
        /** @format uuid */
        entity1Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateLineItemTag/GetAllByEntity1Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagGetAllByEntity2Id
     * @request GET:/SalesEstimateLineItemTag/GetAllByEntity2Id
     * @secure
     * @response `200` `SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemTagGetAllByEntity2Id: (
      query?: {
        /** @format uuid */
        entity2Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        SalesEstimateLineItemTagDtoSalesEstimateLineItemDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateLineItemTag/GetAllByEntity2Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagAdd
     * @request POST:/SalesEstimateLineItemTag/Add
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateLineItemTagAdd: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/SalesEstimateLineItemTag/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateLineItemTag
     * @name SalesEstimateLineItemTagDelete
     * @request DELETE:/SalesEstimateLineItemTag/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateLineItemTagDelete: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateLineItemTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  salesEstimatePracticeAssignment = {
    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentGetById
     * @request GET:/SalesEstimatePracticeAssignment/GetById
     * @secure
     * @response `200` `SalesEstimatePracticeAssignmentDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimatePracticeAssignmentGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimatePracticeAssignmentDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimatePracticeAssignment/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentGetAllCount
     * @request GET:/SalesEstimatePracticeAssignment/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimatePracticeAssignmentGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimatePracticeAssignment/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentUpdate
     * @request PATCH:/SalesEstimatePracticeAssignment/Update
     * @secure
     * @response `200` `SalesEstimatePracticeAssignmentDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimatePracticeAssignmentUpdate: (data: SalesEstimatePracticeAssignmentDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimatePracticeAssignmentDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimatePracticeAssignment/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentAdd
     * @request POST:/SalesEstimatePracticeAssignment/Add
     * @secure
     * @response `201` `SalesEstimatePracticeAssignmentDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimatePracticeAssignmentAdd: (data: SalesEstimatePracticeAssignmentDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimatePracticeAssignmentDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimatePracticeAssignment/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentDelete
     * @request DELETE:/SalesEstimatePracticeAssignment/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimatePracticeAssignmentDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimatePracticeAssignment/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimatePracticeAssignment
     * @name SalesEstimatePracticeAssignmentGetAll
     * @request POST:/SalesEstimatePracticeAssignment/GetAll
     * @secure
     * @response `200` `SalesEstimatePracticeAssignmentDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimatePracticeAssignmentGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimatePracticeAssignmentDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimatePracticeAssignment/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  salesEstimateProductLineItem = {
    /**
     * No description
     *
     * @tags SalesEstimateProductLineItem
     * @name SalesEstimateProductLineItemGetById
     * @request GET:/SalesEstimateProductLineItem/GetById
     * @secure
     * @response `200` `SalesEstimateProductLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateProductLineItemGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateProductLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateProductLineItem/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateProductLineItem
     * @name SalesEstimateProductLineItemGetAllCount
     * @request GET:/SalesEstimateProductLineItem/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateProductLineItemGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateProductLineItem/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateProductLineItem
     * @name SalesEstimateProductLineItemUpdate
     * @request PATCH:/SalesEstimateProductLineItem/Update
     * @secure
     * @response `200` `SalesEstimateProductLineItemDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateProductLineItemUpdate: (data: SalesEstimateProductLineItemDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateProductLineItemDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateProductLineItem/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateProductLineItem
     * @name SalesEstimateProductLineItemAdd
     * @request POST:/SalesEstimateProductLineItem/Add
     * @secure
     * @response `201` `SalesEstimateProductLineItemDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateProductLineItemAdd: (data: SalesEstimateProductLineItemDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateProductLineItemDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateProductLineItem/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateProductLineItem
     * @name SalesEstimateProductLineItemDelete
     * @request DELETE:/SalesEstimateProductLineItem/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateProductLineItemDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateProductLineItem/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  salesEstimateSalesEstimateTemplate = {
    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateGetById
     * @request GET:/SalesEstimateSalesEstimateTemplate/GetById
     * @secure
     * @response `200` `SalesEstimateSalesEstimateTemplateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateSalesEstimateTemplateGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateSalesEstimateTemplateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateSalesEstimateTemplate/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateGetAllCount
     * @request GET:/SalesEstimateSalesEstimateTemplate/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateSalesEstimateTemplateGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateSalesEstimateTemplate/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateUpdate
     * @request PATCH:/SalesEstimateSalesEstimateTemplate/Update
     * @secure
     * @response `200` `SalesEstimateSalesEstimateTemplateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateSalesEstimateTemplateUpdate: (
      data: SalesEstimateSalesEstimateTemplateDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateSalesEstimateTemplateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateSalesEstimateTemplate/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateAdd
     * @request POST:/SalesEstimateSalesEstimateTemplate/Add
     * @secure
     * @response `201` `SalesEstimateSalesEstimateTemplateDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateSalesEstimateTemplateAdd: (data: SalesEstimateSalesEstimateTemplateDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateSalesEstimateTemplateDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateSalesEstimateTemplate/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateDelete
     * @request DELETE:/SalesEstimateSalesEstimateTemplate/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateSalesEstimateTemplateDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateSalesEstimateTemplate/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateSalesEstimateTemplate
     * @name SalesEstimateSalesEstimateTemplateGetAll
     * @request POST:/SalesEstimateSalesEstimateTemplate/GetAll
     * @secure
     * @response `200` `SalesEstimateSalesEstimateTemplateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateSalesEstimateTemplateGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateSalesEstimateTemplateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateSalesEstimateTemplate/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  salesEstimateTemplate = {
    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetById
     * @request GET:/SalesEstimateTemplate/GetById
     * @secure
     * @response `200` `SalesEstimateTemplateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplate/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetByIdWithRelatedEntities
     * @request GET:/SalesEstimateTemplate/GetByIdWithRelatedEntities
     * @secure
     * @response `200` `SalesEstimateTemplateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetByIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplate/GetByIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAllCount
     * @request GET:/SalesEstimateTemplate/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplateGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateTemplate/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateUpdate
     * @request PATCH:/SalesEstimateTemplate/Update
     * @secure
     * @response `200` `SalesEstimateTemplateDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateUpdate: (data: SalesEstimateTemplateDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplateDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplate/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateAdd
     * @request POST:/SalesEstimateTemplate/Add
     * @secure
     * @response `201` `SalesEstimateTemplateDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplateAdd: (data: SalesEstimateTemplateDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplateDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplate/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateDelete
     * @request DELETE:/SalesEstimateTemplate/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateTemplate/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAll
     * @request POST:/SalesEstimateTemplate/GetAll
     * @secure
     * @response `200` `SalesEstimateTemplateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplate/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAllCountByPracticeIds
     * @request POST:/SalesEstimateTemplate/GetAllCountByPracticeIds
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplateGetAllCountByPracticeIds: (data: string[], params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateTemplate/GetAllCountByPracticeIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAllByPracticeIds
     * @request POST:/SalesEstimateTemplate/GetAllByPracticeIds
     * @secure
     * @response `200` `SalesEstimateTemplateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetAllByPracticeIds: (data: PracticeIdsPaginationDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplate/GetAllByPracticeIds`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAllByPracticeIdWithRelatedEntities
     * @request GET:/SalesEstimateTemplate/GetAllByPracticeIdWithRelatedEntities
     * @secure
     * @response `200` `SalesEstimateTemplateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetAllByPracticeIdWithRelatedEntities: (
      query?: {
        /** @format uuid */
        practiceId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplate/GetAllByPracticeIdWithRelatedEntities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplate
     * @name SalesEstimateTemplateGetAllWithRelatedEntities
     * @request POST:/SalesEstimateTemplate/GetAllWithRelatedEntities
     * @secure
     * @response `200` `SalesEstimateTemplateDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateGetAllWithRelatedEntities: (
      data: PaginationOptionsModelApiRequestDto,
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplateDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplate/GetAllWithRelatedEntities`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  salesEstimateTemplatePractice = {
    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeGetById
     * @request GET:/SalesEstimateTemplatePractice/GetById
     * @secure
     * @response `200` `SalesEstimateTemplatePracticeDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplatePracticeGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplatePracticeDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplatePractice/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeGetAllCount
     * @request GET:/SalesEstimateTemplatePractice/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplatePracticeGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateTemplatePractice/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeUpdate
     * @request PATCH:/SalesEstimateTemplatePractice/Update
     * @secure
     * @response `200` `SalesEstimateTemplatePracticeDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplatePracticeUpdate: (data: SalesEstimateTemplatePracticeDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplatePracticeDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplatePractice/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeAdd
     * @request POST:/SalesEstimateTemplatePractice/Add
     * @secure
     * @response `201` `SalesEstimateTemplatePracticeDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplatePracticeAdd: (data: SalesEstimateTemplatePracticeDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplatePracticeDtoApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplatePractice/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeDelete
     * @request DELETE:/SalesEstimateTemplatePractice/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplatePracticeDelete: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateTemplatePractice/Delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplatePractice
     * @name SalesEstimateTemplatePracticeGetAll
     * @request POST:/SalesEstimateTemplatePractice/GetAll
     * @secure
     * @response `200` `SalesEstimateTemplatePracticeDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplatePracticeGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesEstimateTemplatePracticeDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesEstimateTemplatePractice/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  salesEstimateTemplateTag = {
    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagGetById
     * @request GET:/SalesEstimateTemplateTag/GetById
     * @secure
     * @response `200` `SalesEstimateTemplateTagDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateTagGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalesEstimateTemplateTagDtoApiResponseDto, void | ProblemDetails>({
        path: `/SalesEstimateTemplateTag/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagGetAllCount
     * @request GET:/SalesEstimateTemplateTag/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplateTagGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SalesEstimateTemplateTag/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagGetAll
     * @request POST:/SalesEstimateTemplateTag/GetAll
     * @secure
     * @response `200` `SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateTagGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<
        SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateTemplateTag/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagGetAllByEntity1Id
     * @request GET:/SalesEstimateTemplateTag/GetAllByEntity1Id
     * @secure
     * @response `200` `SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateTagGetAllByEntity1Id: (
      query?: {
        /** @format uuid */
        entity1Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateTemplateTag/GetAllByEntity1Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagGetAllByEntity2Id
     * @request GET:/SalesEstimateTemplateTag/GetAllByEntity2Id
     * @secure
     * @response `200` `SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateTagGetAllByEntity2Id: (
      query?: {
        /** @format uuid */
        entity2Id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        SalesEstimateTemplateTagDtoSalesEstimateTemplateDtoTagDtoManyToManyModelIEnumerableApiResponseDto,
        ProblemDetails | void
      >({
        path: `/SalesEstimateTemplateTag/GetAllByEntity2Id`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagAdd
     * @request POST:/SalesEstimateTemplateTag/Add
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    salesEstimateTemplateTagAdd: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, ProblemDetails | void>({
        path: `/SalesEstimateTemplateTag/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SalesEstimateTemplateTag
     * @name SalesEstimateTemplateTagDelete
     * @request DELETE:/SalesEstimateTemplateTag/Delete
     * @secure
     * @response `204` `void` No Content
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesEstimateTemplateTagDelete: (data: IntersectEntityModel, params: RequestParams = {}) =>
      this.http.request<void, void | ProblemDetails>({
        path: `/SalesEstimateTemplateTag/Delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  salesRegion = {
    /**
     * No description
     *
     * @tags SalesRegion
     * @name SalesRegionGetAll
     * @request POST:/SalesRegion/GetAll
     * @secure
     * @response `200` `SalesRegionDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    salesRegionGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SalesRegionDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SalesRegion/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  systemUser = {
    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetById
     * @request GET:/SystemUser/GetById
     * @secure
     * @response `200` `SystemUserDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SystemUserDtoApiResponseDto, void | ProblemDetails>({
        path: `/SystemUser/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetAllCount
     * @request GET:/SystemUser/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    systemUserGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SystemUser/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserUpdate
     * @request PATCH:/SystemUser/Update
     * @secure
     * @response `200` `SystemUserDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserUpdate: (data: SystemUserDto, params: RequestParams = {}) =>
      this.http.request<SystemUserDtoApiResponseDto, void | ProblemDetails>({
        path: `/SystemUser/Update`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetCurrentUserInformation
     * @request GET:/SystemUser/GetCurrentUserInformation
     * @secure
     * @response `200` `CurrentUserDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserGetCurrentUserInformation: (params: RequestParams = {}) =>
      this.http.request<CurrentUserDtoApiResponseDto, void | ProblemDetails>({
        path: `/SystemUser/GetCurrentUserInformation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetOrganizationInformation
     * @request GET:/SystemUser/GetOrganizationInformation
     * @secure
     * @response `200` `OrganizationInformationDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserGetOrganizationInformation: (params: RequestParams = {}) =>
      this.http.request<OrganizationInformationDtoApiResponseDto, void | ProblemDetails>({
        path: `/SystemUser/GetOrganizationInformation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetAll
     * @request POST:/SystemUser/GetAll
     * @secure
     * @response `200` `SystemUserDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SystemUserDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SystemUser/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetAllActiveUsers
     * @request POST:/SystemUser/GetAllActiveUsers
     * @secure
     * @response `200` `SystemUserDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    systemUserGetAllActiveUsers: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<SystemUserDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/SystemUser/GetAllActiveUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemUser
     * @name SystemUserGetAllActiveUsersCount
     * @request GET:/SystemUser/GetAllActiveUsersCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    systemUserGetAllActiveUsersCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/SystemUser/GetAllActiveUsersCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tag = {
    /**
     * No description
     *
     * @tags Tag
     * @name TagGetById
     * @request GET:/Tag/GetById
     * @secure
     * @response `200` `TagDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    tagGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<TagDtoApiResponseDto, void | ProblemDetails>({
        path: `/Tag/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name TagGetAllCount
     * @request GET:/Tag/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    tagGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/Tag/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tag
     * @name TagAdd
     * @request POST:/Tag/Add
     * @secure
     * @response `201` `TagDtoApiResponseDto` Created
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    tagAdd: (data: TagDto, params: RequestParams = {}) =>
      this.http.request<TagDtoApiResponseDto, ProblemDetails | void>({
        path: `/Tag/Add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  transactionCurrency = {
    /**
     * No description
     *
     * @tags TransactionCurrency
     * @name TransactionCurrencyGetById
     * @request GET:/TransactionCurrency/GetById
     * @secure
     * @response `200` `TransactionCurrencyDtoApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    transactionCurrencyGetById: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<TransactionCurrencyDtoApiResponseDto, void | ProblemDetails>({
        path: `/TransactionCurrency/GetById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TransactionCurrency
     * @name TransactionCurrencyGetAllCount
     * @request GET:/TransactionCurrency/GetAllCount
     * @secure
     * @response `200` `Int32ApiResponseDto` Success
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     */
    transactionCurrencyGetAllCount: (params: RequestParams = {}) =>
      this.http.request<Int32ApiResponseDto, void>({
        path: `/TransactionCurrency/GetAllCount`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TransactionCurrency
     * @name TransactionCurrencyGetAll
     * @request POST:/TransactionCurrency/GetAll
     * @secure
     * @response `200` `TransactionCurrencyDtoIEnumerableApiResponseDto` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `void` Unauthorized
     * @response `403` `void` Forbidden
     * @response `404` `ProblemDetails` Not Found
     */
    transactionCurrencyGetAll: (data: PaginationOptionsModelApiRequestDto, params: RequestParams = {}) =>
      this.http.request<TransactionCurrencyDtoIEnumerableApiResponseDto, ProblemDetails | void>({
        path: `/TransactionCurrency/GetAll`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
