// ==============================|| OVERRIDES - BUTTON ||============================== //

import { ComponentsOverrides, Theme } from "@mui/material";

export default function MuiToolbar(theme: Theme) {
    const styleOverrides: ComponentsOverrides<Theme>['MuiToolbar'] = {
        root: {
            backgroundColor: 'white',
            color: 'gray',
            boxShadow: '0px 1px 10px lightgray',
        },
    };

    return {
        MuiAppBar: {
            defaultProps: {
                // disableElevation: true
            },
            styleOverrides: styleOverrides
        }
    };
}
