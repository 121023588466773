import { alpha, styled } from '@mui/material/styles';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';


const ODD_OPACITY = 0.2;

export const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: '#F8F8F8',
        '&:hover, &.Mui-hovered': {
            backgroundColor: '#F8F8F8',
            '@media (hover: none)': {
                backgroundColor: '#F8F8F8',
            },
        },
        '&.Mui-selected': {
            backgroundColor: '#F8F8F8',
            '&:hover, &.Mui-hovered': {
                backgroundColor: '#F8F8F8',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: '#F8F8F8',
                },
            },
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'transparent',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'transparent',
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover, &.Mui-hovered': {
                backgroundColor: 'transparent',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
}));
