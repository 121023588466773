import { AzureDirectoryDataSource as AzureDirectoryDataSource } from "./AzureDirectoryDataSource";
import { AzureDirectoryTokenProvider } from "./AzureDirectoryTokenProvider";
import { HttpClient as SwaggerHttpClient, Api as SwaggerAPI, RequestParams } from "../swagger/API";

/**
 * Represents a datasource connecting to the .Net web api controllers
 */
export class NetDataSource extends AzureDirectoryDataSource {
    readonly swagger;

    async securityWorkerSwagger(securityData: any) {
        const source = securityData as NetDataSource;
        let token = await source.getToken(source.provider.getDefaultScopes());
        const params: RequestParams = {
            // baseUrl: `${source.provider.baseUrl}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=utf-8'
            }
        };

        return params;
    }

    constructor(provider: AzureDirectoryTokenProvider) {
        super(provider);

        const client = new SwaggerHttpClient({
            securityWorker: this.securityWorkerSwagger,
            baseUrl: provider.baseUrl,
        })

        client.setSecurityData(this);
        this.swagger = new SwaggerAPI(client);
    }

    protected async handleResponse(response: Response, parseResponse: boolean): Promise<any> {
        if (response.ok) {
            if (parseResponse) {
                return await response.json();
            }
        } else {
            let body: any = undefined;

            // reading as json reads the stream and its lost
            const responseClone = response.clone();

            if (!body)
                try {
                    body = await response.json();
                } catch { }

            if (!body)
                try {
                    const text = await responseClone.text();
                    body = new Error(text ?? `${response.status}/${response.statusText}`);
                } catch { }

            if (!body)
                try {
                    body = new Error('Failed to interpret response')
                } catch { }

            throw body;
        }
    }

    async execute(method: 'GET' | 'POST', url: string, body?: any): Promise<any> {
        let token = await this.getToken(this.provider.getDefaultScopes());
        let response = await fetch(`${this.provider.baseUrl}/${url}`, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        });

        return this.handleResponse(response, true);
    }

    async executeDurable(method: string, url: string, body?: any, headers?: any, progressCallback?: () => void): Promise<any> {

        function extractDurableHeaders(response: Response) {

            let durableInstanceId: string | null = null;
            let durableRetryAfterMS: number | null = 5000;

            for (const pair of response.headers.entries()) {
                if (pair[0] === "x-durable-instance-id")
                    durableInstanceId = pair[1];

                if (pair[0] === "x-durable-retry-after-ms")
                    durableRetryAfterMS = Number(pair[1]);
            }

            return {
                durableInstanceId,
                durableRetryAfterMS
            };
        }

        let token = await this.getToken(this.provider.getDefaultScopes());

        const requestHeaders: HeadersInit = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
        }

        Object.assign(requestHeaders, headers);

        let response = await fetch(`${this.provider.baseUrl}/${url}`, {
            method: method,
            headers: requestHeaders,
            body: JSON.stringify(body)
        });

        progressCallback?.();

        let { durableInstanceId, durableRetryAfterMS } = extractDurableHeaders(response);

        // ok, partial response
        while (response.ok && response.status === 202 && durableInstanceId) {

            await this.delay(durableRetryAfterMS);

            // lets wait until the time is right
            if (durableInstanceId)
                Object.assign(requestHeaders, { 'x-durable-instance-id': durableInstanceId });

            if (durableRetryAfterMS)
                Object.assign(requestHeaders, { 'x-durable-retry-after-ms': durableRetryAfterMS });

            response = await fetch(`${this.provider.baseUrl}/${url}`, {
                method: method,
                headers: requestHeaders,
                body: JSON.stringify(body)
            });

            let t = extractDurableHeaders(response);

            if (t.durableInstanceId)
                durableInstanceId = t.durableInstanceId;

            if (t.durableRetryAfterMS)
                durableRetryAfterMS = t.durableRetryAfterMS;

            progressCallback?.();

        }

        if (response.ok) {
            return await response.json();

        } else {
            let body = await response.json().catch((err) => {
                response.text().then((text) => {
                    return { error: { code: response.status, message: text } };
                }).catch((err) => {
                    return { error: { code: response.status, message: 'Failed to interpret response' } };
                });
            });
            throw body;
        }
    }

    async raw(method: string, url: string, body?: any): Promise<Response> {
        let token = await this.getToken(this.provider.getDefaultScopes());
        let response = await fetch(`${this.provider.baseUrl}/${url}`, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        });
        await this.handleResponse(response, false);
        return response;
    }

    private async delay(timeout: number) {
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, timeout);
        })
    }
}