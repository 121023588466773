import { DataSource } from "data/DataSource";
import { NotificationDto } from "data/swagger/API";

export async function fetchNotifications(datasource: DataSource, userId: string) {
    return await datasource.webApi.swagger.notification.notificationGetUnreadBySystemUserId({
        systemUserId: userId
    })
}

export async function markAllAsRead(datasource: DataSource, notifications: NotificationDto[]) {
    return await datasource.webApi.swagger.notification.notificationMarkAllAsRead(
        notifications?.map(notification => notification.id!)
    )
}