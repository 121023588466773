import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MuiButton from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';

import ModalNotification from '../../../../../components/Notification/ModalNotification';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 415,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const practiceName: string = 'Dev-Tech';

const ModalApproveEstimate = () => {
    const successAlertColor: any = 'success';
    const errorAlertColor: any = 'error';
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState(successAlertColor);
    const [disableApprovalButton, setDisableApprovalButton] = useState(false);
    const [approvalResultMessage, setapprovalResultMessage] = useState(
        `Estimate approved for the ${practiceName} estimation team.`
    );

    const update = (): void => {
        setDisableApprovalButton(true);
        //Call WebAPI
        //for now mocking approval results
        let approvalSucceed: boolean = true;//Math.random() < 0.8; //generating a random boolean
        if (approvalSucceed) {
            setapprovalResultMessage(
                `Estimate approved for the ${practiceName} estimation team.`
            );
            setAlertSeverity(successAlertColor);
        } else {
            setapprovalResultMessage(
                `There was an issue with the approval for the ${practiceName} estimation team.`
            );
            setAlertSeverity(errorAlertColor);
        }
        setNotificationOpen(true);
    };

    return (
        <Box>
            <header>
                <AppBar position="static">
                    <Toolbar>
                        Are you sure you want to approve this estimate for {practiceName}?{' '}
                    </Toolbar>
                </AppBar>
            </header>
            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Customer Account</TableCell>
                                <TableCell align="left">Opportunity Name</TableCell>
                                <TableCell align="left">Practice</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">Walgreens</TableCell>
                                <TableCell align="left">RXR Development</TableCell>
                                <TableCell align="left">{practiceName}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <ModalNotification isOpen={notificationOpen} message={approvalResultMessage} alertSeverity={alertSeverity} />
            <footer>
                <br />
                <MuiButton variant="contained" sx={{ float: 'right' }} onClick={update} disabled={disableApprovalButton}>
                    Confirm Approval
                </MuiButton>
            </footer>
        </Box>
    );
};

export default ModalApproveEstimate;