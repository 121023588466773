import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import { isDevelopment } from 'config';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { EStatusActionType } from 'store/reducers/StatusReducer';
import { useApplication } from '../ApplicationContextProvider/ApplicationContextProvider';

const drawerWidth = 240;


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Sidebar = () => {
  const theme = useTheme();

  const application = useApplication();

  const [status, dispatchStatus] = application.statusReducer;

  const data = React.useMemo(() => {

    const data = [
      {
        route: 'dashboard',
        name: 'Dashboard',
      },
      {
        route: 'practices',
        name: 'Practices',
      },
    ];



    if (isDevelopment())
      data.push({
        route: 'stencil',
        name: 'Stencil',
      })

    if (isDevelopment())
      data.push({
        route: 'diagnosis',
        name: 'Diagnosis',
      })

    if (isDevelopment())
      data.push({
        route: 'devpage',
        name: 'DevPage',
      })

      return data;

  }, []);

  const navigate = useNavigate();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="temporary"
      anchor="left"
      open={status.sidebarOpen}
    >
      <DrawerHeader>
        <IconButton onClick={() => dispatchStatus({
          type: EStatusActionType.Status_sidebar_open_toggle
        })}
        >
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {data.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(item.route);
                dispatchStatus({
                  type: EStatusActionType.Status_sidebar_open_toggle
                });
              }}
            >
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
};

export default Sidebar;
