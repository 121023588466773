import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements
} from 'react-router-dom';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { lazy } from 'react';
import EstimateDetail from 'views/Estimates/EstimateDetail';
import './App.css';
import ApplicationContextProvider from './components/ApplicationContextProvider';
import WithSuspense, { SimulateWithSuspense } from './components/WithSuspense';
import Root from './layouts/Master';
import ThemeCustomization from './themes';
import DevPage from './views/DevPage';
import Diagnosis from './views/Diagnosis';
import Error from './views/Error';
import Stencil from './views/Stencil';
import { LicenseInfo } from '@mui/x-license-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { reactPlugin } from 'config';


//https://mui.com/x/introduction/licensing/#license-key-installation
LicenseInfo.setLicenseKey('a63d867587bc2f4539a09816abba2742Tz01ODc4NSxFPTE3MDY3MzI2NDU2MjMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const DashboardLazy = lazy(() => (import('./views/Dashboard')));
const Dashboard = WithSuspense(DashboardLazy);

const PracticeLazy = lazy(() => (import('./views/Practices/Practice').then(module => ({ default: module.Practice }))));
const Practice = WithSuspense(PracticeLazy);

const PracticesLazy = lazy(() => (import('./views/Practices').then(module => ({ default: module.Practices }))));
const Practices = WithSuspense(PracticesLazy);

const EstimatesLazy = lazy(() => (import('./views/Estimates/Estimates')));
const Estimates = WithSuspense(EstimatesLazy);

const EstimatesCommentsLazy = lazy(() => (import('./views/Estimates/EstimateComments')));
const EstimatesComments = WithSuspense(EstimatesCommentsLazy);

const EstimateLineItemsLazy = lazy(() => (import('./views/Estimates/EstimateLineItems')));
const EstimateLineItems = WithSuspense(EstimateLineItemsLazy);

const EstimateInvestmentSummaryLazy = lazy(() => (import('./views/Estimates/EstimateInvestmentSummary')));
const EstimateInvestmentSummary = WithSuspense(EstimateInvestmentSummaryLazy);

const EstimateProjectConfigurationLazy = lazy(() => (import('./views/Estimates/EstimateProjectConfiguration')));
const EstimateProjectConfiguration = WithSuspense(EstimateProjectConfigurationLazy);

const EstimateResourceAllocationLazy = lazy(() => (import('./views/Estimates/EstimateResourceAllocation')));
const EstimateResourceAllocation = WithSuspense(EstimateResourceAllocationLazy);

const EstimateOutputsLazy = lazy(() => (import('./views/Estimates/EstimateOutputs')));
const EstimateOutputs = WithSuspense(EstimateOutputsLazy);

const ResourcePlansLazy = lazy(() => (import('./views/Estimates/ResourcePlans')));
const ResourcePlans = WithSuspense(ResourcePlansLazy);

const ResourcePlanLazy = lazy(() => (import('./views/Estimates/ResourcePlans/ResourcePlan')));
const ResourcePlan = WithSuspense(ResourcePlanLazy);

const ResourcePlansListLazy = lazy(() => (import('./views/Estimates/ResourcePlans/ResourcePlanList')));
const ResourcePlansList = WithSuspense(ResourcePlansListLazy);

const TemplateLazy = lazy(() => (import('./views/Templates/Template')));
const Template = WithSuspense(TemplateLazy);

const NewTemplateLazy = lazy(() => (import('./views/Templates/Template')));
const NewTemplate = WithSuspense(TemplateLazy);

const TemplatesLazy = lazy(() => (import('./views/Templates')));
const Templates = WithSuspense(TemplatesLazy);

function App() {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route
				path="/"
				element={<Root />}
				errorElement={<Error />}
			>
				<Route
					index
					path=''
					element={<Dashboard />}
				/>
				<Route
					index
					path='dashboard'
					element={<Dashboard />}
				/>

				<Route
					path='estimates/:estimateId'
					element={<Estimates />}
				>
					<Route
						index
						path=""
						element={<EstimateDetail />}
					/>
					<Route
						index
						path="comments"
						element={<EstimatesComments />}
					/>
					<Route
						index
						path="investmentsummary"
						element={<EstimateInvestmentSummary />}
					/>
					<Route
						path="resourceallocation"
						element={<EstimateResourceAllocation />}
					/>
					<Route
						path="projectconfiguration"
						element={<EstimateProjectConfiguration />}
					/>
					<Route
						path="details"
						element={<EstimateDetail />}
					/>
					<Route
						path="lines"
						element={<EstimateLineItems />}
					/>
					<Route
						path="resourceplans"
						element={<ResourcePlans />}
					>
						<Route
							index
							path=""
							element={<ResourcePlansList />}
						/>
						<Route
							index
							path=":resourcePlanId"
							element={<ResourcePlan />}
						/>
					</Route>
					<Route
						path="outputs"
						element={<EstimateOutputs />}
					/>
				</Route>

				<Route
					path='/stencil'
					element={<Stencil />}
				/>
				<Route
					index
					path='/practices/:practiceId'
					element={<Practice />}
				/>
				<Route
					index
					path='/practices'
					element={<Practices />}
				/>
				<Route
					index
					path='/templates/:templateId'
					element={<Template />}
				/>
				<Route
					index
					path='/templates'
					element={<Templates />}
				/>
				<Route
					index
					path='/templates/new-template'
					element={<NewTemplate isNewTemplate={true} />}
				/>

				<Route
					path='/diagnosis'
					element={<Diagnosis />}
				/>
				<Route
					path='/devpage'
					element={<DevPage />} />

			</Route>
		)
	);

	return (
		<ThemeCustomization>
			<ApplicationContextProvider>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<RouterProvider router={router} />
				</LocalizationProvider >
			</ApplicationContextProvider>
		</ThemeCustomization>
	);
}

export default withAITracking(reactPlugin, App);
