import { LinearProgress, Typography } from "@mui/material";

export const Loader = () => (
    <div style={{ padding: '0px' }}>
        <Typography variant='overline'>
            Loading Component
        </Typography>
        <LinearProgress color="secondary" />
    </div>
);

