import { Theme } from "@mui/material";

export default function LinearProgress(theme: Theme) {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: 6,
                    borderRadius: 100
                },
                bar: {
                    borderRadius: 100
                }
            }
        }
    };
}
