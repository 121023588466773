import { Typography } from "@mui/material";
import { useMemo } from "react";
import { IStepperProps } from "./IStepperProps";
import { EStepStatus } from "./EStepStatus";


const Stepper: React.FunctionComponent<IStepperProps> = (props) => {

    const { styleActive, styleInactive } = useMemo(() => {

        const styleActive: React.CSSProperties = {
            padding: '5px 30px 5px 30px',
            border: 'thin solid gray',
            backgroundColor: 'white',
            borderRadius: '35px',
            minWidth: '180px',
            textAlign: 'center',
        };

        const styleInactive: React.CSSProperties = {
            ...styleActive,
            border: 'thin solid #DDDDDD',

        };

        return { styleActive, styleInactive };
    }, [])

    return <div style={{ display: 'flex', ...props.styles?.root }}>
        {props.steps.map((eachStep, ix) => {

            const step = <div key={eachStep.id ?? ix} style={eachStep.status === EStepStatus.disabled
                ? styleInactive
                : styleActive}
            >
                <Typography
                    variant="caption"
                    sx={{ color: eachStep.status === EStepStatus.disabled ? '#CCCCCC' : '#000000' }}
                >
                    {eachStep.label}
                </Typography>
            </div>

            if (ix > 0)
                return <div key={`link_${eachStep.id ?? ix}`} style={{ display: 'inline-flex' }}>
                    <div
                        id={`link_${eachStep.id}`}

                        style={{
                            width: '20px',
                            height: '2px',
                            border: 'thin solid #DDDDDD',
                            alignSelf: 'center',
                        }}
                    />
                    {step}
                </div>
            else
                return step;
        })}
    </div>
}
export default Stepper;