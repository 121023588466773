import { InputAdornment, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { EEstimateActionType } from "store/reducers/EstimateReducer";
import { isValidDollarAmount } from "views/Estimates/EstimateResourceAllocation/ResourceAllocation";
import useSalesEstimate from "views/Estimates/EstimatesHooks/useSalesEstimate";

export const isNumberOrComma = (value: string) => {
    const numberRegex = new RegExp(/^[\d\,]+$/gm);
    // const dollarAmountRegex = new RegExp(/^\d+$/gm);
    return numberRegex.test(value) || value.length === 0
}


export const QualifiedBudgetInput = (props: { disabled: boolean, onChange: Function }) => {
    const salesEstimateReducer = useSalesEstimate()
    const { stateEstimate, dispatchEstimate, isAPILoading, error } = salesEstimateReducer
    const [isFocused, setIsFocused] = useState(false);
    const [qualifiedBudget, setQualifiedBudget] = useState<string>(stateEstimate.estimate.hsl_qualifiedbudget?.value?.toLocaleString('en-US') || "0");


    return (
        <>
            <TextField
                disabled={props.disabled}
                value={qualifiedBudget || '0'}
                variant="standard"
                sx={{
                    width: '80%',
                }}
                inputProps={{
                    style: {
                        fontSize: 14,
                        paddingLeft: 0,
                    },
                }}
                InputProps={{
                    startAdornment: <InputAdornment sx={{ padding: '0px' }} position="start">$</InputAdornment>,
                }}
                onChange={(event) => {
                    // setShowCostError(false)
                    const value = Number(event.target.value.replaceAll(',', ''))?.toLocaleString()
                    if (isNumberOrComma(value)) {
                        props.onChange()
                        setQualifiedBudget(value)
                    }
                }}
                onBlur={(event) => {
                    // setShowCostError(false)
                    const value = event.target.value.replaceAll(',', '')
                    if (isNumberOrComma(value)) {
                        dispatchEstimate({
                            type: EEstimateActionType.estimate_update,
                            estimate: {
                                hsl_qualifiedbudget: {
                                    value: Number(value)
                                }
                            }
                        })
                    }
                    setIsFocused(false)
                }
                }
            />
        </>
    )
}