import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, Snippet } from "@microsoft/applicationinsights-web";
import _ from "lodash";

// TODO rename the file to ApplicationInfrastructure
interface IConfig {
    webApp: {
        name: string;
        environmentName: "Development" | "Test" | "UAT" | "Production"
        storageKeys: {
            identity: string,
            options: string,
            practices: string,
            practiceRoles: string,
            crmUsers: string
        }
        baseUrl: string;
        appRegistrationSystemUserId: string;
    },
    webApi: {
        baseUrl: string,
        scopes: string[],
    },
    dynamics: {
        baseUrl: string,
        scopes: string[],
        version: string,
    },
    msGraph: {
        baseUrl: string,
        scopes: string[],
    },
    applicationInsights: {
        connectionString: string;
    },
    msal: Configuration,
}

const defaultConfig = () => {
    const config: IConfig = {
        webApp: {
            name: 'Estimator WebApp Non-prod',
            environmentName: 'Development',
            storageKeys: {
                identity: 'estimator-identity',
                options: 'estimator-options',
                practices: 'estimator-practices',
                practiceRoles: 'estimator-practice-roles',
                crmUsers: 'estimator-crm-users'
            },
            appRegistrationSystemUserId: 'ebf0ec1c-4740-ee11-bdf3-00224827bd9c',
            baseUrl: 'https://localhost:44450',
        },
        webApi: {
            baseUrl: 'https://localhost:7284',
            scopes: ['api://89b275c7-1083-49a7-b96d-3394530bfba9/user'],
        },
        dynamics: {
            baseUrl: 'https://hsdev.crm.dynamics.com',
            scopes: ['https://hsdev.crm.dynamics.com/user_impersonation'],
            version: '9.1',
        },
        msGraph: {
            baseUrl: 'https://graph.microsoft.com/v1.0',
            scopes: ['https://graph.microsoft.com/User.Read', 'https://graph.microsoft.com/User.ReadBasic.All'],
        },
        applicationInsights: {
            connectionString: ''
        },
        msal: {
            auth: {
                clientId: "b6faad4a-599b-4dae-afed-5e94c3ad21c4",
                redirectUri: "https://localhost:44450/",
                authority: "https://login.microsoftonline.com/e85feadf-11e7-47bb-a160-43b98dcc96f1",
            },
            cache: {
                // localStorage or sessionStorage - choose localStorage to make sure new tabs are pre-authed
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false,
            },
        },
    }

    return config;
}

const defaultApplicationInsightSnippet = () => {
    const appInsightInit: Snippet = {
        config: {

        }
    }
    return appInsightInit;
}

const ApplicationInfrastructure = {
    config: defaultConfig(),

    isDevelopment: () => {
        return ApplicationInfrastructure.config.webApp.environmentName === "Development";
    },

    applicationInsights: null as null | ApplicationInsights,
    reactPlugin: new ReactPlugin(),
    msalInstance: null as null | PublicClientApplication,

    initialize: async () => {
        await ApplicationInfrastructure.addWebAppSettingsAsync();
        ApplicationInfrastructure.addApplicationInsights();
        ApplicationInfrastructure.addMsal();
        return ApplicationInfrastructure;
    },

    /**
     * Updates the static instance of Config module to latest configuration provided by web server
     * @returns Promise of latest config
     */
    addWebAppSettingsAsync: async () => {
        const configFetch = await fetch('/api/config');
        if (configFetch.ok) {
            const config = await configFetch.json();
            _.merge(ApplicationInfrastructure.config, config)
            console.log('Configuration is ready.', ApplicationInfrastructure.config, config);
            return ApplicationInfrastructure;
        } else {
            const configText = await configFetch.text();
            throw new Error(configText);
        }
    },

    addApplicationInsights: () => {
        // AppInsights.ts
        // for setup and configuration see https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
        // https://github.com/microsoft/applicationinsights-react-js


        // TODO latest application insight currently has a typing issue, updater after May
        // https://github.com/microsoft/applicationinsights-react-js/issues/32
        const { reactPlugin, config } = ApplicationInfrastructure;

        if (!config.applicationInsights.connectionString)
            throw new Error("Connection string missing from configuration.");

        ApplicationInfrastructure.applicationInsights = applicationInsights = new ApplicationInsights({
            config: {
                connectionString: ApplicationInfrastructure.config.applicationInsights.connectionString,
                extensions: [reactPlugin],
                // appId: ApplicationInfrastructure.config.webApp.,
                extensionConfig: {
                    [reactPlugin.identifier]: { history: history }
                }
            }
        })

        // prevent certain telemetry from being sent by returning false
        ApplicationInfrastructure.applicationInsights.addTelemetryInitializer((item) => {
            // ResizeObserver loop completed with undelivered notifications.
            // ResizeObserver loop limit exceeded
            if (item?.data?.message?.includes('ResizeObserver loop'))
                return false;

            return true;
        });

        ApplicationInfrastructure.applicationInsights?.loadAppInsights();
        console.log('Application insights is ready.', ApplicationInfrastructure.config);

        return ApplicationInfrastructure;
    },

    addMsal: () => {
        const { config } = ApplicationInfrastructure;
        ApplicationInfrastructure.msalInstance = msalInstance = new PublicClientApplication(config.msal);
    }
}

let Config = ApplicationInfrastructure.config;
let reactPlugin = ApplicationInfrastructure.reactPlugin;
let applicationInsights = ApplicationInfrastructure.applicationInsights!;
let msalInstance = ApplicationInfrastructure.msalInstance!;

const isDevelopment = ApplicationInfrastructure.isDevelopment

export { Config, isDevelopment, reactPlugin, applicationInsights, msalInstance };
export default ApplicationInfrastructure;