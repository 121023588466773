import { IApplicationContext } from "./IApplicationContext.type";

export const ApplicationContextInitialState = () => {
    const ret: IApplicationContext = {
        statusReducer: [undefined, undefined] as any,
        dataSource: null as any,
        identity: null,
        ready: false,
        sessionState: {
            cacheReady: false,
            canLoad: false,
            dataSourceReady: false,
            identityReady: false,
            userListReady: false,
        },
        cache: {
            options: {} as any,
            practices: [],
            practiceRoles: [],
            practiceEstimators: [],
            practiceLeaders: [],
            crmUsers: [],
            systemUsersBySystemUserId: {},
            reset: () => { },
        }
    };

    return ret;
};
