import { useEffect, useState } from "react";

export const useIsTruncated = (element: any, isVisible: boolean) => {

    const determineIsTruncated = () => {
        if (!element.current) return false;
        return element.current.scrollWidth > element.current.clientWidth;
    };

    const [isTruncated, setIsTruncated] = useState(determineIsTruncated());
    
    useEffect(() => {
        if (!element.current)
            setIsTruncated(
                false,
            );
        setIsTruncated(
            element.current.scrollHeight > element.current.clientHeight,
        );
    }, [element.current, isVisible]);


    useEffect(() => {
        const resizeListener = () => setIsTruncated(determineIsTruncated());
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);
    return isTruncated;
};