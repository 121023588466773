export enum EStatusActionType {
    Status_loading,
    Status_loading_update,
    Status_loading_failed,
    Status_sidebar_open_toggle,
}

export type TStatusAction =
    | { type: EStatusActionType.Status_loading }
    | { type: EStatusActionType.Status_sidebar_open_toggle }
    | { type: EStatusActionType.Status_loading_update, results: string }
    | { type: EStatusActionType.Status_loading_failed, error: string };

export interface IStatusState {
    loading: boolean;
    sidebarOpen: boolean;
}

export const StatusInitialState = () => {
    const ret: IStatusState = {
        loading: false,
        sidebarOpen: false,
    };

    return ret;
};

export const StatusReducer = (xState: IStatusState, action: TStatusAction): IStatusState => {
    switch (action.type) {
        case EStatusActionType.Status_loading:
            return {
                ...xState,
            };

        case EStatusActionType.Status_loading_update:
            return {
                ...xState,
                loading: true,
            };

        case EStatusActionType.Status_loading_failed:
            return {
                ...xState,
                loading: false,
            };

        case EStatusActionType.Status_sidebar_open_toggle:
            return {
                ...xState,
                sidebarOpen: !xState.sidebarOpen
            };

        default:
            return xState;
    }
};