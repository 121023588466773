const Typography = () => ({
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontFamily: '"Segoe UI", sans-serif',

    h1: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    h2: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    h3: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    h4: {
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    h5: {
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    h6: {
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '16px',
    },
    body1: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
    },
    body2: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
    },
    subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1.57
    },
    subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.66
    },
    overline: {
        lineHeight: 1.66
    },
    button: {
        textTransform: 'capitalize' as any
    }
});

export default Typography;
