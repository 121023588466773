import { useEffect, useState } from "react";
import Mock from "../../store/mock";
import DataGrid from "../../components/DataGrid/DataGrid";


const Diagnosis: React.FunctionComponent = () => {

    const [viewModel, setViewModel] = useState({ loading: false });

    const [data, setData] = useState<any | null>()

    useEffect(() => {
        (async () => {
            setViewModel(t => { return { ...t, loading: true } });
            const data = await Mock.PracticeGetAll();
            setData(data);
            setViewModel(t => { return { ...t, loading: false } });

        })();

    }, []);

    return <div>
        {viewModel.loading &&
            <div>
                Loading...
            </div>
        }
        <DataGrid
            data={data}
        />
    </div>
}
export default Diagnosis;