import { Config } from "config";
import { AzureDirectoryDataSource as AzureDirectoryDataSource } from "./AzureDirectoryDataSource";
import { AzureDirectoryTokenProvider } from "./AzureDirectoryTokenProvider";

export class ApiError extends Error {
    status: number = -1;
    code?: string;
    detail?: string;

    constructor(
        status: number,
        message: string,
        code?: string,
        detail?: string) {
        super(message);
        this.status = status;
        this.code = code;
        this.detail = detail;
    }
}

export interface IApiOptions {
    includeAnnotations?: boolean;
    returnRecord?: boolean;
    version?: string;
    pageSize?: number;
    body?: any;
    additionalHeaders?: any;
    noAccept?: boolean
}


/**
 * Represents a datasource connecting to the .Net web api controllers
 */
export class XrmDataSource extends AzureDirectoryDataSource {

    private readonly baseUrl: string;
    private readonly version: string;

    constructor(provider: AzureDirectoryTokenProvider) {
        super(provider);
        this.baseUrl = Config.dynamics.baseUrl;
        this.version = Config.dynamics.version;
    }

    protected async handleResponse(response: Response, parseResponse: boolean): Promise<any> {
        if (response.ok) {
            if (parseResponse) {
                try {
                    return await response.json();
                } catch (err) {
                    throw new Error(`Failed to parse response: ${err}`);
                }
            }
        } else if (
            response.status === 400 ||
            response.status === 412) {
            // WebAPI returns a JSON body on 400 error (most of the time)
            let body = await response.json().catch((err) => {
                response.text().then((text) => {
                    return { error: { code: -1, message: text } };
                }).catch((err) => {
                    return { error: { code: -1, message: 'Failed to interpret response' } };
                });
            });
            throw new ApiError(
                response.status,
                `${response.status} ${body.error.code} ${body.error.message}`,
                body.error.code,
                body.error.message);
        } else {
            let text = await response.text().catch((err) => {
                return 'Failed to interpret response';
            });
            throw new ApiError(
                response.status,
                `${response.status} ${response.statusText} ${text}`);
        }
    }

    async execute(method: string, url: string, options?: IApiOptions): Promise<any> {
        let uri = `${this.baseUrl}/api/data/v${this.version}/${url}`;
        let response = await this.send(method, uri, options);
        return await this.handleResponse(response, true);
    }


    protected async send(method: string, uri: string, options?: IApiOptions, scopes?: string[]): Promise<Response> {
        let token = await this.getToken(this.provider.getDefaultScopes());

        // build the prefer header
        let prefer: string[] = [];

        // get formatted values
        if (options?.includeAnnotations)
            prefer.push(`odata.include-annotations="*"`);

        // for POST/PUT return the new record
        if (options?.returnRecord)
            prefer.push(`return=representation`);

        // for paging, set the page size
        if (options?.pageSize) {
            prefer.push(`odata.maxpagesize=${options.pageSize}`);
        }

        // build the request
        let request: any = {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Prefer': prefer.join(', '),
                ...(options?.additionalHeaders || {})
            }
        };

        if (options?.noAccept)
            delete request.headers['Accept']

        // set the body if given
        if (options?.body) {
            request.body = options?.body;
        }

        return await fetch(uri, request);
    }

    async whoAmI(): Promise<any> {
        return await this.execute('GET', 'WhoAmI');
    }

}