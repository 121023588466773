import { Box, Divider, FormControlLabel, Grid, List, ListItem, ListItemText, Modal, Paper, Switch, Typography, styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import React, { useCallback, useMemo, useState } from 'react';

import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import { ITeam } from 'models/ITeam';
import { IModalEstimateTeamProps } from './IModalEstimateTeamProps';
import * as ISwaggerModel from 'data/swagger/API'


interface IData {
	estimatePractice: ITeam | null,
	referencePractice: ISwaggerModel.PracticeDto;
}

interface IViewModel {
	data: IData[];
}


// TODO unify
const styleModalPopup = {
	position: 'absolute' as 'absolute',
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '75%',

	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

// TODO figure out ui 
const ModalEstimationTeam: React.FunctionComponent<IModalEstimateTeamProps> = (props) => {
	const application = useApplication();
	const createDefaultState = useMemo(() => {
		return {
			data: application.cache.practices.map(eReferencePractice => {
				const estimatePractice = props.defaultValue?.find(q => q.hsl_practiceid?.id === eReferencePractice.id);
				const t: IData = {
					referencePractice: eReferencePractice,
					estimatePractice: estimatePractice ?? null
				};

				return t;
			})
		}
	}, [application.cache.practices, props.defaultValue])

	const [viewModel, setViewModel] = useState<IViewModel>(createDefaultState);

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));

	const onChange = () => {
		const changes = viewModel.data
			.filter(q => q.estimatePractice)
			.map(q => q.estimatePractice!) ?? [];

		props.onChange?.(changes);
	}

	const onRenderBody = () => {

		let label = (practiceName: string) => {
			return <div>
				<Typography>{practiceName}</Typography>
				{/* <div style={{ position: 'relative', width: '0', height: '0' }}>
					<Typography variant='caption' color={'GrayText'} >{status}</Typography>
				</div> */}
			</div>
		}

		return (
			<Box>
				<Typography variant='h3'>Edit Estimation Team</Typography>
				<List sx={{ bgcolor: 'background.paper' }}>
					<div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto' }} >
						{viewModel.data.map((thisData, IX) => (
							<div key={thisData.referencePractice.id}>
								<FormControlLabel
									sx={{ minHeight: '55px' }}
									label={label(thisData.referencePractice.hsl_name ?? '')}
									control={
										<Switch
											checked={!!thisData.estimatePractice}
											onChange={(_, checked) => {
												setViewModel((xViewModel) => {
													const nViewModel: IViewModel = {
														...xViewModel,
														data: xViewModel.data.map((eachData) => {
															if (eachData.referencePractice.id === thisData.referencePractice.id) {
																// TODO probably don't create if already pending a status
																const estimatePracticeChecked: ITeam = {
																	id: '',
																	hsl_practiceid: { id: eachData.referencePractice.id! },
																	hsl_name: eachData.referencePractice.hsl_name!,
																	ownerId: { id: eachData.referencePractice.ownerId?.id, name: eachData.referencePractice.ownerId?.name }
																}
																// if brand new & adding
																if (checked && eachData?.estimatePractice === null) //&& !eachPractice.estimatePractice)
																	return {
																		...eachData,
																		estimatePractice: estimatePracticeChecked,
																	}
																// if brand new & removing
																if (!checked && eachData?.estimatePractice?.id === "")
																	return {
																		...eachData,
																		estimatePractice: null,
																	}
																// if existing and has line efforts do nothing
																if (!checked && eachData?.estimatePractice?.hasLineItemEffort === true) //&& !eachPractice.estimatePractice)
																	return {
																		...eachData,
																	}
																// if existing removing
																if (!checked && eachData?.estimatePractice?.hasLineItemEffort === false) //&& !eachPractice.estimatePractice)
																	return {
																		...eachData,
																		estimatePractice: null,
																	}
															}
															return eachData;
														})
													};

													return nViewModel;
												});
											}}
										/>
									}
								/>


							</div>
						))}
					</div>
				</List>

			</Box >
		);
	}

	const onRenderFooter = () => {
		return <footer>
			<Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: '120px auto 120px', marginTop: '20px' }}>
				<MuiButton
					variant="outlined"
					color='secondary'
					onClick={() => {
						props.onClose();
					}}
				>
					Cancel
				</MuiButton>
				<div></div>
				<MuiButton
					variant="contained"
					onClick={onChange}
				>
					Update
				</MuiButton>
			</Box>
		</footer>
	}

	return (
		<Modal
			open={props.isOpen}
			onClose={() => { props.onClose(); }}
		>
			<Box sx={styleModalPopup}>
				{onRenderBody()}
				<Divider />
				{onRenderFooter()}
			</Box>
		</Modal>
	);

};

export default ModalEstimationTeam;
