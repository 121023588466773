import { Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar";
import Typography from "@mui/material/Typography";

export default function Master() {

    return <>
        <Navbar />
        <Sidebar />
        <Outlet />
        <center>
            <div style={{ position: 'fixed', bottom: '0px', left: '0px' }}>
                <center>
                    <Typography variant="caption">
                        Version {process.env.REACT_APP_VERSION}
                    </Typography>
                </center>
            </div>
        </center>
    </>
}
