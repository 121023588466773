import { Suspense } from "react";
import { Loader } from "./Loader";

const WithSuspense = (Component: any) => (props: any) =>
(
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default WithSuspense;
