import { Theme } from "@mui/material";

export default function Tabs(theme: Theme) {
    return {
        MuiTabs: {
			styleOverrides: {

            }
        }
    };
}
