import { useApplication } from "components/ApplicationContextProvider/ApplicationContextProvider";
import { SalesEstimateLineItemDto, LinkDto, ResourceAllocationDto, InvestmentSummaryDto, SalesEstimateProductLineItemDto } from "data/swagger/API";
import { IResourcePlan, IResourcePlanResource, IResourcePlanEntry } from "models/IResourcePlan";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { EEstimateActionType, IEstimateState, TEstimateAction, TEstimateStore } from "store/reducers/EstimateReducer";
import { saveSalesEstimateResourcePlan, saveSalesEstimateSelectedResourcePlan, addSalesEstimateProductLineItem, addSalesEstimateResourcePlan, applySalesEstimateTemplates, deleteSalesEstimateProductLineItem, fetchSalesEstimate, generateSalesEstimateResourceAllocation, updateSalesEstimateInvestmentSummary, updateSalesEstimateLinks, updateSalesEstimatePracticeAssignment, updateSalesEstimateProductLineItem, updateSalesEstimateProjectAssignment, updateSalesEstimateProjectConfiguration, updateSalesEstimateResourceAllocation, generateSalesEstimateSOW, generateSalesEstimateWBS, getSalesEstimateComments, addSalesEstimateComment, notifyUser } from "./dataLayer";
import { ITeam } from "models/ITeam";
import { IEstimate } from "models/IEstimate";
import * as Swagger from 'data/swagger/API'
import { ProductLineItem } from "../EstimateLineItems/ProductItems/ProductItemGrid";

export enum EEstimateAPIActionType {
    fetch,
    estimate_projectconfiguration_update,
    estimate_links_change,
    estimate_projectassignment_change,
    estimate_resource_allocation_update,
    estimate_save_regenerate_investment_summary,
    estimate_add_resourceplan,
    estimate_apply_templates,
    estimate_resource_allocation_generate,
    estimate_add_product_line_item,
    estimate_update_product_line_item,
    estimate_delete_product_line_item,
    estimate_update_practice_assignment,
    estimate_save_resource_plan,
    estimate_set_selected_resource_plan,
    estimate_generate_sow,
    estimate_generate_wbs,
    estimate_get_comments,
    estimate_create_comment,
    estimate_notify_users

}


export type TEstimateAPIAction =
    | { type: EEstimateAPIActionType.fetch, salesEstimateId: string }
    | { type: EEstimateAPIActionType.estimate_projectassignment_change, projectAssignment: ITeam[] }
    | { type: EEstimateAPIActionType.estimate_links_change, links: LinkDto[] }
    | { type: EEstimateAPIActionType.estimate_projectconfiguration_update, estimate: Partial<IEstimate> }
    | { type: EEstimateAPIActionType.estimate_resource_allocation_update, resourceAllocation: ResourceAllocationDto[] }
    | { type: EEstimateAPIActionType.estimate_save_regenerate_investment_summary, investmentSummary: InvestmentSummaryDto | undefined }
    | { type: EEstimateAPIActionType.estimate_add_resourceplan, resourcePlanName: string }
    | { type: EEstimateAPIActionType.estimate_apply_templates, templateIds: string[] }
    | { type: EEstimateAPIActionType.estimate_resource_allocation_generate }
    | { type: EEstimateAPIActionType.estimate_save_resource_plan, resourcePlan: Swagger.ResourcePlanDto }
    | { type: EEstimateAPIActionType.estimate_update_practice_assignment, practiceAssignment: Swagger.SalesEstimatePracticeAssignmentDto }
    | { type: EEstimateAPIActionType.estimate_add_product_line_item, productLineItem: ProductLineItem }
    | { type: EEstimateAPIActionType.estimate_update_product_line_item, productLineItem: ProductLineItem }
    | { type: EEstimateAPIActionType.estimate_delete_product_line_item, productLineItemId: string }
    | { type: EEstimateAPIActionType.estimate_set_selected_resource_plan, resourcePlanId: string }
    | { type: EEstimateAPIActionType.estimate_generate_sow, salesEstimateId: string }
    | { type: EEstimateAPIActionType.estimate_generate_wbs, salesEstimateId: string }
    | { type: EEstimateAPIActionType.estimate_get_comments, salesEstimateId: string }
    | { type: EEstimateAPIActionType.estimate_create_comment, comment: string, userId: string, users: Swagger.SystemUserDto[], commenterName: string }
    | { type: EEstimateAPIActionType.estimate_notify_users, comment: string, users: Swagger.SystemUserDto[], commenterName: string }




export type SalesEstimateAPIHook = {
    stateEstimate: IEstimateState;
    isAPILoading: boolean;
    error: string;
    dispatchAPIEstimate: React.Dispatch<React.SetStateAction<TEstimateAPIAction | undefined>>;
    dispatchEstimate: React.Dispatch<TEstimateAction>
}
const useSalesEstimate = (): SalesEstimateAPIHook => {
    const [stateEstimate, dispatchEstimate] = useOutletContext() as TEstimateStore;
    const [dispatchEstimateAction, setDispatchEstimateAction] = useState<TEstimateAPIAction>();
    const { dataSource } = useApplication();
    const [error, setError] = useState("");

    const setIsLoading = (isLoading: boolean): void => dispatchEstimate({
        type: EEstimateActionType.estimate_is_loading,
        isLoading: isLoading
    })

    useEffect(() => {
        switch (dispatchEstimateAction?.type) {
            case EEstimateAPIActionType?.fetch: {
                fetchSalesEstimate(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.salesEstimateId)
                break;
            }
            case EEstimateAPIActionType?.estimate_projectassignment_change: {
                updateSalesEstimateProjectAssignment(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.projectAssignment)
                break;
            }
            case EEstimateAPIActionType?.estimate_links_change: {
                updateSalesEstimateLinks(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.links)
                break;
            }
            case EEstimateAPIActionType?.estimate_projectconfiguration_update: {
                updateSalesEstimateProjectConfiguration(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.estimate)
                break;
            }
            case EEstimateAPIActionType?.estimate_resource_allocation_update: {
                updateSalesEstimateResourceAllocation(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.resourceAllocation)
                break;
            }
            case EEstimateAPIActionType?.estimate_resource_allocation_generate: {
                generateSalesEstimateResourceAllocation(stateEstimate, dataSource, setIsLoading, dispatchEstimate)
                break;
            }
            case EEstimateAPIActionType?.estimate_save_regenerate_investment_summary: {
                updateSalesEstimateInvestmentSummary(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.investmentSummary)
                break;
            }
            case EEstimateAPIActionType?.estimate_add_resourceplan: {
                addSalesEstimateResourcePlan(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.resourcePlanName)
                break;
            }
            case EEstimateAPIActionType?.estimate_apply_templates: {
                applySalesEstimateTemplates(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.templateIds)
                break;
            }
            case EEstimateAPIActionType?.estimate_update_practice_assignment: {
                updateSalesEstimatePracticeAssignment(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.practiceAssignment)
                break;
            }
            case EEstimateAPIActionType?.estimate_save_resource_plan: {
                saveSalesEstimateResourcePlan(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.resourcePlan)
                break;
            }
            case EEstimateAPIActionType?.estimate_set_selected_resource_plan: {
                saveSalesEstimateSelectedResourcePlan(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.resourcePlanId)
                break;
            }
            case EEstimateAPIActionType?.estimate_add_product_line_item: {
                addSalesEstimateProductLineItem(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.productLineItem)
                break;
            }
            case EEstimateAPIActionType?.estimate_update_product_line_item: {
                updateSalesEstimateProductLineItem(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.productLineItem)
                break;
            }
            case EEstimateAPIActionType?.estimate_delete_product_line_item: {
                deleteSalesEstimateProductLineItem(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.productLineItemId)
                break;
            }
            case EEstimateAPIActionType?.estimate_generate_sow: {
                generateSalesEstimateSOW(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.salesEstimateId)
                break;
            }
            case EEstimateAPIActionType?.estimate_generate_wbs: {
                generateSalesEstimateWBS(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.salesEstimateId)
                break;
            }
            case EEstimateAPIActionType?.estimate_get_comments: {
                getSalesEstimateComments(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.salesEstimateId)
                break;
            }
            case EEstimateAPIActionType?.estimate_create_comment: {
                addSalesEstimateComment(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.comment, dispatchEstimateAction.userId, dispatchEstimateAction.users, dispatchEstimateAction.commenterName)
                break;
            }
            case EEstimateAPIActionType?.estimate_notify_users: {
                notifyUser(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.users, dispatchEstimateAction.commenterName)
                break;
            }
            // case EEstimateAPIActionType?.load_selected_resourceplan: {
            //     updateSalesEstimateInvestmentSummary(stateEstimate, dataSource, setIsLoading, dispatchEstimate, dispatchEstimateAction.investmentSummary)
            //     break;
            // }
            default:
                // console.log("test")
                break;
        }
    }, [dispatchEstimateAction])

    // useEffect(() => {
    //     fetch(url)
    //         .then((res) => res.json())
    //         .then((data) => {
    //             seterror(data.error)
    //             setdata(data.joke)
    //             setloading(false)
    //         })
    // }, [url]);

    return { stateEstimate: stateEstimate, isAPILoading: stateEstimate.isAPILoading, error, dispatchAPIEstimate: setDispatchEstimateAction, dispatchEstimate: dispatchEstimate };
};

export default useSalesEstimate;