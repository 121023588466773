import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';

import ModalApproveEstimate from '../Estimates/EstimateDetail/Overview/ModalApproveEstimate';
import ModalEstimationTeam from '../Estimates/EstimateDetail/Overview/ModalEstimationTeam';
import ModalChooseTemplate from '../Estimates/EstimateLineItems/ModalChooseTemplate';
// import { IIpItem } from 'models/IIpItem';
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import { dispatchDocumentExceptionEvent } from 'components/EventSystem';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 715,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1115,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DevPage = () => {
  const application = useApplication();

  //Estimation Team Modal
  const [isOpenEstimationTeam, setIsOpenEstimationTeam] = useState(false);
  const handleOpenEstimationTeam = (): void => setIsOpenEstimationTeam(true);

  //Approve Estimate Modal
  const [openApproveEstimate, setOpenApproveEstimate] = useState(false);
  const handleOpenApproveEstimate = (): void => setOpenApproveEstimate(true);
  const handleCloseApproveEstimate = (): void => setOpenApproveEstimate(false);

  const [teams, setTeams] = useState<any>([]);

  //Choose Template Modal
  const [isOpenChooseTemplate, setIsOpenChooseTemplate] = useState(false);
  const handleOpenChooseTemplate = (): void => setIsOpenChooseTemplate(true);
  const [template, setTemplate] = useState('');//for now 
  //Add Build Item Modal

  // const mockBuildItems: IBuildItem[] = [
  //   {
  //     categoryId: '864610000',
  //     type: '864620001',
  //     name: 'Test Build Item1',
  //     effort: [
  //       {
  //         id: '3fa85f64-5717-4562-b3fc-2c963f66afa0',
  //         practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //         practiceRoleId: '10b5551a-88d8-ed11-a7c7-00224827b4ba',
  //         hours: 40
  //       },
  //       {
  //         id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
  //         practiceId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //         practiceRoleId: '11b5551a-88d8-ed11-a7c7-00224827b4bb',
  //         hours: 38
  //       }
  //     ],
  //     assumptions: [
  //       { id: 'testId1', description: 'Assumption1' },
  //       { id: 'testId2', description: 'Assumption2' }
  //     ]
  //   }
  // ];

  const isAddNewBuildItem = false; //true = emulate add new button clicked in parent, false = emulate updating a selected Build Item. This will update buildItems[0]
  const [isOpenAddBuildItem, setIsOpenAddBuildItem] = useState(false);
  // const [buildItems, setBuildItems] = useState<IBuildItem[]>(mockBuildItems);
  const handleOpenAddBuildItem = (): void => setIsOpenAddBuildItem(true);

  //Add Ip Item Modal
  // const [isOpenAddIpItem, setIsOpenAddIpItem] = useState(false);
  // const handleOpenAddIpItem = (): void => setIsOpenAddIpItem(true);
  // const mockIpItems = [
  //   {
  //     ipNameId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  //     billingStyle: '39b85ad4-87d8-ed11-a7c7-0022482a466b',
  //     description: 'test description1',
  //   },
  //   {
  //     ipNameId: '5322f593-b4e3-ed11-a7c7-0022482a466b',
  //     billingStyle: '2322f593-b4e3-ed11-a7c7-0022482a466b',
  //     description: 'test description2',
  //   },
  // ];

  // const selectedIpItem: { ipItem: IIpItem; index: number } = {
  //   ipItem: mockIpItems[0],
  //   index: 0,
  // }; //test a value selected in parent
  // const isAddNewIpItem = false; //test add new button clicked in parent,set to false to test updating the selectedIpItem
  // const [ipItems, setIpItems] = useState<IIpItem[]>(mockIpItems);
  // const ipItemDefaultValue = isAddNewIpItem ? null : selectedIpItem;

  return (
    <Box>
      <div>
        <Button
          variant="contained"
          sx={{ margin: '50px' }}
          onClick={handleOpenEstimationTeam}
        >
          Open Estimation Team Modal
        </Button>
        <ModalEstimationTeam
          defaultValue={teams}
          isOpen={isOpenEstimationTeam}
          onClose={() => {
            setIsOpenEstimationTeam(false);
          }}
          onChange={(teams) => {
            setTeams(teams);
          }}
        />
      </div>
      <div>
        <Button
          variant="contained"
          sx={{ margin: '50px' }}
          onClick={handleOpenApproveEstimate}
        >
          Approve Estimate Modal
        </Button>
        <Modal
          open={openApproveEstimate}
          onClose={handleCloseApproveEstimate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalApproveEstimate />
          </Box>
        </Modal>
      </div>
      <div>
        <Button
          variant="contained"
          sx={{ margin: '50px' }}
          onClick={handleOpenChooseTemplate}
        >
          Choose Template Modal
        </Button>
        <ModalChooseTemplate
          isOpen={isOpenChooseTemplate}
          onClose={() => {
            setIsOpenChooseTemplate(false);
          }}
          onChange={(xTemplate) => {
            setTemplate(xTemplate);
            alert(`Selected Template: ${template}`);
          }}
        />
      </div>
      {/* <div>
        <Button
          variant="contained"
          sx={{ margin: '50px' }}
          onClick={handleOpenAddBuildItem}
        >
          Add Build Item Modal
        </Button>
    
      </div>
      <div>
        <Button
          variant="contained"
          sx={{ margin: '50px' }}
          onClick={handleOpenAddIpItem}
        >
          Add IP Item Modal
        </Button>
    
      </div> */}

      <Button
        variant="contained"
        sx={{ margin: '50px' }}
        onClick={() => {
          application.cache.reset();
          // dispatchDocumentExceptionEvent({
          //   error: new Error("test"),
          // })
          throw new Error("waszup");
        }}
      >
        Reset Cache
      </Button>

    </Box>
  );
};
export default DevPage;
