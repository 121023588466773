import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from 'registerServiceWorker';
import ApplicationInfrastructure, { msalInstance, reactPlugin } from 'config';
import { LinearProgress } from '@mui/material';


const rootElement = document.getElementById('root') as HTMLElement;
rootElement.innerHTML = "Loading Configuration";

const root = ReactDOM.createRoot(
  rootElement
);

root.render(
  <div>
    <LinearProgress />
  </div>
)


ApplicationInfrastructure
  .initialize()
  .then(applicationInfrastructure => {

    //https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-react
    root.render(
      <React.StrictMode>
        <AppInsightsContext.Provider value={applicationInfrastructure.reactPlugin}>
          <MsalProvider instance={applicationInfrastructure.msalInstance!}>
            <App />
          </MsalProvider>
        </AppInsightsContext.Provider>
      </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

  })
  .catch((error) => {
    root.render(
      <React.StrictMode>
        Loading failed.
        <br />
        {error?.message ?? error?.toString()}
      </React.StrictMode>
    );
  });

// for now disabling, service worker will cache user profiles pictures, pending expiry logic and more testing...
// registerServiceWorker();