import { Autocomplete, Avatar, Box, CircularProgress, InputAdornment, Stack, TextField } from "@mui/material";
import { useApplication } from "components/ApplicationContextProvider/ApplicationContextProvider";
import UserAvatar from "components/UserAvatar";
import { EntityReferenceDto, SystemUserDto } from "data/swagger/API";
import _ from "lodash";
import React, { CSSProperties, useMemo, useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import { EEstimateActionType } from "store/reducers/EstimateReducer";
import useSalesEstimate from "views/Estimates/EstimatesHooks/useSalesEstimate";

// TODO !important, we need to drop this type, talk to Christian, should use a system user dto
export interface UserOptions {
    name: string,
    id: string,
    email: string,
    azureActiveDirectoryObjectId?: string,
}

const SystemUserDTO_to_UserOption = (t: SystemUserDto) => {
    return {
        name: t.fullName || `${t.lastName}, ${t.firstName}` || "",
        id: t.id || "",
        email: t.internalEMailAddress || "",
        azureActiveDirectoryObjectId: t.azureActiveDirectoryObjectId || "",
    }
}

// TODO use debounce instead of setTimeout, it still invoked the search with many change requests
// TODO implement abort controller
// TODO update api to load entire users, right now. 100 users are 40K uncompressed, so projecting 10K users ~ 4MB at most
export const UserSearch = (props: { disabled: boolean, title: string, value?: EntityReferenceDto, onSelect: (user: UserOptions) => void, style?: CSSProperties }) => {

    const salesEstimateReducer = useSalesEstimate()
    const { dataSource, identity } = useApplication();
    const [allUserValues, setAllUserValues] = useState<UserOptions[]>([]);
    const [isLoadingUserValues, setIsLoadingUserValues] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string>("");

    const blankUser = useMemo(() => {
        const user: UserOptions = {
            name: "",
            id: "",
            email: "",
            azureActiveDirectoryObjectId: "",
        };
        return user;
    }, []);

    const [selectedUser, setSelectedUser] = useState<UserOptions>((() => {
        if (props?.value?.id) {
            return {
                name: props?.value.name!,
                id: props?.value.id!,
                email: "",
                azureActiveDirectoryObjectId: "",
            }
        }

        return blankUser;
    })());


    // TODO add selected user to list of availalbe options
    const fetchUserOptions = useCallback(
        _.debounce(
            async (searchParameter?: string) => {
                // Fetch Standard Items - confirm if standard items or line itmes
                setIsLoadingUserValues(true)
                const nameSplit = searchParameter?.split(" ")
                const filterFullName = {
                    filters: {
                        "fullname": searchParameter!,
                        "internalemailaddress": searchParameter!,
                    },
                    filterOperator: "or"
                }

                const filterParitalName = {
                    filters: {
                        "firstname": nameSplit?.length == 2 ? nameSplit[0] : searchParameter!,
                        "lastname": nameSplit?.length == 2 ? nameSplit[1] : searchParameter!,
                    },
                    filterOperator: "and"
                }
                const allUsers = await dataSource.webApi.swagger.systemUser.systemUserGetAllActiveUsers({
                    data: {
                        "currentPage": 1,
                        "pageSize": 100,
                        ...(searchParameter && { ...nameSplit?.length === 2 ? filterParitalName : filterFullName })
                    }
                });

                const userOptions: UserOptions[] = allUsers.data?.data?.map(t => SystemUserDTO_to_UserOption(t)) ?? []

                setAllUserValues(userOptions);
                setIsLoadingUserValues(false)
            },
            500),
        []
    )

    useEffect(() => {
        // (async () => {
        //     // initiate the list with current user at least
        //     if (selectedUser?.id && allUserValues.length === 0) {
        //         const systemUser = await dataSource.webApi.swagger.systemUser.systemUserGetById({ id: selectedUser.id });
        //         if (systemUser.data.data) {
        //             setAllUserValues([SystemUserDTO_to_UserOption(systemUser.data.data)]);
        //         }

        //     }
        // })();

        (async () => {
            // load the azureActiveDirectoryObjectId incase if it does not exist
            if (selectedUser?.id && !selectedUser.azureActiveDirectoryObjectId) {
                const systemUser = await dataSource.webApi.swagger.systemUser.systemUserGetById({ id: selectedUser.id });
                if (systemUser.data.data?.azureActiveDirectoryObjectId)
                    setSelectedUser(user => ({
                        ...user,
                        azureActiveDirectoryObjectId: systemUser.data.data?.azureActiveDirectoryObjectId
                    }))
            }
        })()

    }, [selectedUser, allUserValues])



    return <Autocomplete
        disabled={props.disabled}
        key={selectedUser.id}
        filterOptions={(options, { inputValue }) => options.filter(item => {
            const splitValue = inputValue.split(" ")
            return (
                item.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ||
                item.email.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ||
                (splitValue.length == 2 && item.name.toLocaleLowerCase().includes(splitValue[0].toLocaleLowerCase()) && item.name.toLocaleLowerCase().includes(splitValue[1].toLocaleLowerCase()))
            )
        })}
        value={selectedUser}
        onChange={(event, value) => {
            debugger;
            if (value) {
                setSelectedUser(value)
                props.onSelect(value)
            }
        }}
        inputValue={filterValue}
        sx={{ ...props.style, width: "100%" }}
        size='small'
        options={allUserValues}
        onInputChange={(event, value) => {
            if (value === "") {
                debugger;
                setSelectedUser(blankUser)
            }

            setFilterValue(value);
            fetchUserOptions(value);
        }}
        getOptionLabel={option => option?.name ?? ""}
        renderInput={
            (params) => <TextField
                {...params}
                variant="standard"
                label={`${props.title}`}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: selectedUser?.azureActiveDirectoryObjectId && (
                        <React.Fragment>
                            <UserAvatar
                                sx={{ height: 24, width: 24, marginRight: 1 }}
                                azureActiveDirectoryObjectId={selectedUser?.azureActiveDirectoryObjectId}
                            />
                            {params.InputProps.startAdornment}
                        </React.Fragment>
                    ),
                    endAdornment: (
                        <React.Fragment>
                            {isLoadingUserValues ? <CircularProgress color="inherit" size={15} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    style: { height: 41, maxWidth: '80%' }
                }}
            />
        }
        isOptionEqualToValue={(option, value) => !value.id
            ? false
            : (option.id === value.id || option.email === value.email || option.name === value.name)
        }
        renderOption={(props, option) => {

            return (
                <Box
                    component="li"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    {...props}
                >
                    <UserAvatar
                        alt={option.name}
                        azureActiveDirectoryObjectId={option.azureActiveDirectoryObjectId}
                        sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                    {option.name}
                </Box>
            )
        }}
    />;
}