import React, { useEffect, useState } from 'react';
import { Box, Modal, Grid, InputAdornment } from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiTextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import validator from 'validator';

import * as ISwaggerAPI from 'data/swagger/API';

import ModalNotification from '../../../../../components/Notification/ModalNotification';
import { IModalAddLinkProps } from './IModalAddLinkProps';
import ActionButton from 'components/ActionButton/ActionButton';

const styleModalPopup = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ModalAddLink: React.FunctionComponent<IModalAddLinkProps> = (props) => {
  const errorAlertColor: any = 'error';
  const blankProjectLink = () => {
    const bProjectLink: ISwaggerAPI.LinkDto = {
      hsl_salesestimate: undefined,
      hsl_name: null,
      hsl_link: null,
    };
    return bProjectLink;
  };

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [projectLink, setProjectLink] = useState<ISwaggerAPI.LinkDto>(
    props.defaultValue ? props.defaultValue : blankProjectLink()
  );

  useEffect(() => {
    if (props.isOpen) {
      if (props.defaultValue) setProjectLink(props.defaultValue);
      else setProjectLink(blankProjectLink());
    }
  }, [props.isOpen]);

  const validateInput = (): boolean => {
    if (
      !projectLink.hsl_name ||
      !projectLink.hsl_link ||
      !validator.isURL(projectLink.hsl_link) ||
      validator.isEmpty(projectLink.hsl_name)
    ) {
      setNotificationOpen(true);
      return false;
    }
    return true;
  };

  const onRenderFooter = () => {
    return <footer>
      <div style={{ display: 'grid', gap: '10px', gridAutoFlow: 'column', marginTop: '20px' }}>
        <ActionButton
          label='Cancel'
          onClick={() => {
            setNotificationOpen(false);
            setProjectLink(blankProjectLink);
            props.onClose();
          }}
        />
        <ActionButton
          label='Add'
          primary
          onClick={() => {
            if (validateInput()) {
              setNotificationOpen(false);
              props.onChange(projectLink);
              setProjectLink(blankProjectLink);
              props.onClose();
            }
          }}
        />
      </div>
    </footer>;
  }

  const onRenderMain = () => {
    return <Box>
      <Typography variant="h1">{props.action} Link</Typography>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr', marginTop: '15px' }}>
        <MuiTextField
          required
          inputProps={{ maxLength: 100 }}
          sx={{ width: '100%' }}
          label={"Name"}
          margin="normal"
          id="outlined-required"
          value={projectLink.hsl_name}
          onChange={(event) => {
            setProjectLink((xProjectLink) => {
              const nProjectLink: ISwaggerAPI.LinkDto = {
                ...xProjectLink,
                hsl_name: event.target.value,
              };
              return nProjectLink;
            });
            setNotificationOpen(false);
          }}
        />
        <MuiTextField
          required
          inputProps={{ maxLength: 2000 }}
          sx={{ width: '100%' }}
          label={"URL"}
          margin="normal"
          id="outlined-required"
          type="url"
          InputProps={{
            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          }}
          value={projectLink.hsl_link?.replace("https://", "") || ""}
          onChange={(event) => {
            setProjectLink((xProjectLink) => {
              const nProjectLink: ISwaggerAPI.LinkDto = {
                ...xProjectLink,
                hsl_link: `https://${event.target.value}`,
              };
              return nProjectLink;
            });
            setNotificationOpen(false);
          }}
        />
      </div>
      <ModalNotification
        isOpen={notificationOpen}
        message="The Name is empty or the URL is not valid."
        alertSeverity={errorAlertColor}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => { setNotificationOpen(false) }}
        sx={{ width: '80%' }}
      />
      {onRenderFooter()}
    </Box>
  };

  return <Modal
    open={props.isOpen}
    onClose={() => {
      props.onClose();
    }}
  >
    <Box sx={styleModalPopup}>{onRenderMain()}</Box>
  </Modal>;

};

export default ModalAddLink;
