import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { Link } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { saveAs } from 'views/Templates/Template/helpers';

export enum ITraceSeverity {
    Error, Critical,
    Fatal // Halt the application
}
export interface ITrace {
    error?: any,
    timeStamp?: string,
    telemetry?: IExceptionTelemetry,
    description?: string,
    location?: string;
    severity?: "critical" | "error" | ITraceSeverity
}

interface IAlertDialog {
    isOpen: boolean;
    onClose: (event: any) => void;
    trace?: ITrace;
}

const ErrorDialog: React.FC<IAlertDialog> = (props) => {
    const { isOpen, onClose, trace } = props;

    if (!props.isOpen)
        return <></>;

    if (!trace)
        return <></>;

    return <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Uh oh! Something went wrong...
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Try this action again. If the problem continues, contact your organization's Administrator.
            </DialogContentText>

            <div style={{
                boxSizing: 'border-box',
                border: '1px solid rgb(202, 202, 202)',
                width: '100%',
                fontSize: 'x-small',
                maxHeight: '12em',
                minHeight: '12em',
                overflowY: 'scroll',
                whiteSpace: 'pre-wrap',
                lineHeight: '1em'
            }}>
                <code>
                    {trace.timeStamp &&
                        <div>Timestamp: {trace.timeStamp}</div>
                    }

                    {trace.telemetry?.id &&
                        <div>Insight Tracker: {trace.telemetry.id}</div>
                    }

                    {!trace.description &&
                        <div>
                            {trace.description}
                        </div>
                    }
                    <div>
                        {trace?.error?.message &&
                            <div>
                                {trace.error.message}
                            </div>
                        }
                        {trace?.error?.stack &&
                            <div>
                                {trace.error.stack}
                            </div>
                        }
                    </div>
                </code>
            </div>

        </DialogContent>
        <DialogActions>
            <div style={{ display: 'flex' }}>
                <Button
                    size={"small"}
                    color={"secondary"}
                    sx={{ width: '120px', }}
                    onClick={() => {
                        const log = JSON.stringify(trace);
                        const subject = `Estimator - Exception - (${trace.telemetry?.id})`;
                        const body = `Regarding: ${window.location.href}\n\nWhat I was doing:\n[describe steps to reproduce the issue]\n\nStack:` + log;
                        window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
                    }}
                >
                    Share
                </Button>
                <Button
                    size={"small"}
                    color={"secondary"}
                    sx={{ width: '120px', }}
                    onClick={() => {
                        const log = JSON.stringify(trace);
                        const blob = new Blob([log], { type: 'text/plan;charset=utf-8' });
                        saveAs(blob, `trace.${trace.telemetry?.id}.txt`, { autoBom: true });
                    }}
                >
                    Download
                </Button>
                <Button
                    size={"small"}
                    color={"secondary"}
                    sx={{ width: '120px', }}
                    onClick={onClose}
                    style={{ marginLeft: 'auto' }}
                >
                    Close
                </Button>
            </div>
        </DialogActions>
    </Dialog>
}

export default ErrorDialog;