import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    Stack
} from '@mui/material';

// project import

// assets
import { BellOutlined, CloseOutlined, GiftOutlined, MessageOutlined, SettingOutlined } from '@ant-design/icons';
import MainCard from '../MainCard';
import Transitions from '../@extended/Transitions';
import { useApplication } from 'components/ApplicationContextProvider/ApplicationContextProvider';
import { fetchNotifications, markAllAsRead } from './datalayer';
import { NotificationDto } from 'data/swagger/API';
import dayjs from 'dayjs';
import { Config } from 'config';
import DraftsIcon from '@mui/icons-material/Drafts';

enum SupportedEntities {
    hsl_salesestimate,
    hsl_salesestimatelineitem,
    hsl_resouceplan
}

function getUrl(entity: string, id: string, isComments: boolean): string {
    let url = ""
    debugger
    switch (entity) {
        case "hsl_salesestimate":
            url = isComments ? `${Config.webApp.baseUrl}/estimates/${id}/comments` : `${Config.webApp.baseUrl}/estimates/${id}/details`

            break
        // case "hsl_salesestimatelineitem":
        //     url = `${Config.webApp.baseUrl}/${entity}/${id}`
        // case "hsl_resouceplan":
        //     url = `${Config.webApp.baseUrl}/estimates/${entity}/${id}`
        default:
            url = `${Config.dynamics.baseUrl}/main.aspx?etn=${entity}&id={${id}}&newWindow=true&pagetype=entityrecord`;
            break
    }
    return url

}

// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const application = useApplication();

    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const notificationState = useState<NotificationDto[]>([]);
    const [notifications, setNotifications] = notificationState

    const fetchNotificationsData = async () => {
        application.identity?.xrm.systemuser.id && await fetchNotifications(application.dataSource, application.identity?.xrm.systemuser.id)
            .then((response) => {
                if (response.ok)
                    setNotifications(response.data.data?.sort(function (a, b) {
                        return new Date(b.createdOn!).getTime() - new Date(a.createdOn!).getTime()
                    }) || [])
            })
    }

    useEffect(() => {
        fetchNotificationsData()
    }, [application.identity?.xrm.systemuser.id])
    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    useEffect(() => {
        const intervalCall = setInterval(() => {
            fetchNotificationsData();
        }, 120000);
        return () => {
            clearInterval(intervalCall);
        };
    }, []);

    async function handleMarkAllAsRead() {
        setNotifications([])
        application.identity?.xrm.systemuser.id && await markAllAsRead(application.dataSource, notifications)
        fetchNotificationsData()
        setOpen(false);
    }

    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                color="secondary"
                sx={{ rippleColor: 'purple', color: 'text.primary', bgcolor: open ? iconBackColorOpen : "transparent" }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={notifications.length} color="primary">
                    <BellOutlined />
                </Badge>
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.shadows[1],
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    sx={{ maxHeight: '500px', overflow: 'auto' }}
                                    title="Notification"
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={<>
                                        <IconButton size="medium" onClick={handleMarkAllAsRead}>
                                            <DraftsIcon sx={{ height: 20, width: 20 }} />
                                        </IconButton>
                                        <IconButton size="medium" onClick={handleToggle}>
                                            <CloseOutlined />
                                        </IconButton>
                                    </>

                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                                '& .MuiAvatar-root': avatarSX,
                                                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                            }
                                        }}
                                    >
                                        {notifications.length > 0 ?
                                            // <>
                                            //     <ListItemButton
                                            //         onClick={async () => {
                                            //             handleMarkAllAsRead()
                                            //         }}
                                            //         sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                            //         <ListItemText
                                            //             primary={
                                            //                 <Typography variant="h6" color="primary">
                                            //                     Mark All As Read
                                            //                 </Typography>
                                            //             }
                                            //         />
                                            //     </ListItemButton>
                                            //     <Divider />
                                            // </>
                                            <></>

                                            :

                                            <ListItemButton
                                                sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant="h6" color="primary">
                                                            No Notifications
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        }

                                        {
                                            notifications.map((notification) => {

                                                return (
                                                    <>
                                                        <ListItemButton onClick={async () => {
                                                            window.open(getUrl(notification.regardingObjectId?.logicalName!, notification.regardingObjectId?.id!, notification.subject === "New Mention"))
                                                            setNotifications(prev => prev.filter((localNotifications) => localNotifications.id != notification.id))
                                                            application.identity?.xrm.systemuser.id && await markAllAsRead(application.dataSource, [notification])
                                                        }}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="h4">
                                                                        {notification.subject}
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    notification.hsl_Message}

                                                            />
                                                            <ListItemSecondaryAction>
                                                                <Stack textAlign={"right"}>
                                                                    <Typography variant="caption" noWrap>
                                                                        {dayjs(notification.createdOn).format("MM/DD")}
                                                                    </Typography>
                                                                    <Typography variant="caption" noWrap>
                                                                        {dayjs(notification.createdOn).format("hh:mm A")}
                                                                    </Typography>

                                                                </Stack>
                                                            </ListItemSecondaryAction>
                                                        </ListItemButton >
                                                        <Divider />
                                                    </>

                                                )
                                            })
                                        }
                                        {/* <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: 'primary.lighter'
                                                    }}
                                                >
                                                    <MessageOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Aida Burg
                                                        </Typography>{' '}
                                                        commented your post.
                                                    </Typography>
                                                }
                                                secondary="5 August"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    6:00 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: 'error.main',
                                                        bgcolor: 'error.lighter'
                                                    }}
                                                >
                                                    <SettingOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        Your Profile is Complete &nbsp;
                                                        <Typography component="span" variant="subtitle1">
                                                            60%
                                                        </Typography>{' '}
                                                    </Typography>
                                                }
                                                secondary="7 hours ago"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    2:45 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: 'primary.lighter'
                                                    }}
                                                >
                                                    C
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Cristina Danny
                                                        </Typography>{' '}
                                                        invited to join{' '}
                                                        <Typography component="span" variant="subtitle1">
                                                            Meeting.
                                                        </Typography>
                                                    </Typography>
                                                }
                                                secondary="Daily scrum meeting time"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    9:10 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6" color="primary">
                                                        View All
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                        <Divider /> */}
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box >
    );
};

export default Notification;
