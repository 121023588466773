import { AzureDirectoryDataSource } from "./AzureDirectoryDataSource";
import { AzureDirectoryTokenProvider } from "./AzureDirectoryTokenProvider";

/**
 * Represents a datasource connecting to the .Net web api controllers
 */
export class MSGraphDataSource extends AzureDirectoryDataSource {

    constructor(provider: AzureDirectoryTokenProvider) {
        super(provider);
    }

    protected async handleResponse(response: Response, parseResponse: boolean): Promise<any> {
        if (response.ok) {
            if (parseResponse) {
                return await response.json();
            } else {
                return response;
            }
        } else {
            let body: any = undefined;

            // reading as json reads the stream and its lost
            const responseClone = response.clone();

            if (!body)
                try {
                    body = await response.json();
                } catch { }

            if (!body)
                try {
                    const text = await responseClone.text();
                    body = new Error(text ?? `${response.status}/${response.statusText}`);
                } catch { }

            if (!body)
                try {
                    body = new Error('Failed to interpret response')
                } catch { }

            throw body;
        }
    }

    async execute(method: 'GET' | 'POST', url: string, body?: any): Promise<any> {
        let token = await this.getToken(this.provider.getDefaultScopes());
        let response = await fetch(`${this.provider.baseUrl}/${url}`, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        });

        return this.handleResponse(response, true);
    }

    async raw(method: string, url: string, body?: any): Promise<Response> {
        let token = await this.getToken(this.provider.getDefaultScopes());
        let response = await fetch(`${this.provider.baseUrl}/${url}`, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        });
        await this.handleResponse(response, false);
        return response;
    }

}
