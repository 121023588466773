
// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import

// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

interface IMainCardPros {
    border?: boolean;
    sx?: any,
    elevation?: any,
    shadow?: boolean;
    children: any;
    secondary?: any;
    content?: any,
    contentSX?: any,
    codeHighlight?: boolean;
    title?: string;
}


const MainCard = (props: IMainCardPros) => {
    let { border, shadow, elevation, sx, children, secondary, content, contentSX, codeHighlight, title, ...others } = props;
    const theme = useTheme();
    const darkTitle = false;
    const divider = true;

    return (
        <Card
            elevation={elevation || 0}
            {...others}
            sx={{
                ...sx,
                border: border ? '1px solid' : 'none',
                borderRadius: 2,
                borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A700,
                boxShadow: theme.shadows[1],
                '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem'
                }
            }}
        >
            {/* card header and action */}
            {!darkTitle && title && (
                <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} action={secondary} />
            )}
            {darkTitle && title && (
                <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
            )}

            {/* content & header divider */}
            {title && divider && <Divider />}

            {/* card content */}
            {content && <CardContent sx={contentSX}>{children}</CardContent>}
            {!content && children}

            {/* card footer - clipboard & highlighter  */}
            {codeHighlight && (
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                </>
            )}
        </Card>
    );
}


export default MainCard;
