// TODO case adjustment for methods

export const makeId = (length: number) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++) {
    result += characters.charAt(i % characters.length);
  }
  return result;
}

// filter array to contain only unique values, using key as filter
export function filterArrayUnique<T>(data: T[], key: keyof T, predicate?: (v: T) => any): T[] {
  let t = new Map<any, T>();
  data.forEach((v: T) => {
    t.set(
      predicate ? predicate(v) : v[key]
      , v);
  })
  return [...t.values()]
}

//To do: move this function to a shared location, right now it is replicated in multiple files
export function ArrayToLookup<T extends { id: string, label?: string | null }>(object: T[]) {
  const map = object.reduce((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
  }, {} as Record<string, T>);

  return map;
}


export function ArrayToMap<T extends { id: string }>(object: T[]) {
  const map = new Map<string, T>();
  object.forEach(e => {
    map.set(e.id, e);
  });

  return map;
}


export function ArrayToLookupByKey<T extends Record<string, any>>(object: T[], indexer: keyof T) {
  const map = object.reduce((prev, curr) => {
    const key = curr[indexer];
    prev[key] = curr;
    return prev;
  }, {} as Record<string, T>);

  return map;
}

export function ArrayToMapByKey<T extends { id: string }>(object: T[], indexer: keyof T) {
  const map = new Map<string, T>();

  object.forEach(e => {
    const key = (e[indexer] as unknown) as string;
    map.set(key, e);
  });

  return map;
}

export function ArrayToLookupByPredicate<T extends Record<string, any>>(object: T[], predicate: (item: T) => string) {
  const map = object.reduce((prev, curr) => {
    const key = predicate(curr);
    prev[key] = curr;
    return prev;
  }, {} as Record<string, T>);

  return map;
}

export function ArrayToMapByPredicate<T extends { id: string }>(object: T[], predicate: (item: T) => string) {
  const map = new Map<string, T>();
  object.forEach(e => {
    const key = predicate(e)
    map.set(key, e);
  });

  return map;
}


// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function ArrayToGroupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}


// https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript




export function updateArrayReplace<T>(array: T[], oldItem: T, newItemChanges: T) {
  var index = array.indexOf(oldItem);
  if (index === -1)
    return array;

  return [
    ...array.slice(0, index),
    Object.assign({}, newItemChanges),
    ...array.slice(index + 1)
  ];
}

export function Memoize<T>(fn: () => Promise<T>) {
  let cache: T | null = null;

  return async () => {
    if (cache)
      return cache;

    cache = await fn();
    return cache;
  }
}

export async function Delay(ms: number) {
  const promise = new Promise(res => setTimeout(res, ms));
  await promise;
}

// Chat GPT3.0
export function ShortenCurrency(number: number): string {
  const suffixes: string[] = ["", "K", "M", "B", "T"];
  const num: number = parseFloat(number.toFixed(2));
  let shortNum: number = num;
  let suffixIndex: number = 0;

  while (shortNum >= 1000 && suffixIndex < suffixes.length - 1) {
    shortNum /= 1000;
    suffixIndex++;
  }

  shortNum = parseFloat(shortNum.toFixed(2));

  return "$" + shortNum + suffixes[suffixIndex];
}

// Chat GPT3.0
export function ShortenCurrencySTR(number: string) {
  const suffixes = ["", "K", "M", "B", "T"];
  const num = parseFloat(number);
  let shortNum = parseFloat(num.toFixed(2));
  let suffixIndex = 0;

  while (shortNum >= 1000 && suffixIndex < suffixes.length - 1) {
    shortNum /= 1000;
    suffixIndex++;
  }

  let shortNumStr = shortNum.toString();
  if (shortNumStr.indexOf('.') !== -1) {
    while (shortNumStr[shortNumStr.length - 1] === '0') {
      shortNumStr = shortNumStr.slice(0, -1);
    }
  }

  return "$" + shortNumStr + suffixes[suffixIndex];
}




/**
 * 
 * @param date 
 * @param format 
 * @returns 
 * en-US-ET : string, converted to Eastern time zone.   
 * en-US-ET:yyyy-MM-ddThh:mm:ssZ string, ignoring the timezone and assume the its a local date  
 * iso : string of date in iso/utc  
 * local:yyyy-MM-dd : string of date in yyyy-MM-dd format local to user's timezone  
 * local:MM/dd/yyyy : string of date in MM/dd/yyyy format local to user's timezone
 */
export function FormatDate(date: Date, format: 'en-US-ET' | 'local:yyyy-MM-dd' | 'local:MM/dd/yyyy' | 'local:yyyy-MM-ddT00:00:00Z' | 'en-US-ET:yyyy-MM-ddThh:mm:ssZ as local') {
  // TODO switch statement
  switch (format) {
    case 'en-US-ET': {
      return date.toLocaleString('en-US', {
        month: '2-digit',
        year: 'numeric',
        day: '2-digit',
        timeZone: 'America/New_York',
      });
    }

    case "en-US-ET:yyyy-MM-ddThh:mm:ssZ as local": {

      // removing the Z part, so it would be assumed as local time
      const _date = new Date(`${date.toISOString()?.split('T')[0]}T00:00:00`);
      return _date.toLocaleString('en-US', {
        month: '2-digit',
        year: 'numeric',
        day: '2-digit',
        timeZone: 'America/New_York',
      });
    }

    case "local:yyyy-MM-dd": {
      // Better Solution:
      // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
      // const offset = yourDate.getTimezoneOffset()
      // yourDate = new Date(yourDate.getTime() - (offset*60*1000))
      // return yourDate.toISOString().split('T')[0]

      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

    case "local:yyyy-MM-ddT00:00:00Z": {
      // Better Solution:
      // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
      // const offset = yourDate.getTimezoneOffset()
      // yourDate = new Date(yourDate.getTime() - (offset*60*1000))
      // return yourDate.toISOString().split('T')[0]

      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-') + 'T00:00:00Z';
    }

    case "local:MM/dd/yyyy": {
      let month = '' + (date.getMonth() + 1);
      let day = '' + date.getDate();
      const year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day, year].join('/');
    }

    default:
      throw new Error('format unknown');
  }


}

/**
* 
* @param date 
* @param inputFormat 
* @returns 
* iso : JSDate from iso string
* local:yyyy-MM-dd : JSDate from yyyy-MM-dd, ignore timezone associated with the date and only use date
* *adding T00:00:00 without timezone or UTC indicator, iso like format, makes browser generate time for beginning of day in user's timezone
* 
* new Date('2020-11-10T00:00:00')
* Tue Nov 10 2020 00:00:00 GMT-0800 (Pacific Standard Time)
*/
export function ParseDate(date: string, inputFormat: 'local:yyyy-MM-dd' | 'yyyy-MM-ddThh:mm:ssZ as local' | 'iso') {
  switch (inputFormat) {
    case "iso":
      return new Date(date);
    case "local:yyyy-MM-dd":
      return new Date(`${date?.split('T')[0]}T00:00:00`);
    case "yyyy-MM-ddThh:mm:ssZ as local":
      return new Date(`${date?.split('T')[0]}T00:00:00`);
    default:
      throw new Error('format unknown');
  }
}


export function AdjustBrightness(r: number, g: number, b: number, percentage: number) {
  if (percentage < -100) percentage = -100;
  if (percentage > 100) percentage = 100;

  const factor = (100 + percentage) / 100;

  r = Math.round(Math.min(Math.max(r * factor, 0), 255));
  g = Math.round(Math.min(Math.max(g * factor, 0), 255));
  b = Math.round(Math.min(Math.max(b * factor, 0), 255));

  return [r, g, b];
}

export function Throttle(fn: Function, delay: number) {
  let canRun = true;
  let timerId = null as null | NodeJS.Timeout;
  return function (...args: any[]) {

    if (timerId)
      clearTimeout(timerId);

    timerId = setTimeout(() => {
      fn(...args);
      canRun = false;
      setTimeout(() => canRun = true, delay);
    }, delay);

    if (canRun) {
      fn(...args);
      canRun = false;
      setTimeout(() => canRun = true, delay);
      clearTimeout(timerId);
    }

  };
}


export function ArrayToChunk<T>(array: T[], chunkSize?: number) {
  const result = [];

  const _chunkSize = chunkSize ?? 5;

  for (let i = 0; i < array.length; i += _chunkSize) {
    result.push(array.slice(i, i + _chunkSize));
  }

  return result;
}
