import { Id } from "@silevis/reactgrid";
import { SalesEstimateLineItemDto, PracticeRoleDto, ResourcePlanDto, ResourcePlanEntryDto, ResourcePlanResourceDto, ProblemDetails, ProductDto, AnnotationDto, ResourceAllocationDto, HslProjectconfigurationpreferenceGc, HslProjectphase, HslSalesestimateStatecode } from "data/swagger/API";
import { IEstimate } from "models/IEstimate";
import { IAddResourceRequest } from "models/IRequest";
import { IResourcePlan, IResourcePlanResource, IResourcePlanEntry } from "models/IResourcePlan";
import { act } from "react-dom/test-utils";
import { v4 as uuidv4 } from 'uuid';

// TODO remove estimate from the beginnings of the action
// Format: scope_scope_action
export enum EEstimateActionType {
    load,
    estimate_load_practice_role,
    estimate_load_products,
    estimate_update,
    estimate_lineitem_add,
    estimate_iplineitem_addupdate,
    estimate_load_selected_resourceplan,

    estimate_resource_plan_update,
    estimate_resource_plan_resource_update,
    estimate_resource_plan_resource_entry_update,
    estimate_resource_plan_resource_update_billrate,
    estimate_resource_plan_resource_update_hours,
    estimate_resource_plan_resource_update_extend_phase,
    estimate_resource_plan_add_resources,
    estimate_resource_plan_delete_resources,
    estimate_resource_plan_resource_update_delete_phase,
    estimate_resource_plan_resource_update_resource_to_offshore,
    estimate_resource_plan_resource_update_resource_to_onshore,
    estimate_ready,
    estimate_purge,
    estimate_is_loading,
    estimate_clear_resource_plan_state,
    estimate_load_outputs,
    estimate_load_comments,
    estimate_add_comment,

}

export type TEstimateAction =
    | { type: EEstimateActionType.load, estimate?: IEstimate, hsl_resourceplans?: IResourcePlan[] }
    | { type: EEstimateActionType.estimate_load_practice_role, resourceRoles: PracticeRoleDto[] }
    | { type: EEstimateActionType.estimate_update, estimate: Partial<IEstimate> }
    | { type: EEstimateActionType.estimate_lineitem_add, lineItem: SalesEstimateLineItemDto }
    // | { type: EEstimateActionType.estimate_iplineitem_addupdate, item: IntellectualPropertyLineItemDto }
    | { type: EEstimateActionType.estimate_load_selected_resourceplan, resourcePlan: ResourcePlanDto }
    | { type: EEstimateActionType.estimate_resource_plan_update, resourcePlanId: string, resourcePlan: Partial<Pick<IResourcePlan, 'hsl_name'>> }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update, resourcePlanId: string, hsl_resourceplanresourceid: string, resourcePlanResource: Partial<IResourcePlanResource> }
    | { type: EEstimateActionType.estimate_resource_plan_resource_entry_update, resourcePlanId: string, hsl_resourceplanresourceid: string, hsl_resourceplanentryid: string, resourcePlanResourceEntry: Partial<IResourcePlanEntry>, }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_billrate, resourcePlanId: string, resourcePlanResource?: ResourcePlanResourceDto, billRate: number }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_hours, resourcePlanId: string, hsl_resourceplanresourceid: string, hsl_resourceplanentryid: string, hours: number }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_extend_phase, resourcePlanId: string, hsl_resourceplanresourceid: string, entryWeek: number, resourcePlanResourceEntry: ResourcePlanEntryDto[], startingNodeSprint: number | null, extensionWeeks: number }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_resource_to_onshore, resourcePlanId: string, resourceIds: Id[], praticeRoleInfo: PracticeRoleDto[] }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_resource_to_offshore, resourcePlanId: string, resourceIds: Id[], praticeRoleInfo: PracticeRoleDto[] }
    | { type: EEstimateActionType.estimate_resource_plan_resource_update_delete_phase, resourcePlanId: string, entryWeek: number, extensionWeeks: number, startingNodeSprint: number | null }
    | { type: EEstimateActionType.estimate_resource_plan_add_resources, resourcePlanId: string, resourceRoles: IAddResourceRequest[] }
    | { type: EEstimateActionType.estimate_resource_plan_delete_resources, resourcePlanId: string, resourceRoleIds: Id[] }
    | { type: EEstimateActionType.estimate_ready, isResourcePlansReady: boolean }
    | { type: EEstimateActionType.estimate_purge }
    | { type: EEstimateActionType.estimate_is_loading, isLoading: boolean }
    | { type: EEstimateActionType.estimate_load_products, products: ProductDto[] }
    | { type: EEstimateActionType.estimate_load_outputs, estimateOutputs: AnnotationDto[] }
    | { type: EEstimateActionType.estimate_load_comments, estimateComments: AnnotationDto[] }
    | { type: EEstimateActionType.estimate_add_comment, estimateComment: AnnotationDto }

    | { type: EEstimateActionType.estimate_clear_resource_plan_state }



export interface IEstimateState {
    intitialEstimate: IEstimate,
    estimate: IEstimate,
    isEstimateReady: boolean,
    isResourcePlansReady: boolean,
    modifiedOn: number,
    isAPILoading: boolean,
    resourceRoles: PracticeRoleDto[]
    loadedResourcePlan?: ResourcePlanDto
    loadedProducts?: ProductDto[]
    loadedOutputs?: AnnotationDto[]
    deletedResourceEntries?: string[],
    deletedResourceRoles?: string[]
    isReadOnly?: boolean,
    loadedComments?: AnnotationDto[]
}

export const EstimateInitialState = () => {
    const ret: IEstimateState = {
        intitialEstimate: {} as any,
        estimate: {} as any,
        isEstimateReady: false,
        isResourcePlansReady: false,
        modifiedOn: new Date().getTime(),
        isAPILoading: false,
        resourceRoles: [],
        loadedResourcePlan: undefined,
        loadedProducts: [],
        loadedOutputs: [],
        deletedResourceEntries: [],
        deletedResourceRoles: [],
        isReadOnly: true
    };

    return ret;
};


export const EstimateReducer = (xState: IEstimateState, action: TEstimateAction): IEstimateState => {
    switch (action.type) {
        case EEstimateActionType.load: {
            const nState: IEstimateState = { ...xState, };

            if (action.estimate) {
                nState.estimate = action.estimate;
                nState.intitialEstimate = action.estimate;
                nState.isEstimateReady = true;
                nState.isResourcePlansReady = true;
                nState.modifiedOn = new Date().getTime();
                nState.isReadOnly = action.estimate.statecode === HslSalesestimateStatecode.Option_1 ? true : false
            }

            if (action.hsl_resourceplans) {
                nState.estimate.hsl_hsl_resourceplan_salesestimateid_hsl_sale = action.hsl_resourceplans;
                nState.isResourcePlansReady = true;
                nState.modifiedOn = new Date().getTime();
            }

            return nState;
        }

        case EEstimateActionType.estimate_load_practice_role: {
            return {
                ...xState,
                resourceRoles: action.resourceRoles
            };
        }

        case EEstimateActionType.estimate_update:
            return {
                ...xState,
                estimate: { ...xState.estimate, ...action.estimate },
                modifiedOn: new Date().getTime(),
            };

        case EEstimateActionType.estimate_lineitem_add:
            return {
                ...xState,
                estimate: {
                    ...xState.estimate,
                    hsl_hsl_salesestimate_hsl_salesestimatelineitem_salesestimateid: [...xState.estimate.hsl_hsl_salesestimate_hsl_salesestimatelineitem_salesestimateid!, action.lineItem]
                },
                modifiedOn: new Date().getTime(),
            };


        // case EEstimateActionType.estimate_iplineitem_addupdate: {
        //     let update = false;

        //     const nState: IEstimateState = {
        //         ...xState,
        //         estimate: {
        //             ...xState.estimate,
        //             // hsl_hsl_intellectualpropertylineitem_salesest: xState.estimate.hsl_hsl_intellectualpropertylineitem_salesest?.map(t => {
        //             //     if (t.id === action.item.id) {
        //             //         update = true;
        //             //         return action.item;
        //             //     }
        //             //     return t;
        //             // })
        //         },
        //         modifiedOn: new Date().getTime(),
        //     };

        //     // not updating, creating new one ?
        //     // if (!update) {
        //     //     if (!nState.estimate.hsl_hsl_intellectualpropertylineitem_salesest)
        //     //         nState.estimate.hsl_hsl_intellectualpropertylineitem_salesest = [];

        //     //     nState.estimate.hsl_hsl_intellectualpropertylineitem_salesest.push(action.item);
        //     // }

        //     return nState;
        // }

        case EEstimateActionType.estimate_resource_plan_update: {

            const nState: IEstimateState = {
                ...xState,
                estimate: {
                    ...xState.estimate,
                    hsl_hsl_resourceplan_salesestimateid_hsl_sale: xState.estimate.hsl_hsl_resourceplan_salesestimateid_hsl_sale?.map(xPlan => {
                        if (xPlan.id === action.resourcePlanId)
                            return {
                                ...xPlan,
                                ...action.resourcePlan
                            }
                        return xPlan;
                    })
                },
                modifiedOn: new Date().getTime(),
            };

            return nState;
        }

        case EEstimateActionType.estimate_load_selected_resourceplan: {

            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: action.resourcePlan,
                deletedResourceEntries: [],
                deletedResourceRoles: [],
                modifiedOn: new Date().getTime(),
            };

            return nState;
        }

        case EEstimateActionType.estimate_clear_resource_plan_state: {

            const nState: IEstimateState = {
                ...xState,
                deletedResourceEntries: [],
                deletedResourceRoles: [],
            };

            return nState;
        }

        case EEstimateActionType.estimate_resource_plan_resource_update: {
            const nState: IEstimateState = {
                ...xState,
                estimate: {
                    ...xState.estimate,
                    hsl_hsl_resourceplan_salesestimateid_hsl_sale: xState.estimate.hsl_hsl_resourceplan_salesestimateid_hsl_sale?.map(xPlan => {
                        if (xPlan.id === action.resourcePlanId)
                            return {
                                ...xPlan,
                                hsl_resourceplanresources: xPlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map(xResource => {
                                    if (xResource.id === action.hsl_resourceplanresourceid)
                                        return {
                                            ...xResource,
                                            ...action.resourcePlanResource
                                        }
                                    return xResource;
                                })
                            }
                        return xPlan;
                    }),
                },
                modifiedOn: new Date().getTime(),
            };
            return nState;
        }

        case EEstimateActionType.estimate_resource_plan_resource_entry_update: {

            const nState: IEstimateState = {
                ...xState,
                estimate: {
                    ...xState.estimate,
                    hsl_hsl_resourceplan_salesestimateid_hsl_sale: xState.estimate.hsl_hsl_resourceplan_salesestimateid_hsl_sale?.map(xPlan => {
                        if (xPlan.id === action.resourcePlanId)
                            return {
                                ...xPlan,
                                hsl_resourceplanresources: xPlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map(xResource => {
                                    if (xResource.id === action.hsl_resourceplanresourceid)
                                        return {
                                            ...xResource,
                                            hsl_resourceplanentries: xResource?.hsl_hsl_resourceplanentry_resourceplanresourc?.map(xEntry => {
                                                if (xEntry.id === action.hsl_resourceplanentryid)
                                                    return {
                                                        ...xEntry,
                                                        ...action.resourcePlanResourceEntry
                                                    }
                                                return xEntry;
                                            })
                                        }
                                    return xResource;
                                })
                            }
                        return xPlan;
                    }),

                },
                modifiedOn: new Date().getTime(),
            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_resource_update_billrate: {
            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (resource.hsl_practiceroleid?.id === action?.resourcePlanResource?.hsl_practiceroleid?.id) {
                                return {
                                    ...resource,
                                    hsl_rate: action.billRate,
                                    ...resourcePlanResourceToAllocations(resource,
                                        {
                                            hsl_Rate: { value: action.billRate }
                                        })
                                }
                            }
                            return { ...resource }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_resource_update_hours: {
            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (resource.id === action.hsl_resourceplanresourceid) {
                                let phase = HslProjectphase.Option_864630000
                                const updatedEntries = resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.map((entry) => {
                                    if (entry.id === action.hsl_resourceplanentryid) {
                                        phase = entry.hsl_projectphase!
                                        return { ...entry, hsl_hours: action.hours }
                                    }
                                    return { ...entry }
                                }) || []
                                const allocationHours = updatedEntries.map(entry => entry.hsl_projectphase === phase ? (entry.hsl_hours || 0) : 0).reduce((partialSum, a) => partialSum + a, 0)
                                return {
                                    ...resource,
                                    hsl_hsl_resourceplanentry_resourceplanresourc: [
                                        ...updatedEntries
                                    ],
                                    ...updateAllocationPhase(phase, {
                                        ...resource[phaseToKey(phase)],
                                        hsl_Hours: resource[phaseToKey(phase)]!.hsl_Hours = allocationHours
                                    })

                                }
                            }
                            return { ...resource }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_add_resources: {
            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h || [],
                        ...action.resourceRoles.map((resourceRole) => {
                            const resourceRoleIdGenerated = uuidv4()
                            const existingBillRate = xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.find((resource) => {
                                return resource.hsl_practiceroleid?.id === resourceRole.practiceRole.id
                            })?.hsl_rate
                            const entries = [
                                ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h
                                && xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.length > 0
                                && xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h[0].hsl_hsl_resourceplanentry_resourceplanresourc?.map((entry) => {
                                    return {
                                        id: uuidv4(),
                                        isNew: true,
                                        hsl_resourceplanresourceid: { id: resourceRoleIdGenerated },
                                        // BE needs to populate resourceplanresource id
                                        hsl_hours: 0,
                                        hsl_projectphase: entry.hsl_projectphase,
                                        hsl_sprint: entry.hsl_sprint,
                                        hsl_week: entry.hsl_week
                                    }
                                }) || []
                            ]
                            return {
                                isNew: true,
                                id: resourceRoleIdGenerated,
                                hsl_resourceplanid: { id: action.resourcePlanId },
                                hsl_practiceroleid: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                hsl_name: resourceRole.practiceRole?.hsl_name,
                                hsl_rate: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0,
                                // BE needs to create theses
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630000).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630000,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,
                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630001).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630001,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,
                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630002).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630002,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,
                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630003).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630003,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,
                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630004).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630004,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,

                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation: {
                                    hsl_name: "",
                                    hsl_EstimatedHours: 0,
                                    hsl_Hours: 0,
                                    hsl_Rate: { value: existingBillRate || resourceRole.practiceRole.hsl_listprice?.value || 0 },
                                    hsl_Weeks: entries.filter(entry => entry.hsl_projectphase === HslProjectphase.Option_864630005).length,
                                    hsl_DelayedWeeks: 0,
                                    // hsl_HoursPreference: HslProjectconfigurationpreferenceGc.Option_864630000,
                                    hsl_ProjectPhase: HslProjectphase.Option_864630005,
                                    hsl_PracticeRole: { id: resourceRole.practiceRole.id, name: resourceRole.practiceRole.hsl_name },
                                    hsl_SalesEstimate: undefined,

                                    hsl_ResourcePlan: { id: xState.loadedResourcePlan?.id },
                                    hsl_LinkedSalesEstimate: { id: xState.estimate.id },
                                    hsl_LinkedResourcePlan: { id: xState.loadedResourcePlan?.id },
                                },
                                hsl_InitiateResourceAllocation: {},
                                hsl_BlueprintResourceAllocation: {},
                                hsl_BuildResourceAllocation: {},
                                hsl_ValidateResourceAllocation: {},
                                hsl_DeployResourceAllocation: {},
                                hsl_OperateResourceAllocation: {},
                                hsl_hsl_resourceplanentry_resourceplanresourc: entries
                            }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_delete_resources: {
            const resourceRoleIds = xState.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.filter(resource =>
                (action.resourceRoleIds as string[]).includes(resource.id!) && (resource as any)?.isNew === undefined).map((resource) =>
                    resource.id!
                ) || []

            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.filter((resource) => {
                            return resource.id && !action.resourceRoleIds.includes(resource.id)
                        }) || []
                    ]
                },
                deletedResourceRoles: [...(xState?.deletedResourceRoles || []), ...resourceRoleIds],
                modifiedOn: new Date().getTime(),

            }
            return nState
        }


        case EEstimateActionType.estimate_resource_plan_resource_update_resource_to_offshore: {

            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (resource.id && action.resourceIds.includes(resource.id)) {
                                const practiceRole = action.praticeRoleInfo.find((role) =>
                                    role.id === resource.hsl_practiceroleid?.id
                                )
                                const offshoreRole = action.praticeRoleInfo.find((role) =>
                                    role.id === practiceRole?.hsl_offshoreequivalentpracticeroleid?.id
                                )
                                const existingBillRate = xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.find((resource) => {
                                    return resource.hsl_practiceroleid?.id === offshoreRole?.id
                                })?.hsl_rate
                                if (offshoreRole) {
                                    return {
                                        ...resource,
                                        hsl_practiceroleid: { name: offshoreRole?.hsl_name, id: offshoreRole?.id },
                                        hsl_rate: existingBillRate || offshoreRole?.hsl_listprice?.value || 0,
                                        ...resourcePlanResourceToAllocations(resource,
                                            {
                                                hsl_Rate: { value: existingBillRate || practiceRole?.hsl_listprice?.value || 0 }
                                            })
                                    }
                                }
                            }
                            return {
                                ...resource,
                            }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_resource_update_resource_to_onshore: {

            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (resource.id && action.resourceIds.includes(resource.id)) {
                                const practiceRole = action.praticeRoleInfo.find((role) =>
                                    role.hsl_offshoreequivalentpracticeroleid?.id === resource.hsl_practiceroleid?.id
                                )
                                const existingBillRate = xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.find((resource) => {
                                    return resource.hsl_practiceroleid?.id === practiceRole?.id
                                })?.hsl_rate
                                if (practiceRole) {
                                    return {
                                        ...resource,
                                        hsl_practiceroleid: { name: practiceRole?.hsl_name, id: practiceRole?.id },
                                        hsl_rate: existingBillRate || practiceRole?.hsl_listprice?.value || 0,
                                        ...resourcePlanResourceToAllocations(resource,
                                            {
                                                hsl_Rate: { value: existingBillRate || practiceRole?.hsl_listprice?.value || 0 }
                                            })
                                    }
                                }
                            }
                            return {
                                ...resource,
                            }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_resource_update_extend_phase: {
            let lastWeekOfStartingPhase = 0
            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (action.startingNodeSprint) {
                                return {
                                    ...resource,
                                    hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
                                        ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation,
                                        hsl_Weeks: (resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation?.hsl_Weeks || 0) + action.extensionWeeks
                                    },
                                    hsl_hsl_resourceplanentry_resourceplanresourc: [
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry, index) => {
                                            const week = entry?.hsl_week || 0
                                            if ((action?.startingNodeSprint && entry?.hsl_sprint && entry?.hsl_sprint <= action?.startingNodeSprint) || (entry.hsl_week && !entry.hsl_sprint && entry.hsl_week <= action.entryWeek)) {
                                                lastWeekOfStartingPhase = lastWeekOfStartingPhase < week ? week : lastWeekOfStartingPhase
                                                return true
                                            }
                                        }) || [],
                                        ...action.resourcePlanResourceEntry.map((entry, index) => {
                                            return {
                                                ...entry,
                                                hsl_week: lastWeekOfStartingPhase + index + 1
                                            }
                                        }),
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if ((action?.startingNodeSprint && entry?.hsl_sprint && entry?.hsl_sprint > action?.startingNodeSprint) || (entry.hsl_week && !entry.hsl_sprint && lastWeekOfStartingPhase < entry.hsl_week)) {
                                                return true
                                            }
                                        })?.map((entry) => {
                                            const newSprint = entry.hsl_sprint ? entry.hsl_sprint + 1 : null
                                            return { ...entry, hsl_week: entry?.hsl_week! + action.extensionWeeks, hsl_sprint: newSprint }
                                        }) || []
                                    ]
                                }
                            } else {
                                let updatedResource = {
                                    ...resource,
                                    hsl_hsl_resourceplanentry_resourceplanresourc: [
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if (entry?.hsl_week && entry?.hsl_week <= action.entryWeek) {
                                                return true
                                            }
                                        }) || [],
                                        ...action.resourcePlanResourceEntry,
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if (entry?.hsl_week && entry?.hsl_week > action.entryWeek) {
                                                return true
                                            }
                                        })?.map((entry) => {
                                            return { ...entry, hsl_week: entry?.hsl_week! + action.extensionWeeks }
                                        }) || []]
                                }
                                updatedResource[phaseToKey(action.resourcePlanResourceEntry[0].hsl_projectphase!)] = {
                                    ...updatedResource[phaseToKey(action.resourcePlanResourceEntry[0].hsl_projectphase!)],
                                    hsl_Weeks: (updatedResource[phaseToKey(action.resourcePlanResourceEntry[0].hsl_projectphase!)]!.hsl_Weeks || 0) + 1
                                }
                                return updatedResource
                            }
                        }) || []
                    ]
                },
                modifiedOn: new Date().getTime(),

            }
            return nState
        }

        case EEstimateActionType.estimate_resource_plan_resource_update_delete_phase: {
            let deletedEntiesArr: string[] = []
            const nState: IEstimateState = {
                ...xState,
                loadedResourcePlan: {
                    ...xState.loadedResourcePlan,
                    hsl_hsl_resourceplanresource_resourceplanid_h: [
                        ...xState?.loadedResourcePlan?.hsl_hsl_resourceplanresource_resourceplanid_h?.map((resource) => {
                            if (action.startingNodeSprint) {
                                const deletedEntries = resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                    return (entry?.hsl_sprint === action?.startingNodeSprint && entry.id)
                                }) || []
                                const deletedEntriesIds = deletedEntries?.map((entry) => entry.id!) || []
                                deletedEntiesArr = [...deletedEntiesArr, ...deletedEntriesIds]
                                return {
                                    ...resource,
                                    hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
                                        ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation,
                                        hsl_Weeks: (resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation?.hsl_Weeks || 0) - action.extensionWeeks,
                                        hsl_Hours: (resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation?.hsl_Hours || 0) - deletedEntries.reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0)
                                    },
                                    hsl_hsl_resourceplanentry_resourceplanresourc: [
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if ((action?.startingNodeSprint && entry?.hsl_sprint && entry?.hsl_sprint < action?.startingNodeSprint) || (!entry?.hsl_sprint && entry?.hsl_week && entry?.hsl_week < action.entryWeek)) {
                                                return true
                                            }
                                        }) || [],
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if (entry?.hsl_week && entry?.hsl_week > action.entryWeek && entry?.hsl_sprint !== action?.startingNodeSprint) {
                                                return true
                                            }
                                        })?.map((entry) => {
                                            const newSprint = entry.hsl_sprint ? entry.hsl_sprint - 1 : null
                                            return { ...entry, hsl_week: entry?.hsl_week! - action.extensionWeeks, hsl_sprint: newSprint }
                                        }) || []
                                    ]
                                }
                            } else {
                                const deletedEntries = resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                    return (entry?.hsl_week && entry?.hsl_week === action.entryWeek && entry.id)
                                }) || []
                                const deletedEntriesIds = deletedEntries?.map((entry) => entry.id!) || []
                                deletedEntiesArr = [...deletedEntiesArr, ...deletedEntriesIds]
                                let phase = HslProjectphase.Option_864630000
                                let updatedResource = {
                                    ...resource,
                                    hsl_hsl_resourceplanentry_resourceplanresourc: [
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if (entry?.hsl_week && entry?.hsl_week < action.entryWeek) {
                                                return true
                                            }
                                            if (entry?.hsl_week && entry?.hsl_week === action.entryWeek) {
                                                phase = entry.hsl_projectphase!
                                                return false
                                            }
                                        }) || [],
                                        ...resource?.hsl_hsl_resourceplanentry_resourceplanresourc?.filter((entry) => {
                                            if (entry?.hsl_week && entry?.hsl_week > action.entryWeek) {
                                                return true
                                            }
                                        })?.map((entry) => {
                                            return { ...entry, hsl_week: entry?.hsl_week! - action.extensionWeeks }
                                        }) || []]
                                }
                                updatedResource[phaseToKey(phase)] = {
                                    ...updatedResource[phaseToKey(phase)],
                                    hsl_Weeks: (updatedResource[phaseToKey(phase)]!.hsl_Weeks || 0) - 1,
                                    hsl_Hours: updatedResource.hsl_hsl_resourceplanentry_resourceplanresourc.filter((resource) => resource.hsl_projectphase === phase).reduce((partialSum, a) => partialSum + (a.hsl_hours || 0), 0)

                                }
                                return updatedResource
                            }
                        }) || []
                    ]
                },
                deletedResourceEntries: [...xState.deletedResourceEntries!, ...deletedEntiesArr],
                modifiedOn: new Date().getTime(),

            }

            return nState
        }

        case EEstimateActionType.estimate_load_outputs: {
            const nState: IEstimateState = {
                ...xState,
                loadedOutputs: action.estimateOutputs,
            };

            return nState;
        }

        case EEstimateActionType.estimate_load_comments: {
            const nState: IEstimateState = {
                ...xState,
                loadedComments: action.estimateComments,
            };

            return nState;
        }

        case EEstimateActionType.estimate_add_comment: {
            const newLoadedComments = structuredClone(xState.loadedComments)
            newLoadedComments?.push(action.estimateComment)
            const loadedComments = newLoadedComments
            const nState: IEstimateState = {
                ...xState,
                loadedComments: loadedComments,
            };

            return nState;
        }

        case EEstimateActionType.estimate_ready: {

            const nState: IEstimateState = {
                ...xState,
                isEstimateReady: action.isResourcePlansReady,
                modifiedOn: new Date().getTime(),
            };

            return nState;
        }

        case EEstimateActionType.estimate_load_products: {

            const nState: IEstimateState = {
                ...xState,
                loadedProducts: action.products,
            };

            return nState;
        }
        case EEstimateActionType.estimate_purge: {
            const nState: IEstimateState = {
                ...xState,
                estimate: {} as any,
                isEstimateReady: false,
                modifiedOn: new Date().getTime(),
            };

            return nState;
        }

        case EEstimateActionType.estimate_is_loading: {
            const nState: IEstimateState = {
                ...xState,
                isAPILoading: action.isLoading,
            };

            return nState;
        }

        default:
            return xState;
    }
};

export type TEstimateStore = [IEstimateState, React.Dispatch<TEstimateAction>]


const phaseToKey = (phase: HslProjectphase) => {
    switch (phase) {
        case (HslProjectphase.Option_864630000):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation"
        case (HslProjectphase.Option_864630001):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation"
        case (HslProjectphase.Option_864630002):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation"
        case (HslProjectphase.Option_864630003):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation"
        case (HslProjectphase.Option_864630004):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation"
        case (HslProjectphase.Option_864630005):
            return "hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation"
    }
}

const updateAllocationPhase = (phase: HslProjectphase, update: Partial<ResourceAllocationDto>) => {
    switch (phase) {
        case (HslProjectphase.Option_864630000):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation: {
                    ...update,
                },
            }
        case (HslProjectphase.Option_864630001):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation: {
                    ...update,
                },
            }
        case (HslProjectphase.Option_864630002):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
                    ...update,
                },
            }
        case (HslProjectphase.Option_864630003):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation: {
                    ...update,
                },
            }
        case (HslProjectphase.Option_864630004):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation: {
                    ...update,
                },
            }
        case (HslProjectphase.Option_864630005):
            return {
                hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation: {
                    ...update,
                },
            }
    }
}

const resourcePlanResourceToAllocations = (resource: ResourcePlanResourceDto, value: Partial<ResourceAllocationDto>) => {
    return {
        hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_InitiateResourceAllocation,
            ...value,
        },
        hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BlueprintResourceAllocation,
            ...value,
        },
        hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_BuildResourceAllocation,
            ...value,
        },
        hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_ValidateResourceAllocation,
            ...value,
        },
        hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_DeployResourceAllocation,
            ...value,
        },
        hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation: {
            ...resource.hsl_hsl_resourceallocation_hsl_resourceplanresource_OperateResourceAllocation,
            ...value,
        },
    }
}