import { IPublicClientApplication, InteractionRequiredAuthError, PopupRequest } from "@azure/msal-browser";
import { IJsonWebTokenProvider } from "./IJsonWebTokenProvider";
import { Config } from "../../config";
import { ITrace } from "components/ErrorDialog";


export class AzureDirectoryTokenProvider implements IJsonWebTokenProvider {
    private readonly instance: IPublicClientApplication;

    private defaultScopes: string[];
    public readonly baseUrl: string;

    // TODO change this to status instead of error object
    private readonly onError: (error: any) => void;
    private readonly onFinally: () => void;

    constructor(msalInstance: IPublicClientApplication, defaultScopes: string[], baseUrl: string, onError?: any, onFinally?: any) {
        this.instance = msalInstance;
        this.defaultScopes = [...defaultScopes];
        this.baseUrl = baseUrl;
        this.onError = onError;
        this.onFinally = onFinally;
    }

    public extendDefaultScope(scope: string) {
        if (!this.defaultScopes.includes(scope))
            this.defaultScopes.push(scope);
    }

    public async getUserName(): Promise<string> {
        let active = this.instance.getActiveAccount();
        return active!.username;
    }

    public async getToken(scopes: string[]): Promise<string> {
        const request: PopupRequest = {
            account: this.instance.getActiveAccount() ?? this.instance.getAllAccounts()[0],
            scopes: scopes,
        };

        try {
            let authenticationResult = await this.instance.acquireTokenSilent(request);
            return authenticationResult.accessToken;

        } catch (error: any) {

            this.onError?.(error);
            // https://learn.microsoft.com/en-us/azure/active-directory/manage-apps/configure-user-consent?pivots=portal
            /* Payload Examples:
            // asking for scope increase
            {
                "errorCode": "invalid_grant",
                "errorMessage": "AADSTS65001: The user or administrator has not consented to use the application with ID 'b6faad4a-599b-4dae-afed-5e94c3ad21c4' named 'Estimator WebApp Non-Prod'. Send an interactive authorization request for this user and resource.\r\nTrace ID: 70416f7d-3e9c-469b-8e9b-f1d498e49200\r\nCorrelation ID: 8e71152f-de0d-4375-8e16-55d200c95d69\r\nTimestamp: 2023-06-23 22:20:58Z",
                "subError": "consent_required",
                "name": "InteractionRequiredAuthError",
                "timestamp": "2023-06-23 22:20:58Z",
                "traceId": "70416f7d-3e9c-469b-8e9b-f1d498e49200",
                "correlationId": "ffd90126-8b6c-41e5-a173-89651e2a5a8b",
                "claims": ""
            }


            // consent pormpted and user sumbited to ask admin for access
            {
                "errorCode": "access_denied",
                "errorMessage": "AADSTS65004: User declined to consent to access the app.",
                "subError": "",
                "name": "ServerError",
                "correlationId": "eb1efe3e-a96b-47b5-9861-954f5fb5f383"
            }


            {
                "errorCode": "interaction_in_progress",
                "errorMessage": "Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API.  For more visit: aka.ms/msaljs/browser-errors.",
                "subError": "",
                "name": "BrowserAuthError"
            }

            {
                "errorCode": "no_account_error",
                "errorMessage": "No account object provided to acquireTokenSilent and no active account has been set. Please call setActiveAccount or provide an account on the request.",
                "subError": "",
                "name": "BrowserAuthError"
            }

            */

            // extending scope request?
            if (error.name === "InteractionRequiredAuthError") {

            } else if (error instanceof InteractionRequiredAuthError) {

            }

            // force login
            try {
                let authenticationResult = await this.instance.loginPopup(request);
                authenticationResult = await this.instance.acquireTokenSilent(request);
                return authenticationResult.accessToken;
            } catch (error: any) {
                throw error;
            }
        } finally {
            this.onFinally?.()
        }
    }

    public getDefaultScopes() {
        return this.defaultScopes;
    }
}