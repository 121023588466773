import { IJsonWebTokenProvider } from "./IJsonWebTokenProvider";

/**
 * Abstract class that handles the token acquisition.
 */
export abstract class JsonWebTokenDataSource {
    protected readonly provider: IJsonWebTokenProvider;

    constructor(provider: IJsonWebTokenProvider) {
        this.provider = provider;
    }

    protected async getToken(scopes: string[]): Promise<string> {
        return await this.provider.getToken(scopes);
    }
}