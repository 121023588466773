import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

// Define the type of the component's props
interface CustomTypographyProps extends TypographyProps {
  text: string;
}

// Create the CustomTypography component
const CustomTypography: React.FC<CustomTypographyProps> = ({ text, ...props }) => {
  return (
    <Typography {...props}>{text}</Typography>
  );
};

// Export the component
export default CustomTypography;
