
import { IOptionsRecord } from 'components/ApplicationContextProvider/IApplicationContext.type';
import * as Swagger from 'data/swagger/API';
import { IBuildItem } from 'models/IBuildItem';
import { IPracticeRole } from 'views/Practices/Practice/GridComponents/PracticeRoleGrid';

export const typeToName = (options: IOptionsRecord, type: Swagger.HslType | undefined) => {
    return options?.hsl_type?.find((option) => option.value === type)?.name
}

export const reduceDefaultItem = (item: Swagger.SalesEstimateLineItemDto, practiceRoles: Swagger.PracticeRoleDto[]): IBuildItem => {
    return {
        standardItemId: item?.hsl_salesestimatestandarditemid?.id || null,
        id: item?.id || null,
        hsl_isstandarditem: item?.hsl_isstandarditem || null,
        hsl_isexcluded: item?.hsl_isexcluded || false,
        hsl_type: item?.hsl_type?.toString() || null,
        hsl_name: item?.hsl_name || "",
        hsl_projectphase: item?.hsl_projectphase?.toString() || null,
        hsl_description: item?.hsl_descriptiontext || "",
        hsl_developmentlineitemefforts: item?.hsl_hsl_salesestimatelineitem_hsl_salesestimatelineitemeffort_salesestimatelineitemid
            ? convertEffortDtoToIEstimateLine(item?.hsl_hsl_salesestimatelineitem_hsl_salesestimatelineitemeffort_salesestimatelineitemid, practiceRoles)
            : [],
        hsl_assumptions: item?.hsl_hsl_salesestimatelineitem_hsl_assumption ? convertAssumptionDtoToIAssumption(item?.hsl_hsl_salesestimatelineitem_hsl_assumption) : []
    }
}

export const convertAssumptionDtoToIAssumption = (assumptions: Swagger.AssumptionDto[]) => {
    return assumptions?.map((assumption) => {
        return {
            id: assumption.id as any,
            hsl_description: assumption.hsl_descriptiontext as any,
        }
    })
}

export const convertEffortDtoToIEstimateLine = (lineItem: Swagger.SalesEstimateLineItemEffortDto[], practiceRoles: Swagger.PracticeRoleDto[]) => {
    return lineItem?.map((effort) => {
        return {
            hsl_developmentlineitemeffortid: effort.id || "",
            hsl_name: effort.hsl_name,
            practiceId: practiceRoles?.find(role => role?.id === effort?.hsl_practiceroleid?.id)?.hsl_practiceid?.id || `${effort.hsl_practiceroleid?.id}`,
            hsl_practiceroleid: effort.hsl_practiceroleid?.id || "",
            hsl_hours: effort.hsl_hours || 0,
        }
    })
}

export function roleDtoToPracticeRole(roleDto: Swagger.PracticeRoleDto): IPracticeRole {
    return {
        id: roleDto.id || "",
        hsl_name: roleDto.hsl_name,
        hsl_cost: roleDto.hsl_cost?.value?.toString(),
        hsl_isarchitect: roleDto.hsl_isarchitect,
        hsl_isoffshore: roleDto.hsl_isoffshore,
        hsl_listprice: roleDto.hsl_listprice?.value?.toString(),
        hsl_preferredclientprice: roleDto.hsl_preferredclientprice?.value?.toString(),
        hsl_practiceid: roleDto.hsl_practiceid?.id || "",
        hsl_offshoreequivalentpracticeroleid: roleDto.hsl_offshoreequivalentpracticeroleid?.id,
        hsl_hsl_practicerole_hsl_practiceroledelayedweeks_PracticeRole: roleDto.hsl_hsl_practicerole_hsl_practiceroledelayedweeks_PracticeRole || []
    }
}

export function practiceRoleToRoleDto(role: IPracticeRole): Swagger.PracticeRoleDto {
    return {
        id: role.id || "",
        hsl_name: role.hsl_name,
        hsl_cost: { value: Number(role.hsl_cost) },
        hsl_isarchitect: role.hsl_isarchitect,
        hsl_isoffshore: role.hsl_isoffshore,
        hsl_listprice: { value: Number(role.hsl_listprice) },
        hsl_preferredclientprice: { value: Number(role.hsl_preferredclientprice) },
        hsl_practiceid: { id: role.hsl_practiceid || "" },
        hsl_offshoreequivalentpracticeroleid: role.hsl_offshoreequivalentpracticeroleid ? { id: role.hsl_offshoreequivalentpracticeroleid } : undefined
    }
}

export function getPracticeFromRoleId(practiceRoles: Swagger.PracticeRoleDto[], roleId: string) {
    return practiceRoles.find(role => role.id === roleId)?.hsl_practiceid
}

export function roleIdToPracticeId(roleId: string | undefined, practiceRoles: Swagger.PracticeRoleDto[] | undefined) {
    return practiceRoles?.find((role) => role?.id === roleId)?.hsl_practiceid
}


export async function b64toBlob(base64: string, type = 'application/octet-stream') {
    const t = await fetch(`data:${type};base64,${base64}`);
    return await t.blob();
}


export async function createAnchor(link: string, name: string, type: string) {
    const anchor = document.createElement("a");
    const blobLink = window.URL.createObjectURL(await b64toBlob(link, type))
    anchor.href = blobLink;
    anchor.download = name; // Set the desired file name
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    // Trigger the download
    anchor.click();
    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
}


export function saveAs(blob: Blob, name: string, options: any) {
    const anchor = document.createElement("a");
    const blobLink = window.URL.createObjectURL(blob)
    anchor.href = blobLink;
    anchor.download = name; // Set the desired file name
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    // Trigger the download
    anchor.click();
    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
}

// TODO move all of these to TSFunctions
